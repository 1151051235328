import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WaterIntakeService } from '../water-intake.service';
import { waterIntakeActions } from './water-intake.actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class WaterIntakeEffects {
  public readonly loadWaterIntake$ = createEffect(() =>
    this.action$.pipe(
      ofType(waterIntakeActions.loadWaterIntake),
      switchMap(() =>
        this.waterIntakeService.getWaterIntake().pipe(
          map(waterIntake => waterIntakeActions.loadWaterIntakeSuccess({ waterIntake })),
          catchError(error => of(waterIntakeActions.loadWaterIntakeFailure({ error })))
        )
      )
    )
  );

  public readonly addWaterIntake$ = createEffect(() =>
    this.action$.pipe(
      ofType(waterIntakeActions.addWaterIntake),
      switchMap(({ amountMl }) =>
        this.waterIntakeService.addWaterIntake(amountMl).pipe(
          map(waterIntake => waterIntakeActions.addWaterIntakeSuccess({ waterIntake })),
          catchError(error => of(waterIntakeActions.addWaterIntakeFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly action$: Actions,
    private readonly waterIntakeService: WaterIntakeService
  ) {}
}
