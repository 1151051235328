import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

export interface SegmentOption<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule],
  standalone: true,
})
export class SegmentComponent<T> {
  @Input()
  public set segmentOptions(segmentOptions: SegmentOption<T>[]) {
    this.segmentOptionStore = segmentOptions;
    if (segmentOptions.indexOf(this.selectedSegment$.value) === -1 && segmentOptions.length) {
      this.selectedSegment$.next(segmentOptions[0]);
    }
  }

  @Input()
  public set selectedSegment(selectedSegment: SegmentOption<T>) {
    if (this.selectedSegment$.value !== selectedSegment) {
      this.selectedSegment$.next(selectedSegment);
      this.selectedSegmentChange.emit(selectedSegment);
    }
  }

  @Output()
  public selectedSegmentChange = new EventEmitter<SegmentOption<T>>();

  public segmentOptionStore: SegmentOption<T>[] = [];
  public selectedSegment$ = new BehaviorSubject<SegmentOption<T> | undefined>(undefined);

  public onSegmentChange(segment: SegmentOption<T>): void {
    if (this.selectedSegment$.value?.value !== segment.value) {
      this.selectedSegment$.next(segment);
      this.selectedSegmentChange.emit(segment);
    }
  }

  public isSelectedSegment(value: T): boolean {
    const selectedValue = this.selectedSegment$.value.value;
    if (typeof value === 'string' && typeof selectedValue === 'string') {
      return value.toLowerCase() === selectedValue.toLowerCase();
    }
    return value === selectedValue;
  }
}
