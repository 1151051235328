import { AfterViewInit, ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Feature, FeatureService } from '../services/features/feature.service';

@Directive({
  selector: '[appFeature]',
  standalone: true
})
export class FeatureDirective implements AfterViewInit {
  @Input()
  public feature: Feature | Feature[];

  constructor(
    private readonly featureService: FeatureService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly view: ViewContainerRef,
    private readonly template: TemplateRef<any>
  ) {}

  private hasAnyFeature(feature: Feature | Feature[]): boolean {
    if (Array.isArray(feature)) {
      return feature.some(f => this.featureService.isVisible(f));
    } else {
      return this.featureService.isVisible(feature);
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    if (this.hasAnyFeature(this.feature)) {
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
    this.changeDetectorRef.detectChanges();
  }
}
