import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TeamService } from './team.service';
import { BehaviorSubject, switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { defaultProfileImage } from '@fitup-monorepo/core/lib/constants';
import { BannerComponent, BannerTheme } from '../banner/banner.component';
import { sortByAlphabet } from '@fitup-monorepo/core/lib/util/array-util';
import { ProfileNavigationDirective } from '../profile/profile-navigation.directive';

@Component({
  selector: 'app-team-member-list',
  templateUrl: './team-member-list.component.html',
  styleUrls: ['./team-member-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IonicModule, BannerComponent, ProfileNavigationDirective],
  standalone: true
})
export class TeamMemberListComponent {
  @Input({ required: true })
  public set teamId(id: number) {
    this.teamId$.next(id);
  }

  @Input()
  public customerUuid: string;

  protected readonly teamId$ = new BehaviorSubject<number>(null);

  protected readonly team$ = this.teamId$.pipe(switchMap(id => this.teamService.getTeam(id)));

  protected readonly defaultProfileImage = defaultProfileImage;
  protected readonly bannerTheme = BannerTheme;
  protected readonly sortByAlphabet = sortByAlphabet;

  constructor(private readonly teamService: TeamService) {}
}
