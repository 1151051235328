import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { IonicRatingComponent } from './feedback/rating-component/ionic-rating-component';
import { CardComponent, CardFooterDirective } from './card/card.component';
import { HeaderComponent } from './header/header.component';
import { ErrorComponent } from './error/error.component';
import { SwiperComponent, SwiperSlideDirective } from './swiper/swiper/swiper.component';
import { IonicRatingComponentModule } from './feedback/rating-component/ionic-rating.module';
import { CardSlideDirective, CardSwiperComponent } from './swiper/card-swiper/card-swiper.component';
import { CountdownComponent } from './countdown/countdown.component';
import { CtaButtonComponent } from './cta-button/cta-button.component';
import { LiveClassCardItemComponent } from './live-class-card-item/live-class-card-item.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';

import { ImagePickerDirective } from './image-picker-modal/image-picker.directive';
import { IfDefaultPipe } from '@fitup-monorepo/core/lib/pipes/if-default-pipe';
import { DatetimePipe } from '@fitup-monorepo/core/lib/pipes/datetime.pipe';
import { DistancePipe } from '@fitup-monorepo/core/lib/pipes/distance.pipe';
import { DurationPipe } from '@fitup-monorepo/core/lib/pipes/duration-pipe';
import { LocalizedNumberPipe } from '@fitup-monorepo/core/lib/pipes/localized-number.pipe';
import { LocalizedCurrencyPipe } from '@fitup-monorepo/core/lib/pipes/localized-currency.pipe';
import { LocalizedDatePipe } from '@fitup-monorepo/core/lib/pipes/localized-date.pipe';
import { NativeOnlyDirective } from '@fitup-monorepo/core/lib/services/platform/platform.service';
import { TimeAgoPipe } from '@fitup-monorepo/core/lib/pipes/time-ago.pipe';
import { AdminOnlyDirective } from '@fitup-monorepo/core/lib/directives/admin-only.directive';
import { TimeLeftPipe } from '@fitup-monorepo/core/lib/pipes/time-left.pipe';
import { FeatureDirective } from '@fitup-monorepo/core/lib/directives/feature.directive';

@NgModule({
  declarations: [IfDefaultPipe, DatetimePipe],
  imports: [
    DistancePipe,
    ErrorComponent,
    DurationPipe,
    LocalizedNumberPipe,
    LocalizedCurrencyPipe,
    LocalizedDatePipe,
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    NgCircleProgressModule.forRoot(),
    ImagePickerDirective,
    NativeOnlyDirective,
    CardComponent,
    HeaderComponent,
    TimeAgoPipe,
    CardFooterDirective,
    IonicRatingComponentModule,
    SwiperComponent,
    SwiperSlideDirective,
    CardSwiperComponent,
    CardSlideDirective,
    AdminOnlyDirective,
    CtaButtonComponent,
    LiveClassCardItemComponent,
    CountdownComponent,
    ProgressBarComponent,
    TimeLeftPipe,
    FeatureDirective
  ],
  exports: [
    IonicRatingComponent,
    DurationPipe,
    IfDefaultPipe,
    ImagePickerDirective,
    CardComponent,
    DistancePipe,
    LocalizedDatePipe,
    LocalizedNumberPipe,
    LocalizedCurrencyPipe,
    ErrorComponent,
    CardFooterDirective,
    SwiperComponent,
    SwiperSlideDirective,
    CardSwiperComponent,
    CardSlideDirective,
    NativeOnlyDirective,
    FeatureDirective
  ],
  providers: [DurationPipe, LocalizedDatePipe, DistancePipe]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule
    };
  }
}
