import { PurchaseService } from '@fitup-monorepo/core/lib/services/purchase/purchase.service';
import { CustomerSelectService } from '@fitup-monorepo/core/lib/services/customer-select/customer-select.service';
import { Store } from '@ngrx/store';
import { interval, of, Subject } from 'rxjs';
import { AuthServerProvider } from '@fitup-monorepo/core/lib/services/auth/auth-jwt.service';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProfileService } from '@fitup-monorepo/core/lib/services/profile/profile.service';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PACKAGE_TYPE } from '@revenuecat/purchases-capacitor';
import { CustomerMembership } from '@fitup-monorepo/core/lib/model/customer-membership';
import { MembershipPlan } from '@fitup-monorepo/core/lib/model/purchase/purchase';
import { profileApiActions } from '@fitup-monorepo/core/lib/state/profile/profile.actions';

@Injectable({
  providedIn: 'root'
})
export class StartSubscriptionService {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly purchaseService: PurchaseService,
    private readonly customerSelectService: CustomerSelectService,
    private readonly store: Store,
    private readonly authServerProvider: AuthServerProvider,
    private readonly profileService: ProfileService,
    private readonly platform: Platform
  ) {}

  public async getRecommendedPlan(): Promise<MembershipPlan> {
    const currentPackage = await this.purchaseService.getCurrentMembershipPackage();
    return currentPackage.plans.find(p => p.packageType === PACKAGE_TYPE.ANNUAL);
  }
  public async startSubscription(plan: MembershipPlan): Promise<void> {
    this.destroy$.next();
    const customerInfo = await this.purchaseService.getInfo();
    const customer = await this.customerSelectService.selectCustomer();
    console.log('customerInfo', customerInfo);

    await this.purchaseService.purchase(plan, customer.membership);
    if (this.platform.is('capacitor')) {
      await this.pollForMembership(plan.id);
      await this.updateJwt();
      this.store.dispatch(profileApiActions.loadProfile());
    }
  }

  public async updateJwt(): Promise<void> {
    await this.authServerProvider.updateJWT();
  }

  public pollForMembership(planId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      interval(1000)
        .pipe(
          tap(intervalResult => {
            if (intervalResult === 30) {
              this.destroy$.next();
              reject('No renewed membership after 30 seconds');
            }
          }),
          switchMap(() => this.profileService.getProfileInformation()),
          takeUntil(this.destroy$),
          catchError(error => {
            reject(error);
            return of(undefined);
          })
        )
        .subscribe(result => {
          const membership = result?.currentMembership;
          console.log('polling membership', membership);
          if (this.hasNewMembership(planId, membership)) {
            this.destroy$.next();
            this.store.dispatch(profileApiActions.updateMembership({ membership }));
            resolve();
          }
        });
    });
  }

  private hasNewMembership(planId: string, membership: CustomerMembership): boolean {
    return membership?.membership.name === planId || membership?.nextMembership.name === planId;
  }
}
