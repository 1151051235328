import { HttpClient } from '@angular/common/http';
import { environment } from '@fitup-monorepo/core/lib/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationLoggingService {
  constructor(private readonly http: HttpClient) {}

  public sendLogs(deviceId: string, request: LogRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/log/location?deviceId=${deviceId}`, request);
  }
}

export interface LogRequest {
  lines: string[];
}
