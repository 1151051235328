// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  width: 100%;
}
.dashboard__header {
  margin: 30px 0;
}
.dashboard__mode-segment {
  margin-bottom: 30px;
}
.dashboard__date-select {
  margin-bottom: 30px;
}
.dashboard__date-select__wrapper {
  padding: 0;
}
.dashboard__icon-box {
  width: 64px;
  height: 64px;
  padding: 16px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
}
.dashboard__icon-box__icon {
  font-size: 32px;
}
.dashboard__icon-box__title {
  margin-bottom: 30px;
}
.dashboard__icon-box__green {
  background-color: var(--fu-green-light);
}
.dashboard__icon-box__green ion-icon {
  color: var(--fu-green);
}
.dashboard__icon-box__orange {
  background-color: var(--fu-orange-extra-light);
}
.dashboard__icon-box__orange ion-icon {
  color: var(--fu-orange);
}
.dashboard__chart {
  margin-bottom: 40px;
  border-bottom: 1px solid var(--fu-grey-1);
  padding-bottom: 30px;
}
.dashboard__chart:last-child {
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./libs/account/src/lib/personal-dashboard/personal-dashboard.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAGI;EACE,UAAA;AADN;AAKE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,wBAAA;AAHJ;AAKI;EACE,eAAA;AAHN;AAMI;EACE,mBAAA;AAJN;AAOI;EACE,uCAAA;AALN;AAOM;EACE,sBAAA;AALR;AASI;EACE,8CAAA;AAPN;AASM;EACE,uBAAA;AAPR;AAYE;EACE,mBAAA;EACA,yCAAA;EACA,oBAAA;AAVJ;AAaE;EACE,mBAAA;AAXJ","sourcesContent":[".dashboard {\n  width: 100%;\n\n  &__header {\n    margin: 30px 0;\n  }\n\n  &__mode-segment {\n    margin-bottom: 30px;\n  }\n\n  &__date-select {\n    margin-bottom: 30px;\n\n    &__wrapper {\n      padding: 0;\n    }\n  }\n\n  &__icon-box {\n    width: 64px;\n    height: 64px;\n    padding: 16px;\n    border-radius: 50%;\n    margin: 0 auto 20px auto;\n\n    &__icon {\n      font-size: 32px;\n    }\n\n    &__title {\n      margin-bottom: 30px;\n    }\n\n    &__green {\n      background-color: var(--fu-green-light);\n\n      ion-icon {\n        color: var(--fu-green);\n      }\n    }\n\n    &__orange {\n      background-color: var(--fu-orange-extra-light);\n\n      ion-icon {\n        color: var(--fu-orange);\n      }\n    }\n  }\n\n  &__chart {\n    margin-bottom: 40px;\n    border-bottom: 1px solid var(--fu-grey-1);\n    padding-bottom: 30px;\n  }\n\n  &__chart:last-child {\n    border-bottom: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
