// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.fu-swiper__slide:first-child {
  margin-left: var(--fu-content-padding-side);
}
.fu-swiper__slide {
  --slide-width: 300px;
  min-width: var(--slide-width);
  width: var(--slide-width);
  margin-right: var(--fu-spacing-s);
}

swiper-container {
  height: 100%;
  --swiper-pagination-bottom: 60px;
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/swiper/swiper/swiper.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAGE;EACE,2CAAA;AAAJ;AAGE;EACE,oBAAA;EACA,6BAAA;EACA,yBAAA;EACA,iCAAA;AADJ;;AAKA;EACE,YAAA;EAEA,gCAAA;AAHF","sourcesContent":[":host {\n  display: block;\n}\n\n.fu-swiper {\n  &__slide:first-child {\n    margin-left: var(--fu-content-padding-side);\n  }\n\n  &__slide {\n    --slide-width: 300px;\n    min-width: var(--slide-width);\n    width: var(--slide-width);\n    margin-right: var(--fu-spacing-s);\n  }\n}\n\nswiper-container {\n  height: 100%;\n\n  --swiper-pagination-bottom: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
