import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';

export const GENERAL_ANIMATIONS = [
   trigger('createDestroyHeight', [
      transition(':enter', [
         style({
            transformOrigin: 'center top',
            transform: 'scaleY(0)',
            overflow: 'hidden'
         }),
         animate('500ms ease', style({
            transform: 'scaleY(1)'
         }))
      ]),
      transition(':leave', [
         animate('500ms ease', style({
            overflow: 'hidden',
            height: '0',
            marginTop: '0',
            marginRight: '0',
            marginBottom: '0',
            marginLeft: '0',
            paddingTop: '0',
            paddingRight: '*',
            paddingBottom: '0',
            paddingLeft: '*'
         }))
      ]),
   ]),

   trigger('createDestroyHeightOpacity', [
      transition(':enter', [
         style({
            opacity: '0',
            height: '0',
            overflow: 'hidden'
         }),
         animate('500ms ease', style({
            opacity: '1',
            height: '*',
            overflow: 'hidden'
         }))
      ]),
      transition(':leave', [
         animate('500ms ease', style({
            overflow: 'hidden',
            opacity: '0',
            height: '0',
            marginTop: '0',
            marginRight: '0',
            marginBottom: '0',
            marginLeft: '0',
            paddingTop: '0',
            paddingRight: '*',
            paddingBottom: '0',
            paddingLeft: '*'
         }))
      ]),
   ]),

   trigger('createDestroyHeightFast', [
      transition(':enter', [
         style({
            height: '0'
         }),
         animate('100ms ease', style({
            height: '*'
         }))
      ]),
      transition(':leave', [
         animate('100ms ease', style({
            height: '0',
            marginTop: '0',
            marginRight: '0',
            marginBottom: '0',
            marginLeft: '0',
            paddingTop: '0',
            paddingRight: '*',
            paddingBottom: '0',
            paddingLeft: '*'
         }))
      ]),
   ]),

   trigger('createDestroyHeightOpacityFast', [
      transition(':enter', [
         style({
            opacity: '0',
            height: '0'
         }),
         animate('100ms ease', style({
            opacity: '1',
            height: '*'
         }))
      ]),
      transition(':leave', [
         animate('100ms ease', style({
            opacity: '0',
            height: '0',
            marginTop: '0',
            marginRight: '0',
            marginBottom: '0',
            marginLeft: '0',
            paddingTop: '0',
            paddingRight: '*',
            paddingBottom: '0',
            paddingLeft: '*'
         }))
      ]),
   ]),

   trigger('modalWindowGrow', [
      transition(':enter', [
         style({
            transform: 'scale3d(0, 0, 0)',
         }),
         animate('100ms ease', style({
            transform: 'scale3d(1, 1, 1)',
         }))
      ]),
   ]),

   trigger('collapse', [
      state('true', style({height: AUTO_STYLE, visibility: AUTO_STYLE})),
      state('false', style({height: '0', visibility: 'hidden'})),
      transition('false => true', animate(300 + 'ms ease-in-out')),
      transition('true => false', animate(300 + 'ms ease-in-out'))
   ]),

   trigger('collapseFaqs', [
      state('false', style({height: AUTO_STYLE, visibility: AUTO_STYLE})),
      state('true', style({height: '0', visibility: 'hidden'})),
      transition('false => true', animate(300 + 'ms ease-out')),
      transition('true => false', animate(300 + 'ms ease-in'))
   ]),

   trigger('collapseFutureAppointments', [
      state('true', style({height: AUTO_STYLE, visibility: AUTO_STYLE})),
      state('false', style({height: AUTO_STYLE, visibility: AUTO_STYLE})),
      transition('false => true', animate(300 + 'ms ease-in-out')),
      transition('true => false', animate(300 + 'ms ease-in-out'))
   ]),

   trigger('rotateY', [
      state('true', style({visibility: AUTO_STYLE})),
      state('false', style({visibility: AUTO_STYLE, transform: 'scaleY(-1)'})),
      transition('false => true', animate(300 + 'ms')),
      transition('true => false', animate(300 + 'ms'))
   ]),

   trigger('collapse-arrow', [
      state('true', style({bottom: '1rem'})),
      state('false', style({bottom: '1rem', transform: 'scaleY(-1)'})),
      transition('false => true', animate(300 + 'ms')),
      transition('true => false', animate(300 + 'ms'))
   ]),
  trigger('scaleAnimation', [
    transition(':enter', [style({transform: 'scale(0)', opacity: 0}),
      animate('4000ms ease', style({transform: 'scale(1)', opacity: 1}))
    ]),
    transition(':leave', [style({transform: 'scale(1)', opacity: 1}),
        animate('2000ms ease', style({transform: 'scale(0)', opacity: 0}))])
  ])
];
