import { AbstractControl, FormArray, FormControl } from '@angular/forms';

export function getFormControls<T>(formControl: AbstractControl<T[], T[]>): FormControl<T>[] {
  if (formControl instanceof FormArray) {
    return formControl.controls as FormControl[];
  }
  throw Error('No form array.');
}

export function getCheckedFormElements<T>(formValues: boolean[], mappedValues: T[]): T[] | [] {
  return formValues.map((checked, index) => (checked ? mappedValues[index] : undefined)).filter(v => !!v);
}
