import { Injectable } from '@angular/core';
import { firstValueFrom, interval, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BleDevice, ScanMode } from '@capacitor-community/bluetooth-le';
import { BleClient } from '@capacitor-community/bluetooth-le/dist/esm/bleClient';
import { Platform } from '@ionic/angular';

export interface HeartRateValue {
  time: number;
  heartRate: number;
  error?: string;
}

export interface ConnectedDevice {
  deviceId: string;
  name: string;
  heartRate$: Observable<HeartRateValue>;
}

@Injectable({
  providedIn: 'root',
})
export class HeartRateService {
  private readonly HEART_RATE_SERVICE = '0000180d-0000-1000-8000-00805f9b34fb';
  private readonly HEART_RATE_MEASUREMENT_CHARACTERISTIC = '00002a37-0000-1000-8000-00805f9b34fb';
  private readonly destroy$ = new Subject<void>();

  private heartRate$: Subject<HeartRateValue> | undefined;
  private connectedDevice: BleDevice | undefined;
  private isInitialised = false;

  constructor(private readonly platform: Platform) {}

  public async connectAndMonitorHeartRateMonitor(): Promise<ConnectedDevice> {
    if (!!this.heartRate$) {
      return {
        deviceId: this.connectedDevice?.deviceId ?? '',
        name: this.connectedDevice?.name ?? '',
        heartRate$: this.heartRate$,
      };
    }

    try {
      await this.init();

      const device = await this.findDevice();
      if (!!device) {
        console.log('connected to device', device);
        this.connectedDevice = device;
        this.heartRate$ = new Subject<HeartRateValue>();

        await this.connectAndMonitor(device.deviceId);

        return {
          deviceId: device.deviceId,
          name: device.name,
          heartRate$: this.heartRate$.pipe(takeUntil(this.destroy$)),
        };
      } else {
        this.heartRate$ = undefined;
        this.connectedDevice = undefined;
        return Promise.reject('NOT_FOUND');
      }
    } catch (e) {
      console.error('Error requesting device', e);
      this.heartRate$ = undefined;
      this.connectedDevice = undefined;
      return Promise.reject(e);
    }
  }

  public async disconnect(): Promise<void> {
    if (!!this.connectedDevice) {
      this.destroy$.next();
      const deviceId = this.connectedDevice.deviceId;
      this.connectedDevice = undefined;
      try {
        await BleClient.stopNotifications(
          deviceId,
          this.HEART_RATE_SERVICE,
          this.HEART_RATE_MEASUREMENT_CHARACTERISTIC
        );
      } catch (e) {
        console.error('Error stopping notifications', e);
      }

      try {
        await BleClient.disconnect(deviceId);
      } catch (e) {
        console.error('Error disconnecting', e);
      }
      this.heartRate$ = undefined;
    }
  }

  private async init(): Promise<void> {
    if (!this.isInitialised) {
      await BleClient.initialize({ androidNeverForLocation: true });
      this.isInitialised = true;
    }
  }

  private onDisconnect(deviceId: string): void {
    console.log('onDisconnect', deviceId);
    if (!!this.connectedDevice && this.connectedDevice.deviceId === deviceId) {
      this.connectAndMonitor(deviceId).catch(e => {
        console.error('Error reconnecting', e);
        this.heartRate$.next({
          time: Date.now(),
          heartRate: -1,
          error: 'DISCONNECTED',
        });
        firstValueFrom(interval(2000)).then(() => this.onDisconnect(deviceId));
      });
    }
  }

  private async findDevices(): Promise<BleDevice[]> {
    const devices: BleDevice[] = [];
    let error: any | undefined = undefined;
    console.log('starting scan');
    BleClient.requestLEScan(
      {
        services: [this.HEART_RATE_SERVICE],
        allowDuplicates: false,
        scanMode: ScanMode.SCAN_MODE_LOW_LATENCY,
      },
      result => {
        console.log('scan result', result);
        devices.push(result.device);
      }
    ).catch(e => {
      console.error('Error scanning', e);
      error = e;
    });

    await firstValueFrom(interval(3000));
    await BleClient.stopLEScan();
    console.log('scan stopped', devices, error);
    if (!!error) {
      throw error;
    }
    return devices;
  }

  private async findDevice(): Promise<BleDevice | undefined> {
    if (this.platform.is('capacitor')) {
      console.log('Finding device');
      const devices = await this.findDevices();
      if (devices.length == 1) {
        return devices[0];
      } else if (devices.length > 1) {
        return BleClient.requestDevice({
          services: [this.HEART_RATE_SERVICE],
          scanMode: ScanMode.SCAN_MODE_LOW_LATENCY,
        });
      } else {
        return undefined;
      }
    } else {
      return BleClient.requestDevice({
        services: [this.HEART_RATE_SERVICE],
        scanMode: ScanMode.SCAN_MODE_LOW_LATENCY,
      });
    }
  }

  private async connectAndMonitor(deviceId: string): Promise<void> {
    await BleClient.connect(deviceId, deviceId => this.onDisconnect(deviceId));

    await BleClient.startNotifications(
      deviceId,
      this.HEART_RATE_SERVICE,
      this.HEART_RATE_MEASUREMENT_CHARACTERISTIC,
      value => {
        const heartRate = this.parseHeartRate(value);
        console.log('current heart rate', heartRate);
        this.heartRate$.next({
          time: Date.now(),
          heartRate,
        });
      }
    );
  }

  private parseHeartRate(value: DataView): number {
    const flags = value.getUint8(0);
    const rate16Bits = flags & 0x1;
    let heartRate: number;
    if (rate16Bits > 0) {
      heartRate = value.getUint16(1, true);
    } else {
      heartRate = value.getUint8(1);
    }
    return heartRate;
  }
}
