import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { SplashScreenService } from '@fitup-monorepo/core/lib/services/splash-screen/splash-screen.service';
import { AuthServerProvider } from '@fitup-monorepo/core/lib/services/auth/auth-jwt.service';

export const welcomePageGuard: CanActivateFn = (): boolean | UrlTree => {
  const authServerProvider = inject(AuthServerProvider);
  const router = inject(Router);
  const splashScreenService = inject(SplashScreenService);
  const isLoggedIn = !!authServerProvider.isAuthenticated();

  if (!isLoggedIn) {
    splashScreenService.hide();

    return true;
  }
  return router.createUrlTree(['tabs', 'home']);
};
