import { Injectable } from '@angular/core';
import { AuthServerProvider } from '../auth/auth-jwt.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isObjectOfType } from '../../util/type-util';

export enum Permission {
  basic = 'basic',
  live = 'live',
  nutrition = 'nutrition',
  teamChallenge = 'team-challenge',
  adminDashboard = 'admin-dashboard',
  trainer = 'trainer',
  pinboard = 'pinboard',
  fitAtWorkReminder = 'fit-at-work-reminder',
  mediaCenter = 'media-center',
  waterIntake = 'water-intake',
  miniChallenge = 'mini-challenge',
  activityTracking = 'activity-tracking',
  publicChallenge = 'public-challenge',
  virtualCoach = 'virtual-coach',
  free = 'free'
}

export interface DecodedToken {
  sub: string;
  auth: string;
  permissions: Permission[];
  partnerId: number;
  exp: number;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor(private readonly authServerProvider: AuthServerProvider) {}

  public observerPermissions(): Observable<Set<Permission>> {
    return this.authServerProvider.observeToken().pipe(
      map(token => {
        const decodedToken = this.parseToken(token);
        if (decodedToken) {
          return new Set(decodedToken.permissions);
        } else {
          return new Set<Permission>();
        }
      })
    );
  }

  public getAllPermissions(): Set<Permission> {
    const token = this.getToken();
    if (token) {
      return new Set(token.permissions);
    }
    return new Set();
  }

  public hasPermission(permission: Permission): boolean {
    const token = this.getToken();
    if (token) {
      return token.permissions.includes(permission);
    }
    return false;
  }

  public isCorporateUser(): boolean {
    const partnerId = this.getToken().partnerId;
    return !!partnerId && partnerId > 0;
  }

  private getToken(): DecodedToken | undefined {
    const token = this.authServerProvider.observeToken().value;
    return this.parseToken(token);
  }

  private parseToken(token: string): DecodedToken | undefined {
    if (!token) {
      return undefined;
    }
    const decodedToken = Buffer.from(token.split('.')[1], 'base64').toString('utf-8');
    const parsedToken = JSON.parse(decodedToken);
    if (!this.isDecodedToken(parsedToken)) {
      return undefined;
    }
    return parsedToken;
  }

  public hasRole(role: string): boolean {
    const token = this.getToken();
    if (!!token) {
      return token.auth.includes(role);
    }
    return false;
  }

  private isDecodedToken(token: unknown): token is DecodedToken {
    return isObjectOfType(token, ['permissions', 'auth']);
  }
}
