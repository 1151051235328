import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'distance',
  standalone: true,
})
export class DistancePipe implements PipeTransform {
  public static readonly kilometer = 1000;

  constructor(private readonly translateService: TranslateService) {}

  public transform(value: number, showUnit = false, showOnlyUnit = false): string {
    if (value < DistancePipe.kilometer) {
      return `${showOnlyUnit ? '' : formatNumber(value, this.translateService.currentLang, '1.0-0')}${
        showUnit ? ' m' : ''
      }`;
    }
    return `${
      showOnlyUnit ? '' : formatNumber(value / DistancePipe.kilometer, this.translateService.currentLang, '1.1-2')
    }${showUnit ? ' km' : ''}`;
  }
}
