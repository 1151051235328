import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { badgesPageActions } from './badges.page.actions';
import { badgeActions } from './state/badge.actions';
import { map } from 'rxjs/operators';

@Injectable()
export class BadgesPageEffects {
  public loadEarnedBadges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(badgesPageActions.loadEarnedBadges),
      map(() => badgeActions.loadEarnedBadges())
    )
  );

  constructor(private readonly actions$: Actions) {}
}
