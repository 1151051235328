import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { OrientationType, ScreenOrientationPlugin } from '@capawesome/capacitor-screen-orientation';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { SCREEN_ORIENTATION } from '../../capacitor-injection-tokens';

export enum AppScreenOrientation {
  portrait = 'portrait',
  landscape = 'landscape',
  free = 'any'
}

@Injectable({
  providedIn: 'root'
})
export class AppScreenOrientationService {
  private unlocked = false;
  private loggingEnabled = false;
  constructor(
    private readonly platform: Platform,
    @Inject(SCREEN_ORIENTATION) readonly screenOrientation: ScreenOrientationPlugin
  ) {}

  private log(...data: any[]): void {
    if (this.loggingEnabled) console.log('AppScreenOrientationService:', data);
  }

  public async lock(orientation: AppScreenOrientation): Promise<void> {
    const currentOrientation = await this.screenOrientation.getCurrentOrientation();
    console.log('AppScreenOrientationService: lock', orientation, this.unlocked, currentOrientation);
    if (this.platform.is('cordova')) {
      if (orientation === AppScreenOrientation.free) {
        this.unlocked = true;
        await this.screenOrientation.unlock();
      } else {
        if ((currentOrientation.type as unknown) !== orientation) {
          this.screenOrientation
            .lock({
              type:
                orientation === AppScreenOrientation.landscape ? OrientationType.LANDSCAPE : OrientationType.PORTRAIT
            })
            .catch(e => console.log('error locking screen orientation', e));
        }
      }
    }
  }

  public async unlock(): Promise<void> {
    const currentOrientation = await this.screenOrientation.getCurrentOrientation();
    console.log('AppScreenOrientationService: unlock', this.unlocked, currentOrientation.type);
    if (this.platform.is('cordova')) {
      if (this.unlocked || (currentOrientation.type as unknown) !== AppScreenOrientation.portrait) {
        this.screenOrientation
          .lock({ type: OrientationType.PORTRAIT })
          .catch(e => console.log('error locking screen orientation', e));
      }
    }
  }

  public async handleInsets(from: string): Promise<void> {
    if (this.platform.is('android')) {
      const style = document.documentElement.style;

      try {
        const windowHeight = this.platform.height();
        const windowWidth = this.platform.width();
        const screenHeight = window.screen.height;
        const screenWidth = window.screen.width;
        const innerHeight = window.innerHeight;
        const innerWidth = window.innerWidth;

        this.log(`init from ${from}`);
        this.log(`window height: ${windowHeight}, width: ${windowWidth}`);
        this.log(`screen height: ${screenHeight}, width: ${screenWidth}`);
        this.log(`screen inner height: ${innerHeight}`);
        this.log(`screen inner width: ${innerWidth}`);
        const insets = await SafeArea.getSafeAreaInsets();
        this.log(`insets`, JSON.stringify(insets));

        if (windowHeight === 0 || windowWidth === 0) {
          const delay = (from ? from.length : 10) * 10;
          this.log('window height or width is 0, retrying in ', delay);
          if (delay <= 3000) {
            setTimeout(() => this.handleInsets(`retry ${from}`), delay);
          }
          return;
        }

        const topInset = insets.insets.top;
        const bottomInset = insets.insets.bottom;
        const rightInset = insets.insets.right;
        const leftInset = insets.insets.left;

        this.log(`setting top safe-area to ${topInset}px`);
        style.setProperty('--ion-safe-area-top', `${topInset}px`);
        style.setProperty('--ion-safe-area-left', `${leftInset}px`);

        if (screenHeight - bottomInset - innerHeight < 0) {
          // means the window is fullscreen, and we need to set the bottom safe-area
          this.log(`setting bottom safe-area to ${bottomInset}px`);
          style.setProperty('--ion-safe-area-bottom', `${bottomInset}px`);
        } else {
          this.log(`setting bottom safe-area to 0`);
          style.setProperty('--ion-safe-area-bottom', '0px');
        }

        if (screenWidth - rightInset - innerWidth < 0) {
          this.log(`setting right safe-area to ${rightInset}px`);
          style.setProperty('--ion-safe-area-right', `${rightInset}px`);
        } else {
          this.log(`setting right safe-area to 0`);
          style.setProperty('--ion-safe-area-right', '0px');
        }
      } catch (e) {
        console.log('error getting insets', e);
      }
    }
  }

  public async init(from: string): Promise<void> {
    if (this.platform.is('cordova')) {
      this.screenOrientation
        .lock({ type: OrientationType.PORTRAIT })
        .then(() => console.log('screen orientation locked'))
        .catch(e => console.log('error locking screen orientation', e));
      await this.handleInsets(from);
    }
  }
}
