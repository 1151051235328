import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WaterIntake } from './model/water-intake';
import { Observable } from 'rxjs';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class WaterIntakeService {
  constructor(private readonly httpClient: HttpClient) {}

  public getWaterIntake(): Observable<WaterIntake> {
    return this.httpClient.get<WaterIntake>(`${ApiService.API_URL}/water-intake`);
  }

  public addWaterIntake(amountMl: number): Observable<WaterIntake> {
    return this.httpClient.put<WaterIntake>(`${ApiService.API_URL}/water-intake`, { ['amountMl']: amountMl });
  }
}
