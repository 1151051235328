import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { selectEarnedBadgesPreview } from '@fitup-monorepo/badges/lib/state/badge.selector';
import { calculateAge } from '@fitup-monorepo/core/lib/util/date.util';
import { PublicProfile, UserMembership } from '@fitup-monorepo/core/lib/model/public-profile';
import {
  selectCustomer,
  selectMembership,
  selectPartner,
  selectProfileFeature
} from '@fitup-monorepo/core/lib/state/profile/profile.selector';
import { PrivacyLevel } from '@fitup-monorepo/core/lib/model/privacy-level';
import { SubscriptionStatus } from '@fitup-monorepo/core/lib/model/customer-membership';

export const selectOwnProfile: MemoizedSelector<any, PublicProfile> = createSelector(
  selectProfileFeature,
  selectCustomer,
  selectMembership,
  selectPartner,
  selectEarnedBadgesPreview,
  (_, customer, membership, partner, badges) => ({
    nickname: customer.nickname,
    profileType: PrivacyLevel.public,
    image: customer.image,
    membership:
      membership && membership.status === SubscriptionStatus.active
        ? UserMembership.premium
        : partner
        ? UserMembership.corporate
        : undefined,
    partnerName: partner?.title,
    firstName: customer.firstName,
    lastName: customer.lastName,
    age: customer.birthDate ? calculateAge(customer.birthDate) : undefined,
    lastBadges: badges
  })
);
