// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  min-height: calc(100vh - 180px + var(--ion-safe-area-bottom));
}
.error__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  flex-grow: 1;
  height: 100%;
}
.error__icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  background-color: var(--fu-color-error);
  margin-bottom: 10px;
}
.error__icon {
  color: var(--fu-pure-white);
  font-size: 32px;
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/error/error.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,6DAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,uCAAA;EACA,mBAAA;AAAJ;AAGE;EACE,2BAAA;EACA,eAAA;AADJ","sourcesContent":[".error {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  padding: 16px 20px;\n  min-height: calc(100vh - 180px + var(--ion-safe-area-bottom));\n\n  &__description {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    gap: 10px;\n    flex-grow: 1;\n    height: 100%;\n  }\n\n  &__icon-circle {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    height: 64px;\n    width: 64px;\n    background-color: var(--fu-color-error);\n    margin-bottom: 10px;\n  }\n\n  &__icon {\n    color: var(--fu-pure-white);\n    font-size: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
