import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AuthServerProvider } from '../services/auth/auth-jwt.service';
import { LoginService } from '../services/login/login.service';
import { PurchaseService } from '../services/purchase/purchase.service';
import { SplashScreenService } from '../services/splash-screen/splash-screen.service';
import { environment } from '../environment';

export async function isAuthenticated(authServerProvider: AuthServerProvider): Promise<boolean> {
  const authenticated = authServerProvider.isAuthenticated();
  return authenticated;
}

export const authGuard: CanActivateFn = async () => {
  const authServerProvider = inject(AuthServerProvider);
  const loginService = inject(LoginService);
  const purchaseService = inject(PurchaseService);
  const splashScreenService = inject(SplashScreenService);
  const router = inject(Router);

  try {
    let authenticated = false;
    if (await isAuthenticated(authServerProvider)) {
      authenticated = true;
      if (environment.appName === 'fitup') {
        await purchaseService.init();
      }
    }

    await splashScreenService.hide();
    return authenticated;
  } catch (e) {
    console.error('Auth error', JSON.stringify(e));
    if (e instanceof HttpErrorResponse && e.status === HttpStatusCode.Forbidden) {
      await loginService.logout();
    }
  }
  return router.createUrlTree(['']);
};
