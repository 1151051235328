import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { NavigationExtras, Router, RouterLink } from '@angular/router';
import { navigateToDetail, recipeCardFooter } from '../../../util/nutrition-planner.util';
import {
  CardSlideDirective,
  CardSwiperComponent
} from '@fitup-monorepo/components/lib/swiper/card-swiper/card-swiper.component';
import { CardComponent } from '@fitup-monorepo/components/lib/card/card.component';
import { TranslatableTextPipe } from '@fitup-monorepo/core/lib/pipes/translatable-text.pipe';
import { InfoBannerComponent } from '@fitup-monorepo/components/lib/info-banner/info-banner.component';
import { FbMealType, FbRecipe } from '../../../model/firestore-plan';

@Component({
  selector: 'app-recipe-list',
  templateUrl: './recipe-list.component.html',
  styleUrls: ['./recipe-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    CardSwiperComponent,
    CardComponent,
    TranslatableTextPipe,
    CardSlideDirective,
    IonicModule,
    RouterLink,
    InfoBannerComponent
  ]
})
export class RecipeListComponent {
  @Input({ required: true })
  public recipes!: FbRecipe[];

  @Input({ required: true })
  public mealType!: FbMealType;

  constructor(private readonly router: Router) {}

  protected async navigateToDetail(recipe: FbRecipe): Promise<void> {
    await navigateToDetail(this.router, recipe.id, undefined, true);
  }

  protected async navigateToOverview(mealType: FbMealType): Promise<void> {
    const extras: NavigationExtras = {
      queryParams: {
        mealType
      }
    };

    await this.router.navigate(['/recipe-overview'], extras);
  }

  protected readonly recipeCardFooter = recipeCardFooter;
}
