import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Avatar } from '../image-picker-modal/model/avatar';
import { ImagePickerDirective } from '../image-picker-modal/image-picker.directive';
import { defaultProfileImage } from '@fitup-monorepo/core/lib/constants';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IonicModule, ImagePickerDirective],
  standalone: true
})
export class ProfilePictureComponent {
  @Input()
  public isReadonly = false;

  @Input()
  public set imageUrl(url: string | undefined) {
    this.imageUrl$.next(url);
  }

  @Input()
  public isOnboardingMode = false;

  @Output()
  public updateAvatar = new EventEmitter<Avatar>();

  @Output()
  public readonly updateImageUrl = new EventEmitter<string>();

  @Output()
  public readonly removePicture = new EventEmitter<void>();

  public readonly imageUrl$ = new BehaviorSubject<string | undefined>(undefined);
  private readonly defaultOnboardImage: string = 'svg/edit-profile-image.svg';

  @HostBinding('class')
  public get imageSizeClass(): string {
    return !this.isOnboardingMode ? 'small' : undefined;
  }

  protected getImageSrc(url: string | undefined): string {
    if (!url && this.isOnboardingMode) {
      return this.defaultOnboardImage;
    }
    return url ?? defaultProfileImage;
  }
}
