import { Session } from '@fitup-monorepo/core/lib/entities/session.model';

function hasSessionRating(sessionId: number, sessionRating: Map<number, number[]>): boolean {
  return !!(sessionRating && sessionRating.size > 0 && sessionRating.get(sessionId));
}

export function getSessionRating(session: Session, sessionRating: Map<number, number[]>): number {
  return hasSessionRating(session.product.id, sessionRating) ? sessionRating.get(session?.product?.id)[0] : undefined;
}

export function getSessionRatingCount(session: Session, sessionRating: Map<number, number[]>): number {
  return hasSessionRating(session.product.id, sessionRating)
    ? sessionRating.get(session?.product?.id)[1] + 12
    : undefined;
}

export function getBorderColorByCategory(category: string): string {
  switch (category) {
    case 'FULL_BODY':
      return '#FF4A4A';
    case 'PILATES':
      return '#6EE032';
    case 'STRENGTH':
      return '#FF4A4A';
    case 'HEALTHY_BACK':
      return '#FFB800';
    case 'STRETCHING':
      return '#FFB800';
    case 'YOGA':
      return '#6EE032';
    case 'MEDITATION':
      return '#BD6EFC';
    case 'ABS':
      return '#FF4A4A';
    case 'CARDIO':
      return '#FF4A4A';
    case 'CORE':
      return '#FF4A4A';
    case 'HIIT':
      return '#FF4A4A';
    case 'FIT_AM_ARBEITSPLATZ':
      return '#FFB800';
    default:
      return '#FF4A4A';
  }
}
