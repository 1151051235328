import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { WizardService } from '@fitup-monorepo/core/lib/services/wizard/wizard.service';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthServerProvider } from '@fitup-monorepo/core/lib/services/auth/auth-jwt.service';
import { WIZARD_SERVICE } from '@fitup-monorepo/core/lib/capacitor-injection-tokens';

@Component({
  selector: 'app-subscription-free-trial-onboard',
  templateUrl: './subscription-free-trial-onboard.page.html',
  styleUrls: ['./subscription-free-trial-onboard.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionFreeTrialOnboardPage {
  constructor(
    @Inject(WIZARD_SERVICE) private readonly wizardService: WizardService,
    private readonly localStorageService: LocalStorageService,
    private readonly authServerProvider: AuthServerProvider
  ) {}

  public async onSuccess(): Promise<void> {
    this.localStorageService.store(
      'subscriptionOnboardingInfo',
      JSON.stringify({ hasSubscribed: true, planId: 'TRIAL' })
    );
    await this.authServerProvider.updateJWT();
    await this.wizardService.navigateToNextStep();
  }
}
