// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rate-button + .rate-button {
  margin-left: 24px;
}

.rate-button {
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/feedback/rating-component/ionic-rating-component.ts"],"names":[],"mappings":"AACM;EACE,iBAAA;AAAR;;AAGM;EACE,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AAAR","sourcesContent":["\n      .rate-button + .rate-button {\n        margin-left: 24px;\n      }\n\n      .rate-button {\n        --padding-bottom: 0;\n        --padding-end: 0;\n        --padding-start: 0;\n        --padding-top: 0;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
