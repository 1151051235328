import { createActionGroup, props } from '@ngrx/store';

export const challengeDetailActions = createActionGroup({
  source: 'Challenge Detail Page',
  events: {
    ['Load Challenge']: props<{ challengeId: number }>(),
    ['Join Challenge']: props<{ challengeId: number }>(),
    ['Leave Challenge']: props<{ challengeId: number }>(),
  }
});
