import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { groupDetailActions } from './group-detail.actions';
import { map } from 'rxjs/operators';
import { groupActions } from '../../state/group/group.actions';

@Injectable()
export class GroupDetailEffects {
  public readonly joinGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupDetailActions.joinGroup),
      map(action => groupActions.joinGroup(action))
    )
  );

  public readonly leaveGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupDetailActions.leaveGroup),
      map(action => groupActions.leaveGroup(action))
    )
  );

  constructor(private readonly actions$: Actions) {}
}
