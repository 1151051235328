import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ActivityMutationService } from './activity-mutation.service';
import { ActivityCreateRequest } from '../models/activity-request';
import { firstValueFrom } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { LoadingService } from '@fitup-monorepo/core/lib/services/loading/loading.service';
import { ModalService } from '@fitup-monorepo/components/lib/confirmation-modal/confirmation-modal.service';
import { PointsService } from '@fitup-monorepo/components/lib/points/points.service';
import { CompletedActivityDetails } from '../models/activity-list';
import { StateService } from '@fitup-monorepo/core/lib/services/state/state.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityStorageService {
  constructor(
    private readonly activityMutationService: ActivityMutationService,
    private readonly localStorageService: LocalStorageService,
    private readonly navController: NavController,
    private readonly loadingService: LoadingService,
    private readonly modalService: ModalService,
    private readonly pointsService: PointsService,
    private readonly stateService: StateService
  ) {}

  public async checkForUnsavedActivity(): Promise<void> {
    const { isTrackingActivity } = this.stateService.getState();
    console.log('checking for unsaved activity, isTracking: ', isTrackingActivity);
    if (!isTrackingActivity) {
      const unsavedActivity: ActivityCreateRequest | undefined =
        this.localStorageService.retrieve('unsavedActivityRequest') ?? undefined;

      if (unsavedActivity) {
        console.log('found unsaved activity request', JSON.stringify(unsavedActivity));
        await this.persistActivity(unsavedActivity);
      }
    }
  }

  public async persistActivity(activityRequest: ActivityCreateRequest): Promise<void> {
    try {
      await this.loadingService.showLoading('ACTIVITY.ACTIVITY_TRACKING.SAVING');
      const savedActivity = await firstValueFrom(this.activityMutationService.saveActivity(activityRequest));
      await this.#handleSuccess(savedActivity);
    } catch (e) {
      await this.#handleError(e, activityRequest);
    }
  }

  async #handleSuccess({ id, fitPointEarningSummary }: CompletedActivityDetails): Promise<void> {
    await this.loadingService.hideLoading();
    await this.pointsService.showFitPointModal(fitPointEarningSummary, 'POINTS.ACTIVITY_BUTTON');
    await this.navController.navigateRoot(['activity', 'activity-list', 'activity-summary', 'tracking-activity', id]);
    this.localStorageService.clear('unsavedActivityRequest');
  }

  async #handleError(error: unknown, activityRequest: ActivityCreateRequest): Promise<void> {
    console.error('Error while saving activity', JSON.stringify(error));
    await this.loadingService.hideLoading();
    const confirmed = await this.confirmSavingActivity();
    if (confirmed) {
      await this.navController.navigateForward(['tabs', 'activity']);
    }
    await this.localStorageService.store('unsavedActivityRequest', activityRequest);
  }

  private async confirmSavingActivity(): Promise<boolean> {
    const modalDescription = window.navigator.onLine
      ? 'ACTIVITY.MESSAGES.ACTIVITY_SAVING_ERROR_INFO'
      : 'ACTIVITY.MESSAGES.ACTIVITY_SAVED_OFFLINE_ERROR';

    return this.modalService.showConfirmation({
      title: 'ACTIVITY.MESSAGES.ACTIVITY_SAVING_ERROR',
      description: modalDescription,
      confirmButtonLabel: 'ACTIVITY.MESSAGES.OK_CONTINUE',
      iconUrl: 'svg/info-circle-solid.svg',
      iconColor: 'var(--fu-light-red)',
      dismissible: true
    });
  }
}
