// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  padding-right: 0;
  padding-left: 0;
}

.border {
  margin-right: var(--fu-content-padding-side);
  margin-left: var(--fu-content-padding-side);
  width: auto;
}`, "",{"version":3,"sources":["webpack://./libs/nutrition-planner/src/lib/nutrition-plan/recipes/recipes.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,4CAAA;EACA,2CAAA;EACA,WAAA;AACF","sourcesContent":[".content {\n  padding-right: 0;\n  padding-left: 0;\n}\n\n.border {\n  margin-right: var(--fu-content-padding-side);\n  margin-left: var(--fu-content-padding-side);\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
