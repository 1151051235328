import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { NotificationPreference } from '../../../model/notification-preference';

@Injectable({
  providedIn: 'root'
})
export class NotificationPreferencesService {
  public static readonly liveClasses = 'live-session';
  public static readonly shorts = 'shorts';
  constructor(private readonly httpClient: HttpClient) {}

  public getNotificationPreferences(): Observable<NotificationPreference[]> {
    return this.httpClient.get<NotificationPreference[]>(`${ApiService.API_URL}/notifications/preferences`);
  }

  public saveNotificationPreferences(preferences: NotificationPreference[]): Observable<NotificationPreference[]> {
    return this.httpClient.post<NotificationPreference[]>(
      `${ApiService.API_URL}/notifications/preferences`,
      preferences
    );
  }

  public disableAll(): Observable<void> {
    return this.httpClient.post<void>(`${ApiService.API_URL}/notifications/disable-all`, null);
  }

  public setEnabled(id: string, enabled: boolean): Observable<boolean> {
    return this.httpClient.put<boolean>(`${ApiService.API_URL}/notifications/preferences/${id}`, null, {
      params: {
        enabled
      }
    });
  }
}
