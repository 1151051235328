import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { interval, Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalizedNumberPipe } from '@fitup-monorepo/core/lib/pipes/localized-number.pipe';

export interface Countdown {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, LocalizedNumberPipe]
})
export class CountdownComponent {
  @Input({ required: true })
  public dueDate!: Date | string;

  protected readonly countdown$ = this.initCountdown();

  private initCountdown(): Observable<Countdown | undefined> {
    const secondInMs = 1000;
    return interval(secondInMs).pipe(
      startWith(0),
      map(() => this.calculateCountdown())
    );
  }

  private calculateCountdown(): Countdown {
    const now = new Date();
    const targetDate = new Date(this.dueDate);

    const timeDifference = targetDate.getTime() - now.getTime();

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }
}
