import { Injectable } from '@angular/core';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Meal, MealStatus, PlanProgressDetail } from './model/plan';
import { MealAddRequest, MealRequest, MealSwapRequest, MealTrackRequest } from './model/plan-request';

@Injectable({
  providedIn: 'root'
})
export class PlanMutationService {
  private readonly planBaseUrl = `${ApiService.API_URL}/nutrition/my-plan`;

  constructor(private readonly httpClient: HttpClient) {}

  public startPlan(planId: string): Observable<PlanProgressDetail> {
    return this.httpClient.post<PlanProgressDetail>(`${this.planBaseUrl}/${planId}`, null);
  }

  public endPlan(planId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.planBaseUrl}/${planId}`);
  }

  public swapMeal({
    planId,
    mealIndex,
    mealType,
    week,
    day,
    recipeId,
    body,
    status,
    numOfPortions
  }: MealSwapRequest): Observable<Meal> {
    const recipeIdPatch = recipeId ? { recipeId } : {};
    const numOfPortionsPatch = numOfPortions ? { numOfPortions } : {};
    return this.httpClient.post<Meal>(
      `${this.planBaseUrl}/${planId}/week/${week}/day/${day}/meal/${mealIndex}/${mealType}`,
      body ?? null,
      {
        params: {
          ...recipeIdPatch,
          ...numOfPortionsPatch,
          status
        }
      }
    );
  }

  public addMeal({ planId, week, day, body, status, numOfPortions }: MealAddRequest): Observable<Meal> {
    const numOfPortionsPatch = numOfPortions ? { numOfPortions } : {};
    return this.httpClient.post<Meal>(`${this.planBaseUrl}/${planId}/week/${week}/day/${day}/meal`, body ?? null, {
      params: { status, ...numOfPortionsPatch }
    });
  }

  public trackMeal({ mealIndex, planId, mealType, week, day, numOfPortions }: MealTrackRequest): Observable<Meal> {
    return this.httpClient.put<Meal>(
      `${this.planBaseUrl}/${planId}/week/${week}/day/${day}/meal/${mealIndex}/${mealType}`,
      null,
      {
        params: {
          numOfPortions,
          status: MealStatus.tracked
        }
      }
    );
  }

  public untrackMeal({ planId, mealIndex, mealType, week, day }: MealTrackRequest): Observable<Meal> {
    return this.httpClient.put<Meal>(
      `${this.planBaseUrl}/${planId}/week/${week}/day/${day}/meal/${mealIndex}/${mealType}`,
      null,
      {
        params: {
          status: MealStatus.open
        }
      }
    );
  }

  public deleteMeal({ planId, mealIndex, mealType, week, day }: MealRequest): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.planBaseUrl}/${planId}/week/${week}/day/${day}/meal/${mealIndex}/${mealType}`
    );
  }
}
