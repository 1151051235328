import { Badge } from './badge';
import { PrivacyLevel } from './privacy-level';

export enum UserMembership {
  premium = 'Premium',
  corporate = 'Corporate',
}

export interface PublicProfile {
  nickname: string;
  profileType: PrivacyLevel;
  membership?: UserMembership;
  partnerName?: string;
  firstName?: string;
  lastName?: string;
  image?: string;
  age?: number;
  currentMonthFitPoints?: number;
  currentMonthActiveDurationSeconds?: number;
  numOfBadges?: number;
  lastBadges: Badge[];
}
