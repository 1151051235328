import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StartSubscriptionComponent } from './start-subscription.component';
import { IonicModule, ModalController } from '@ionic/angular';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { SubscriptionSuccessComponent } from '../subscription-success/subscription-success.component';
import { dismissModalIfExistent } from '../subscription.utils';
import {MembershipPlan} from "@fitup-monorepo/core/lib/model/purchase/purchase";

@Component({
  selector: 'app-start-subscription-modal',
  templateUrl: './start-subscription-modal.component.html',
  styleUrls: ['./start-subscription-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, StartSubscriptionComponent, HeaderComponent]
})
export class StartSubscriptionModalComponent {
  @Input()
  public plan: MembershipPlan | undefined;

  @Input()
  public hasFitnessOnlyMembership = false;

  @Input()
  public discount: number | undefined;

  private static readonly modalId = 'start-subscription';

  constructor(private readonly modalController: ModalController) {}

  public static async openModal(
    modalController: ModalController,
    selectedPlan: MembershipPlan,
    hasFitnessOnlyMembership: boolean,
    discount: number
  ): Promise<void> {
    const ref = await modalController.create({
      component: StartSubscriptionModalComponent,
      componentProps: {
        plan: selectedPlan,
        hasFitnessOnlyMembership,
        discount
      },
      id: StartSubscriptionModalComponent.modalId,
      cssClass: 'fu-subscription-modal'
    });
    await ref.present();
  }

  public static closeModal(modalController: ModalController): void {
    dismissModalIfExistent(modalController, StartSubscriptionModalComponent.modalId);
  }

  public onBackButtonClick(): void {
    StartSubscriptionModalComponent.closeModal(this.modalController);
  }

  public async onSuccess(): Promise<void> {
    await SubscriptionSuccessComponent.openModal(this.modalController);
  }
}
