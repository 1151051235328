import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Avatar } from './model/avatar';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AvatarModalService {
  constructor(private readonly httpClient: HttpClient) {}

  public getAvatars(): Observable<Avatar[]> {
    return this.httpClient.get<Avatar[]>(`${ApiService.API_URL}/profile/avatars`);
  }
}
