// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  text-align: center;
  border-radius: var(--fu-radius-s);
  background-color: var(--banner-bg);
  color: var(--banner-color);
  padding: var(--fu-spacing-xl) var(--fu-spacing-m);
}
:host .banner--title {
  font-size: var(--fu-font-size-m);
  font-weight: var(--fu-font-bold);
  margin-bottom: var(--fu-spacing-xs);
}
:host .banner--image {
  max-width: 64px;
}
:host .banner--description {
  font-size: var(--fu-font-size-xxs);
  color: var(--banner-color);
}
:host.has-icon, :host.has-image {
  display: grid;
  grid-gap: var(--fu-spacing-m);
  text-align: left;
  padding: var(--fu-spacing-m);
}
:host.has-image {
  grid-template-columns: 64px auto;
  align-items: center;
}
:host.has-icon {
  grid-template-columns: var(--fu-icon-font-size-regular) auto;
  align-items: flex-start;
}
:host.primary {
  --banner-color: var(--fu-color-primary);
  --banner-bg: rgba(var(--fu-color-primary-rgb), 0.16);
}
:host.grey {
  --banner-bg: var(--fu-grey-14);
  border: 1px solid var(--fu-grey-15);
}
:host.grey .banner--description {
  color: var(--fu-mild-grey);
}
:host.orange {
  --banner-bg: rgba(var(--fu-color-primary-rgb), 0.1);
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/banner/banner.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,iCAAA;EACA,kCAAA;EACA,0BAAA;EACA,iDAAA;AACF;AAEI;EACE,gCAAA;EACA,gCAAA;EACA,mCAAA;AAAN;AAGI;EACE,eAAA;AADN;AAII;EACE,kCAAA;EACA,0BAAA;AAFN;AAME;EACE,aAAA;EACA,6BAAA;EACA,gBAAA;EACA,4BAAA;AAJJ;AAOE;EACE,gCAAA;EACA,mBAAA;AALJ;AAQE;EACE,4DAAA;EACA,uBAAA;AANJ;AASE;EACE,uCAAA;EACA,oDAAA;AAPJ;AAUE;EACE,8BAAA;EACA,mCAAA;AARJ;AAWM;EACE,0BAAA;AATR;AAcE;EACE,mDAAA;EACA,6BAAA;AAZJ","sourcesContent":[":host {\n  display: block;\n  text-align: center;\n  border-radius: var(--fu-radius-s);\n  background-color: var(--banner-bg);\n  color: var(--banner-color);\n  padding: var(--fu-spacing-xl) var(--fu-spacing-m);\n\n  .banner {\n    &--title {\n      font-size: var(--fu-font-size-m);\n      font-weight: var(--fu-font-bold);\n      margin-bottom: var(--fu-spacing-xs);\n    }\n\n    &--image {\n      max-width: 64px;\n    }\n\n    &--description {\n      font-size: var(--fu-font-size-xxs);\n      color: var(--banner-color);\n    }\n  }\n\n  &.has-icon, &.has-image {\n    display: grid;\n    grid-gap: var(--fu-spacing-m);\n    text-align: left;\n    padding: var(--fu-spacing-m);\n  }\n\n  &.has-image {\n    grid-template-columns: 64px auto;\n    align-items: center;\n  }\n\n  &.has-icon {\n    grid-template-columns: var(--fu-icon-font-size-regular) auto;\n    align-items: flex-start;\n  }\n\n  &.primary {\n    --banner-color: var(--fu-color-primary);\n    --banner-bg: rgba(var(--fu-color-primary-rgb), 0.16);\n  }\n\n  &.grey {\n    --banner-bg: var(--fu-grey-14);\n    border: 1px solid var(--fu-grey-15);\n\n    .banner {\n      &--description {\n        color: var(--fu-mild-grey);\n      }\n    }\n  }\n\n  &.orange {\n    --banner-bg: rgba(var(--fu-color-primary-rgb), 0.1);\n    background-color: transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
