import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';

export interface PriceTableConfig {
  priceTableId: string;
  recommendedPlanPriceTableId: string;
  publishableKey: string;
  clientReferenceId: string;
  customerEmail: string;
}

@Injectable({
  providedIn: 'root'
})
export class StripeConfigService {
  constructor(private readonly httpClient: HttpClient) {}

  public getPriceTableConfig(): Observable<PriceTableConfig> {
    return this.httpClient.get<PriceTableConfig>(`${ApiService.API_URL}/payment/config/stripe`);
  }
}
