import { Pipe, PipeTransform } from '@angular/core';
import { TranslatableTextService } from '../services/translatable-text/translatable-text.service';
import { TranslatableString } from '../services/translatable-text/translatable-string';

@Pipe({
  name: 'translatableText',
  standalone: true
})
export class TranslatableTextPipe implements PipeTransform {
  constructor(private readonly translatableTextService: TranslatableTextService) {}
  public transform(value: TranslatableString | string): string {
    if (typeof value === 'string') {
      return value;
    }
    return this.translatableTextService.translate(value);
  }
}
