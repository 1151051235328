import {
  AfterViewInit,
  Component,
  ContentChildren,
  CUSTOM_ELEMENTS_SCHEMA,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicSlides } from '@ionic/angular';
import { Swiper } from 'swiper/types';

@Directive({
  selector: '[appSwiperSlide]',
  standalone: true
})
export class SwiperSlideDirective {
  @Input()
  public slideClasses: string[] | { [className: string]: boolean } | string = [];

  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
  imports: [CommonModule, IonicModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true
})
export class SwiperComponent implements AfterViewInit {
  @Input()
  public useSideSpacing = false;

  @Input()
  public spaceBetween = 0;

  @Input()
  public initialSlide = 0;

  @Input()
  public speed = 400;

  @Input()
  public freeMode = false;

  @Input()
  public slidesPerView: number | 'auto' = 'auto';

  @Input()
  public direction = 'horizontal';

  @Input()
  public slideClass: string | undefined;

  @Input()
  public swiperClass: string | undefined;

  @Input()
  public scrollbar = false;

  @Input()
  public centeredSlides = false;

  @Input()
  public centeredSlidesBounds = false;

  @Input()
  public pagination = false;

  @Input()
  public slideWidth: number | undefined;

  @Output()
  public slideChange = new EventEmitter<Swiper>();

  @ViewChild('swiper')
  public swiperElement: ElementRef | undefined;

  @ContentChildren(SwiperSlideDirective)
  public slides: SwiperSlideDirective[];

  public readonly swiperModules = [IonicSlides];

  public get swiperContainer(): Swiper | undefined {
    return this.swiperElement?.nativeElement.swiper;
  }

  public ngAfterViewInit(): void {
    this.swiperContainer?.on('slideChange', event => {
      this.slideChange.emit(event);
    });
  }
}
