import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ApiService } from '../api/api.service';
import { catchError, map } from 'rxjs/operators';
import { ProfileInformation } from '../../model/profile-information';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService {
  constructor(private readonly httpClient: HttpClient) {}

  public applyPromotionCode(code: string): Observable<ProfileInformation> {
    return this.httpClient.put<ProfileInformation>(
      `${ApiService.API_URL}/promotion-codes/apply?promotionCode=${code}`,
      null
    );
  }

  public isValidPromotionCode(code: string): Observable<boolean> {
    return this.httpClient.get<void>(`${ApiService.API_URL}/promotioncodes/${code}`).pipe(
      map(() => true),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
          return of(false);
        }
        return throwError(() => error);
      })
    );
  }
}
