import { DEFAULT_CURRENCY_CODE, Inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

export type CurrencyDisplay = 'code' | 'symbol' | 'symbol-narrow' | string | boolean;

@Pipe({
  name: 'localizedCurrency',
  pure: false,
  standalone: true,
})
export class LocalizedCurrencyPipe implements PipeTransform, OnDestroy {
  private value: string | undefined;
  private langChangeSubscription: Subscription | undefined;

  constructor(
    private readonly translateService: TranslateService,
    @Inject(DEFAULT_CURRENCY_CODE) private readonly defaultCurrencyCode
  ) {}

  public transform(
    value: number,
    currencyCode: string = this.defaultCurrencyCode,
    display: CurrencyDisplay = 'symbol',
    digitsInfo?: string
  ): string {
    this.setCurrentValue(value, currencyCode, display, digitsInfo);
    if (!this.langChangeSubscription) {
      this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
        this.setCurrentValue(value, currencyCode, display, digitsInfo);
      });
    }
    return this.value;
  }

  private setCurrentValue(value: number, currencyCode: string, display: CurrencyDisplay, digitsInfo?: string): void {
    const locale = this.translateService.currentLang ?? 'en';
    let currency: string = currencyCode ?? this.defaultCurrencyCode;
    if (display !== 'code') {
      if (display === 'symbol' || display === 'symbol-narrow') {
        currency = getCurrencySymbol(currency, display === 'symbol' ? 'wide' : 'narrow', locale);
      } else {
        currency = display as string;
      }
    }
    const newValue = formatCurrency(value, locale, currency, currencyCode, digitsInfo);
    this.value = newValue.replace(/\s/g, '');
  }

  public ngOnDestroy(): void {
    this.langChangeSubscription?.unsubscribe();
  }
}
