import { LocalizedCurrencyPipe } from '@fitup-monorepo/core/lib/pipes/localized-currency.pipe';
import { TranslateService } from '@ngx-translate/core';
import { addDays } from '@fitup-monorepo/core/lib/util/date.util';
import { ModalController } from '@ionic/angular';
import {MembershipPlan, PlanType} from "@fitup-monorepo/core/lib/model/purchase/purchase";

export const trialDays = 14;

export function getTrialEndDate(): Date {
  return addDays(new Date(), trialDays);
}
export function formatPrice(
  plan: MembershipPlan,
  translateService: TranslateService,
  useOriginalPrice = false
): string {
  const unformattedPrice = getPrice(
    useOriginalPrice ? plan.price : plan.promotionOfferPrice ?? plan.price,
    plan.planType
  );
  const formattedPrice = new LocalizedCurrencyPipe(translateService, plan.currencyCode).transform(
    unformattedPrice,
    plan.currencyCode,
    'symbol',
    plan.planType === PlanType.annually ? '1.4-4' : '1.2-2'
  );
  return plan.planType === PlanType.annually ? cutDecimalDigitsToTwo(formattedPrice, translateService) : formattedPrice;
}

export const monthAmountMap = new Map<PlanType, number>([
  [PlanType.annually, 12],
  [PlanType.quarterly, 3],
  [PlanType.monthly, 1]
]);

function getPrice(price: number, planType: PlanType): number {
  if (planType === PlanType.monthly) {
    return price;
  }
  return price / monthAmountMap.get(planType);
}

function cutDecimalDigitsToTwo(priceString: string, translateService: TranslateService): string {
  const numberRegex = translateService.currentLang === 'en' ? /[0-9]+(?:\.[0-9]+)?/ : /[0-9]+(?:,[0-9]+)?/;
  const numberString = priceString.match(numberRegex)?.[0];
  const truncatedNumberString = numberString.slice(0, numberString.length - 2);
  return priceString.replace(numberRegex, truncatedNumberString);
}

export function dismissModalIfExistent(modalController: ModalController, modalId: string): void {
  modalController.dismiss(null, null, modalId).catch(e => {
    console.log(e, modalId);
  }); // we don't want an error even if the modal does not exist
}
