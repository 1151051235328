import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PublicProfile } from '@fitup-monorepo/core/lib/model/public-profile';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor(private readonly httpClient: HttpClient) {}

  public getPublicProfile(uuid: string): Observable<PublicProfile> {
    return this.httpClient.get<PublicProfile>(`${ApiService.API_URL}/profile/public/${uuid}`);
  }
}
