import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, IonicModule],
  standalone: true,
})
export class ErrorComponent {
  @Input()
  public showReloadButton = true;

  @Input()
  public errorTitle: string | undefined;

  @Input()
  public errorDescription: string | undefined;

  @Output()
  public readonly reload = new EventEmitter<void>();
}
