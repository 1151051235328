import { Injectable } from '@angular/core';
import { Bugfender } from '@bugfender/capacitor';
import { LocalStorageService } from 'ngx-webstorage';
import { CustomerSelectService } from '../customer-select/customer-select.service';
import { environment } from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private storageKey = 'forceSendOnce';

  constructor(
    private customerSelectService: CustomerSelectService,
    private storageService: LocalStorageService
  ) {}

  public async init(version: string): Promise<void> {
    if (!!environment.bugfender?.appKey) {
      await Bugfender.init({
        appKey: environment.bugfender.appKey,
        overrideConsoleMethods: true,
        printToConsole: true,
        registerErrorHandler: false,
        logBrowserEvents: true,
        logUIEvents: false,
        version: version
      });
      console.log(`Bugfender initialized with version ${version}`);
      if (!this.storageService.retrieve(this.storageKey)) {
        Bugfender.forceSendOnce();
        this.storageService.store(this.storageKey, true);
      }

      this.customerSelectService.selectCustomer().then(customer => {
        Bugfender.setDeviceKey('customerId', `${customer.id}`);
        Bugfender.setDeviceKey('email', `${customer.email}`);
      });
    }
  }
}
