import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { challengesPageActions } from './challenges-page.actions';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Feature, FeatureService } from '@fitup-monorepo/core/lib/services/features/feature.service';
import { selectIsPrivateCustomerInfo } from '@fitup-monorepo/core/lib/state/profile/profile.selector';

export const challengesGuard: CanActivateFn = async () => {
  const store = inject(Store);
  const isPrivateUser = await firstValueFrom(
    store.select(selectIsPrivateCustomerInfo).pipe(filter(i => i !== undefined))
  );
  if (isPrivateUser) {
    store.dispatch(challengesPageActions.loadCommunityOverview());
  } else {
    store.dispatch(challengesPageActions.loadPartnerOverview());
  }

  store.dispatch(challengesPageActions.loadGroupOverview());

  return true;
};

export const challengesFeatureGuard: CanActivateFn = async () => {
  const featureService = inject(FeatureService);

  return (
    featureService.isVisible(Feature.challenge) ||
    featureService.isVisible(Feature.publicChallenge) ||
    featureService.isVisible(Feature.teamChallenge)
  );
};
