// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--fu-spacing-m);
  gap: var(--fu-spacing-m);
}
.confirmation__icon {
  width: 100%;
  height: 32px;
}
.confirmation__buttons {
  display: flex;
  gap: var(--fu-spacing-xs);
}
.confirmation__buttons > * {
  width: 100%;
}
.confirmation hr {
  width: 100%;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/confirmation-modal/confirmation-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,4BAAA;EACA,wBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EACE,aAAA;EACA,yBAAA;AAAJ;AAEI;EACE,WAAA;AAAN;AAIE;EACE,WAAA;EACA,SAAA;AAFJ","sourcesContent":[".confirmation {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  padding: var(--fu-spacing-m);\n  gap: var(--fu-spacing-m);\n\n  &__icon {\n    width: 100%;\n    height: 32px;\n  }\n\n  &__buttons {\n    display: flex;\n    gap: var(--fu-spacing-xs);\n\n    > * {\n      width: 100%;\n    }\n  }\n\n  hr {\n    width: 100%;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
