import { Inject, Injectable } from '@angular/core';
import { BrowserService } from '../browser/browser.service';
import { Platform } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class OAuth2Service {
  constructor(
    private readonly browserService: BrowserService,
    private readonly platform: Platform,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public startOAuth2(provider: 'google' | 'apple', entranceRoute = '/start-onboard'): void {
    let redirectUri: string;
    let newWindow: boolean;
    const entranceRouteParam = entranceRoute ? `?entranceRoute=${entranceRoute}` : '';
    if (this.platform.is('cordova')) {
      redirectUri = `${environment.urlScheme}://oauth2${entranceRouteParam}`;
      newWindow = true;
    } else {
      redirectUri = `${this.document.location.origin}/oauth2${entranceRouteParam}`;
      newWindow = false; // so no new tab is opened when app runs in a browser
    }

    const platforms = this.platform.platforms().join(',');
    const url = `${environment.apiHost}/oauth2/authorize/${provider}?platforms=${platforms}&redirect_uri=${encodeURI(
      redirectUri
    )}`;
    this.browserService.openUrl(url, newWindow);
  }
}
