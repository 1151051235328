import { createActionGroup, props } from '@ngrx/store';
import {ActionError} from "@fitup-monorepo/core/lib/model/common-types";

export const pinboardFilterActions = createActionGroup({
  source: 'Pinboard Filter',
  events: {
    ['Set Filter']: props<{ filter: string }>(),
    ['Set Filter Success']: props<{ filter: string }>(),
    ['Set Filter Failure']: props<ActionError>()
  }
});
