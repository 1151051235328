import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from '../header/header.component';
import { RouterModule } from '@angular/router';
import { HeaderTheme } from '@fitup-monorepo/core/lib/util/style.util';

@Component({
  selector: 'app-setup-container',
  templateUrl: './setup-container.component.html',
  styleUrls: ['./setup-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, TranslateModule, HeaderComponent, RouterModule],
  standalone: true
})
export class SetupContainerComponent {
  @Input()
  public showBackButton = true;

  @Input()
  public hasTransparentHeader = false;

  @Input()
  public skipUrl: string | any[] | undefined;

  @Input()
  public showSkipButton = false;

  @Input()
  public headerTheme: HeaderTheme = 'dark';

  @Output()
  public skipAction = new EventEmitter<void>();

  protected iconItems: { name: string; url: string }[] = [
    { name: 'facebook', url: 'https://www.facebook.com/fitup.online/' },
    { name: 'instagram', url: 'https://www.instagram.com/fitup.online/' },
    { name: 'linkedin', url: 'https://at.linkedin.com/company/fitup-online' },
    { name: 'tiktok', url: 'https://www.tiktok.com/@fitup.online' }
  ];

  @HostBinding('class')
  public get setupContainerClass(): string {
    return 'fu-setup-container';
  }
}
