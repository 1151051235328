import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule, NavController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { hasMembership, hasTrialPeriod, isMembershipExpired } from '@fitup-monorepo/core/lib/util/subscription.util';
import { SubscriptionPlanService } from '../../subscription-plan/subscription-plan.service';
import { CtaButtonComponent } from '@fitup-monorepo/components/lib/cta-button/cta-button.component';
import { SubscriptionBoxComponent } from '../subscription-box/subscription-box.component';
import { MembershipCardComponent } from '../membership-card/membership-card.component';
import {LocalizedDatePipe} from "@fitup-monorepo/core/lib/pipes/localized-date.pipe";
import {PromotionCode} from "@fitup-monorepo/core/lib/model/promotion-code";
import {CustomerMembership} from "@fitup-monorepo/core/lib/model/customer-membership";
import {Team} from "@fitup-monorepo/core/lib/model/team-customer";
import {selectHasSubscription} from "@fitup-monorepo/core/lib/state/profile/profile.selector";
import {CustomerSelectService} from "@fitup-monorepo/core/lib/services/customer-select/customer-select.service";
import {PlatformService} from "@fitup-monorepo/core/lib/services/platform/platform.service";

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    LocalizedDatePipe,
    TranslateModule,
    RouterModule,
    CtaButtonComponent,
    SubscriptionBoxComponent,
    MembershipCardComponent
  ]
})
export class SubscriptionComponent {
  @Input()
  public promotionCode: PromotionCode | undefined;

  @Input()
  public membership: CustomerMembership | undefined;

  @Input()
  public team: Team | undefined;

  @Input()
  public hasTeams = false;

  @Output()
  public subscriptionUpdate = new EventEmitter<void>();

  protected readonly logoUrl = '/assets/img/plans-logos/fitup.svg';
  protected readonly hasTrialPeriod = hasTrialPeriod;
  protected readonly hasMembership = hasMembership;
  protected readonly isMembershipExpired = isMembershipExpired;

  protected readonly hasExpiredTrial$: Observable<boolean> = this.customerSelectService.selectExpiredTrial$();
  protected readonly selectHasSubscription$ = this.store.select(selectHasSubscription);

  constructor(
    private readonly subscriptionPlanService: SubscriptionPlanService,
    private readonly customerSelectService: CustomerSelectService,
    protected readonly platformService: PlatformService,
    private readonly navCtrl: NavController,
    private readonly store: Store
  ) {}

  public isExpired(): boolean {
    return this.promotionCode?.validTo ? new Date(this.promotionCode.validTo) < new Date() : false;
  }

  public async onActivateSubscription(): Promise<void> {
    await this.subscriptionPlanService.startSubscription();
    this.subscriptionUpdate.emit();
  }

  protected async goOnboarding(): Promise<void> {
    await this.navCtrl.navigateRoot('/user-options-onboarding', {
      queryParams: { toOnboarding: true }
    });
  }
}
