import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { WaterIntake } from '../model/water-intake';
import { createReducer, on } from '@ngrx/store';
import { waterIntakeActions } from './water-intake.actions';

export interface WaterIntakeState {
  waterIntake?: WaterIntake;
  loading: boolean;
  error?: string;
}

export const waterIntakeAdapter: EntityAdapter<WaterIntake> = createEntityAdapter();

const initialState: WaterIntakeState = waterIntakeAdapter.getInitialState({
  loading: false,
});

export const waterIntakeReducer = createReducer(
  initialState,
  on(
    waterIntakeActions.loadWaterIntake,
    waterIntakeActions.addWaterIntake,
    (state): WaterIntakeState => ({ ...state, loading: true })
  ),
  on(
    waterIntakeActions.loadWaterIntakeSuccess,
    (state, { waterIntake }): WaterIntakeState => ({ ...state, waterIntake, loading: false })
  ),
  on(
    waterIntakeActions.addWaterIntakeSuccess,
    (state, { waterIntake }): WaterIntakeState => ({ ...state, waterIntake, loading: false })
  )
);
