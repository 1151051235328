// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --size: 161px;
}
:host.small .profile-picture {
  --size: 128px;
}

.profile-picture {
  height: var(--size);
  width: var(--size);
}
.profile-picture::part(image) {
  border-radius: 50%;
  object-fit: cover;
}
.profile-picture--default {
  border-radius: 50%;
  border: 4px solid var(--fu-body-bg);
  box-shadow: 0 10px 30px 0 rgba(209, 218, 227, 0.6);
}
.profile-picture.onboard {
  border: 5px solid var(--fu-green-2);
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/profile-picture/profile-picture.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEI;EACE,aAAA;AAAN;;AAKA;EACE,mBAAA;EACA,kBAAA;AAFF;AAIE;EACE,kBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,mCAAA;EACA,kDAAA;AAHJ;AAME;EACE,mCAAA;EACA,kBAAA;AAJJ","sourcesContent":[":host {\n  --size: 161px;\n\n  &.small {\n    .profile-picture {\n      --size: 128px;\n    }\n  }\n}\n\n.profile-picture {\n  height: var(--size);\n  width: var(--size);\n\n  &::part(image) {\n    border-radius: 50%;\n    object-fit: cover;\n  }\n\n  &--default {\n    border-radius: 50%;\n    border: 4px solid var(--fu-body-bg);\n    box-shadow: 0 10px 30px 0 rgba(209, 218, 227, 0.60);\n  }\n\n  &.onboard {\n    border: 5px solid var(--fu-green-2);\n    border-radius: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
