import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'ifDefault'})
export class IfDefaultPipe implements PipeTransform {
  transform(value: any, ...args): any {
    if (value === undefined || value === null || value === args[0]) {
      return args[1];
    }
    return value;
  }
}
