import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { IonicRatingComponent } from './ionic-rating-component';

@NgModule({
  declarations: [IonicRatingComponent],
  imports: [CommonModule, IonicModule],
  exports: [IonicRatingComponent],
})
export class IonicRatingComponentModule {}
