import { Injectable } from '@angular/core';
import { FirestoreService } from '@fitup-monorepo/core/lib/services/firestore/firestore.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FbMealType, FbRecipe } from './model/firestore-plan';

export enum Durations {
  short_duration = 'short_duration',
  medium_duration = 'medium_duration',
  long_duration = 'long_duration'
}

@Injectable({
  providedIn: 'root'
})
export class RecipeQueryService {
  constructor(public readonly firestoreService: FirestoreService) {}

  public getRecipes(): Observable<FbRecipe[]> {
    return this.firestoreService.observeCollection<FbRecipe>(`recipe`).pipe(
      map(recipes =>
        recipes.map(recipe => ({
          ...recipe,
          tags: [...recipe.tags, ...this.addRecipeTags(recipe)]
        }))
      )
    );
  }

  public getRecipeById(id: string): Observable<FbRecipe> {
    return this.firestoreService.observeDocument<FbRecipe>(`recipe/${id}`);
  }

  public getRecipesByCategory(mealType: FbMealType): Observable<FbRecipe[]> {
    return this.getRecipes().pipe(map(recipes => recipes.filter(recipe => recipe.mealTypes.includes(mealType))));
  }

  public getRecipesTags(): Observable<string[]> {
    return this.getRecipes().pipe(
      map(recipes => [...new Set(recipes.flatMap(recipe => recipe.tags))].sort((a, b) => a.localeCompare(b))),
      map(tags =>
        this.sortTagsByDuration(tags).filter(filter => !['lunch', 'dinner', 'snack', 'breakfast'].includes(filter))
      )
    );
  }

  private addRecipeTags(recipe: FbRecipe): string[] {
    const maxCal = 350;
    const minDuration = 10;
    const maxDuration = 20;

    const lowCal = recipe.caloriesAmount <= maxCal ? 'low_cal' : '';
    const duration =
      recipe.preparationTimeMinutes <= minDuration
        ? 'short_duration'
        : recipe.preparationTimeMinutes >= minDuration && recipe.preparationTimeMinutes <= maxDuration
        ? 'medium_duration'
        : recipe.preparationTimeMinutes >= maxDuration
        ? 'long_duration'
        : '';
    return [lowCal, duration].filter(tag => !!tag);
  }

  private sortTagsByDuration(tags: string[]): string[] {
    const priorityOrder = [
      Durations.short_duration as string,
      Durations.medium_duration as string,
      Durations.long_duration as string
    ];

    return tags.sort((a, b) => {
      const indexA = priorityOrder.indexOf(a);
      const indexB = priorityOrder.indexOf(b);

      if (indexA === -1 && indexB === -1) {
        return 0;
      }
      if (indexA === -1) {
        return 1;
      }
      if (indexB === -1) {
        return -1;
      }
      return indexA - indexB;
    });
  }
}
