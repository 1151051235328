import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { firstValueFrom, Observable } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { PlanMutationService } from '../plan-mutation.service';
import { errorHandlingWithToast, ErrorResult, isErrorResult } from '@fitup-monorepo/core/lib/util/rxjs-util';
import { isObjectOfType } from '@fitup-monorepo/core/lib/util/type-util';
import { ReferenceUnit } from '../model/custom-meal';
import { MealStatusRequest, MealSwapRequest } from '../model/plan-request';
import { InternalMealType, Meal, MealAlternative, MealStatus } from '../model/plan';

export type MealWithIndex = Meal & { mealIndex: number };

export type PreferredMealBase = Omit<MealAlternative, 'id' | 'preparationTimeMinutes'> & {
  type: InternalMealType;
};

export type PreferredMealPlan = PreferredMealBase & {
  type: InternalMealType.nutritionPlan;
  id: string;
  preparationTimeMinutes: number;
};

export type PreferredMealCustom = Omit<PreferredMealBase, 'title'> & {
  type: InternalMealType.custom;
  id: number;
  title: string;
  unitsAmount: number;
  unitId: string;
  numOfPortions?: number;
};

export type PreferredMealCustomAdded = Omit<PreferredMealBase, 'title'> & {
  type: InternalMealType.customAdded;
  title: string;
  servingSize: number;
  servingUnit: ReferenceUnit;
  portions: number;
  fats: number;
  proteins: number;
  carbs: number;
};

export type PreferredMeal = PreferredMealPlan | PreferredMealCustom | PreferredMealCustomAdded;

export interface MealSwapConfirmContent {
  currentMeal: MealWithIndex;
  preferredMeal: PreferredMeal;
  mealStatus?: MealStatusRequest;
}

@Component({
  selector: 'app-confirm-meal-swap',
  templateUrl: './confirm-meal-swap.page.html',
  styleUrls: ['./confirm-meal-swap.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmMealSwapPage {
  @Input()
  public meals: MealSwapConfirmContent | undefined;

  constructor(
    private readonly toastService: ToastService,
    private readonly planMutationService: PlanMutationService,
    private readonly modalController: ModalController
  ) {}

  public async onCancel(): Promise<void> {
    await this.modalController.dismiss(false);
  }

  public async confirmMealSwap(
    currentMeal: MealWithIndex,
    preferredMeal?: PreferredMeal,
    mealStatus?: MealStatusRequest
  ): Promise<void> {
    const result = await firstValueFrom(this.getSwapMealObservable(currentMeal, preferredMeal, mealStatus));
    if (!isErrorResult(result)) {
      await this.modalController.dismiss(true);
    }
  }

  private getPreferredMealPatch(preferredMeal: PreferredMeal): Partial<MealSwapRequest> {
    switch (preferredMeal.type) {
      case InternalMealType.custom:
        return {
          numOfPortions: preferredMeal.numOfPortions,
          body: {
            id: preferredMeal.id,
            unitsAmount: preferredMeal.unitsAmount,
            unitId: preferredMeal.unitId
          }
        };
      case InternalMealType.nutritionPlan:
        return { recipeId: preferredMeal.id };
      case InternalMealType.customAdded:
        return {
          numOfPortions: preferredMeal.portions,
          body: {
            unitsAmount: preferredMeal.portions,
            unitId: 'portion',
            customMealData: {
              image: preferredMeal.image,
              caloriesAmount: preferredMeal.caloriesAmount,
              fatsInGrams: preferredMeal.fats,
              carbsInGrams: preferredMeal.carbs,
              proteinInGrams: preferredMeal.proteins,
              servingSize: preferredMeal.servingSize,
              servingUnit: preferredMeal.servingUnit,
              title: preferredMeal.title
            }
          }
        };
    }
  }

  private getSwapMealObservable(
    currentMeal: MealWithIndex,
    preferredMeal?: PreferredMeal,
    mealStatus?: MealStatusRequest
  ): Observable<Meal | ErrorResult> {
    const preferredMealPatch: Partial<MealSwapRequest> = this.getPreferredMealPatch(preferredMeal);
    return this.planMutationService
      .swapMeal({
        planId: currentMeal.planId,
        mealType: currentMeal.mealType,
        week: currentMeal.numOfWeek,
        day: currentMeal.weekDay,
        mealIndex: currentMeal.mealIndex,
        status: mealStatus ?? MealStatus.open,
        ...preferredMealPatch
      })
      .pipe(errorHandlingWithToast(this.toastService, 'NUTRITION.MEAL_SWAP_CONFIRM.ERROR_MESSAGE'));
  }

  public getDuration(currentMeal: unknown): number | undefined {
    return isObjectOfType<Meal>(currentMeal, []) && currentMeal.type === InternalMealType.nutritionPlan
      ? currentMeal.preparationTimeMinutes
      : undefined;
  }
}
