import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AvatarModalService } from './avatar-modal.service';
import { BehaviorSubject } from 'rxjs';
import { Avatar } from './model/avatar';
import { HeaderComponent } from '../header/header.component';
import { ProfileImagePickerService } from './profile-image-picker.service';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';

@Component({
  selector: 'app-image-picker-modal',
  templateUrl: './image-picker-modal.component.html',
  styleUrls: ['./image-picker-modal.component.scss'],
  imports: [CommonModule, IonicModule, TranslateModule, HeaderComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePickerModalComponent implements OnInit {
  @Input()
  public useFrontCamera = false;

  @Input()
  public readonly hiddenTab: 'photo' | 'avatar';

  public readonly avatars$ = this.avatarModalService.getAvatars();

  public readonly tabName$ = new BehaviorSubject<string>('upload');

  constructor(
    public readonly avatarModalService: AvatarModalService,
    private readonly profileImagePickerService: ProfileImagePickerService,
    private readonly translateService: TranslateService,
    private readonly modalCtrl: ModalController,
    private readonly platform: Platform,
    private readonly toastService: ToastService
  ) {}

  public ngOnInit(): void {
    if (this.hiddenTab === 'photo') {
      this.tabName$.next('avatar');
    }
  }

  public setTab(tabName: string): void {
    this.tabName$.next(tabName);
  }

  public isTab(tabName: string): boolean {
    return tabName === this.tabName$.value;
  }

  public async choosePicture(): Promise<void> {
    try {
      const image: string = await this.profileImagePickerService.getPicture();
      await this.modalCtrl.dismiss(image, 'add_image');
    } catch (error) {
      await this.#handleError();
    }
  }

  public showPhotoOption(): boolean {
    return this.platform.is('cordova');
  }

  public async takePhoto(): Promise<void> {
    try {
      const image: string = await this.profileImagePickerService.getPhoto(this.useFrontCamera);
      await this.modalCtrl.dismiss(image, 'add_image');
    } catch (error) {
      await this.#handleError();
    }
  }

  public async removePicture(): Promise<void> {
    await this.modalCtrl.dismiss(undefined, 'delete_image');
  }

  public async chooseAvatar(avatar: Avatar): Promise<void> {
    await this.modalCtrl.dismiss(avatar, 'add_avatar');
  }

  async #handleError(): Promise<void> {
    await this.toastService.showError(this.translateService.instant('PROFILE_PICTURE.IMAGE_ERROR'));
  }

  protected async onClose(): Promise<void> {
    await this.modalCtrl.dismiss();
  }
}
