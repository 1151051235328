import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, TranslateModule],
})
export class InfoBannerComponent {
  @Input()
  public title: string | undefined;

  @Input()
  public info: string | undefined;

  @Input()
  public usePrimaryColorForIcon = false;

  @HostBinding('class')
  @Input()
  public align: 'center' | 'left' = 'center';
}
