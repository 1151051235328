import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedNumber',
  pure: false,
  standalone: true,
})
export class LocalizedNumberPipe implements PipeTransform, OnDestroy {
  private value: string | undefined;
  private langChangeSubscription: Subscription | undefined;

  constructor(private readonly translateService: TranslateService) {}

  public transform(value: number, digitsInfo?: string): string {
    this.setCurrentValue(value, digitsInfo);
    if (!this.langChangeSubscription) {
      this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
        this.setCurrentValue(value, digitsInfo);
      });
    }
    return this.value;
  }

  private setCurrentValue(value: number, digitsInfo?: string): void {
    this.value = formatNumber(value, this.translateService.currentLang ?? 'en', digitsInfo);
  }

  public ngOnDestroy(): void {
    this.langChangeSubscription?.unsubscribe();
  }
}
