import { Interest } from './interest';
import { AuthProvider } from './acount/account.model';
import { GoalType } from './goal-type';
import { Level } from './level';

export enum Gender {
  male = 'Male',
  female = 'Female',
  preferNotToSay = 'PreferNotToSay',
  diverse = 'Diverse'
}

export interface Customer {
  id: number;
  email: string;
  userId: number;
  uuid: string;
  freeTrialUsed: boolean;
  createdAt: string;
  firstName: string;
  lastName: string;
  nickname: string;
  promotionCode?: string;
  membership?: string;
  image?: string;
  birthDate?: string;
  weight?: number;
  height?: number;
  partnerId?: number;
  gender?: Gender;
  goals: GoalType[];
  fitnessLevel: Level;
  interests: Interest[];
  visibleOnPublicLeaderboard?: boolean;
  authProvider: AuthProvider;
  language: string;
}
