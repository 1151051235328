import { Membership } from './membership';

export enum SubscriptionStatus {
  inactive = 'INACTIVE',
  cancelled = 'CANCELLED',
  active = 'ACTIVE',
  pending = 'PENDING',
}

export enum PaymentProvider {
  payPal = 'PAYPAL',
  stripe = 'STRIPE',
  voucher = 'VOUCHER',
  apple = 'APPLE',
  android = 'ANDROID',
  revenueCatGoogle = 'REVENUECAT_GOOGLE',
  revenueCatApple = 'REVENUECAT_APPLE',
  trial = 'TRIAL',
}

export interface CustomerMembership {
  membership: Membership;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  status: SubscriptionStatus;
  trial: boolean;
  paymentProvider: PaymentProvider;
  manageSubscriptionUrl?: string;
  subscriptionId?: string;
  nextMembership?: Membership;
}
