import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { RecipeFilterState } from './recipe-filter.reducer';

export const selectRecipeFilterFeature = createFeatureSelector<RecipeFilterState>('recipeFilter');

export const selectRecipeSelectedFilters: MemoizedSelector<RecipeFilterState, string[]> = createSelector(
  selectRecipeFilterFeature,
  state => state.selectedFilters
);

export const selectAllRecipeFilters: MemoizedSelector<RecipeFilterState, string[]> = createSelector(
  selectRecipeFilterFeature,
  state => state.filters
);
