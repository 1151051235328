import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

export interface FooterInfo {
  icon: string;
  iconLabel?: string;
  showComingSoonBadge?: boolean;
  text: string;
}

@Component({
  selector: 'app-footer-info',
  templateUrl: './footer-info.component.html',
  styleUrls: ['./footer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, TranslateModule],
})
export class FooterInfoComponent {
  @Input()
  public title: string;

  @Input()
  public footerInfos: FooterInfo[];

  @Input()
  public bottomBorder = false;

  @Input()
  public iconColor = 'var(--fu-color-primary)';

  @Input()
  public textColor = 'var(--fu-body-color)';
}
