import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';

import { IonicModule } from '@ionic/angular';
import { PlanProgressComponent } from '../components/plan-progress/plan-progress.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-plan-progress-page',
  templateUrl: './plan-progress.page.html',
  styleUrls: ['./plan-progress.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderComponent, IonicModule, PlanProgressComponent, TranslateModule]
})
export class PlanProgressPage {}
