import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInEmailModule } from '@fitup-monorepo/onboarding/lib/sign-in/sign-in-email.module';
import { CookieModule, CookieService } from 'ngx-cookie';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { Health } from '@awesome-cordova-plugins/health/ngx';
import { AccountPageModule } from '@fitup-monorepo/account/lib/account.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '@fitup-monorepo/core/lib/environment';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { ScreenOrientation } from '@capawesome/capacitor-screen-orientation';
import { Camera } from '@capacitor/camera';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ErrorEffects } from './state/error/effects/error.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Haptics } from '@capacitor/haptics';
import { Share } from '@capacitor/share';
import { BadgeEffects } from '@fitup-monorepo/badges/lib/state/badge.effects';
import { BadgesPageEffects } from '@fitup-monorepo/badges/lib/badges.page.effects';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { PushNotifications } from '@capacitor/push-notifications';
import { NativeSettings } from 'capacitor-native-settings';
import { Device } from '@capacitor/device';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { Browser } from '@capacitor/browser';
import { NativeAudio } from '@capacitor-community/native-audio';
import { App } from '@capacitor/app';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { NotificationsEffects } from '@fitup-monorepo/core/lib/state/notifications/notifications.effects';
import { WaterIntakeEffects } from '@fitup-monorepo/home-dashboard/lib/components/water-tracking/state/water-intake.effects';
import { Filesystem } from '@capacitor/filesystem';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { StatusBar } from '@capacitor/status-bar';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { metaReducers, reducers } from './state';
import { ChallengeEffects } from '@fitup-monorepo/challenge/lib/state/challenge.effects';
import { SharedModule } from '@fitup-monorepo/components/lib/shared.module';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { FeatureDirective } from '@fitup-monorepo/core/lib/directives/feature.directive';
import { ProfileEffects } from '@fitup-monorepo/core/lib/state/profile/profile.effects';
import { AuthInterceptor } from '@fitup-monorepo/core/lib/interceptors/auth.interceptor';
import { AuthExpiredInterceptor } from '@fitup-monorepo/core/lib/interceptors/auth-expired.interceptor';
import { AppVersionInterceptor } from '@fitup-monorepo/core/lib/interceptors/app-version.interceptor';
import { TimeZoneInterceptor } from '@fitup-monorepo/core/lib/interceptors/time-zone.interceptor';
import { FirestoreService } from '@fitup-monorepo/core/lib/services/firestore/firestore.service';
import { TranslatableTextService } from '@fitup-monorepo/core/lib/services/translatable-text/translatable-text.service';
import { ChallengeDetailEffects } from '@fitup-monorepo/challenge/lib/pages/challenge-detail/challenge-detail.effects';
import { createTranslateLoader } from '@fitup-monorepo/core/lib/util/translate-loader.util';
import { FitupOnboardingWizardService } from './service/fitup-onboarding-wizard.service';
import { MiniChallengesEffects } from '@fitup-monorepo/home-dashboard/lib/components/mini-challenges/state/mini-challenges.effects';
import {
  APP,
  APP_UPDATE,
  BROWSER,
  CAMERA,
  DEVICE,
  FILE_PICKER,
  FILESYSTEM,
  HAPTICS,
  KEEP_AWAKE,
  NATIVE_AUDIO,
  NATIVE_SETTINGS,
  NAVIGATION_BAR,
  PURCHASES,
  PUSH_NOTIFICATIONS,
  SCREEN_ORIENTATION,
  SHARE,
  STATUS_BAR,
  WIZARD_SERVICE
} from '@fitup-monorepo/core/lib/capacitor-injection-tokens';
import { GroupEffects } from '@fitup-monorepo/challenge/lib/state/group/group.effects';
import { GroupDetailEffects } from '@fitup-monorepo/challenge/lib/pages/group-detail/group-detail.effects';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    FeatureDirective,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot(),
    NgxWebstorageModule.forRoot({
      prefix: 'jhi',
      separator: '-'
    }),
    AppRoutingModule,
    SignInEmailModule,
    AccountPageModule,
    CookieModule.withOptions(),
    FormsModule,
    NgCircleProgressModule.forRoot(),
    ServiceWorkerModule.register('firebase-messaging-sw.js', {
      enabled: environment.production
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireMessagingModule,
    HeaderComponent,
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    EffectsModule.forRoot([
      ProfileEffects,
      ChallengeEffects,
      ErrorEffects,
      ChallengeDetailEffects,
      BadgeEffects,
      BadgesPageEffects,
      MiniChallengesEffects,
      NotificationsEffects,
      WaterIntakeEffects,
      GroupEffects,
      GroupDetailEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true
    }),
    StoreRouterConnectingModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    CookieService,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    {
      provide: FIRESTORE_SETTINGS,
      useValue: {
        experimentalAutoDetectLongPolling: true
      }
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeZoneInterceptor,
      multi: true
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: SCREEN_ORIENTATION,
      useValue: ScreenOrientation
    },
    {
      provide: FILE_PICKER,
      useValue: FilePicker
    },
    {
      provide: CAMERA,
      useValue: Camera
    },
    {
      provide: HAPTICS,
      useValue: Haptics
    },
    {
      provide: SHARE,
      useValue: Share
    },
    {
      provide: WIZARD_SERVICE,
      useClass: FitupOnboardingWizardService
    },
    {
      provide: APP_UPDATE,
      useValue: AppUpdate
    },
    {
      provide: APP,
      useValue: App
    },
    {
      provide: PUSH_NOTIFICATIONS,
      useValue: PushNotifications
    },
    {
      provide: NATIVE_SETTINGS,
      useValue: NativeSettings
    },
    {
      provide: DEVICE,
      useValue: Device
    },
    {
      provide: KEEP_AWAKE,
      useValue: KeepAwake
    },
    {
      provide: STATUS_BAR,
      useValue: StatusBar
    },
    {
      provide: NAVIGATION_BAR,
      useValue: NavigationBar
    },
    {
      provide: PURCHASES,
      useValue: Purchases
    },
    {
      provide: BROWSER,
      useValue: Browser
    },
    {
      provide: NATIVE_AUDIO,
      useValue: NativeAudio
    },
    {
      provide: FILESYSTEM,
      useValue: Filesystem
    },
    FirestoreService,
    TranslatableTextService,
    Health
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
