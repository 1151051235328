import { createReducer, on } from '@ngrx/store';
import { profileApiActions } from './profile.actions';
import { PromotionCode } from '../../model/promotion-code';
import { Customer } from '../../model/customer';
import { TeamCustomer } from '../../model/team-customer';
import { CustomerMembership } from '../../model/customer-membership';

export interface ProfileState {
  customer?: Customer;
  promotionCode?: PromotionCode;
  teamCustomer?: TeamCustomer;
  currentMembership?: CustomerMembership;
  isEligibleForFreeTrial?: boolean;
}

export const initialState: ProfileState = {};

export const profileReducer = createReducer(
  initialState,
  on(profileApiActions.loadProfileSuccess, (state, { profile }): ProfileState => ({ ...state, ...profile })),
  on(
    profileApiActions.uploadImageSuccess,
    (state, { image }): ProfileState => ({
      ...state,
      customer: { ...state.customer, image }
    })
  ),
  on(
    profileApiActions.deleteImageSuccess,
    (state): ProfileState => ({
      ...state,
      customer: { ...state.customer, image: undefined }
    })
  ),
  on(profileApiActions.resetProfile, (): ProfileState => initialState),
  on(
    profileApiActions.updateMembership,
    (state, { membership }): ProfileState => ({
      ...state,
      currentMembership: membership
    })
  )
);
