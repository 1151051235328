import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Challenge, CommunityChallengeOverview, PartnerChallengeOverview } from '../model';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';

export const challengeActions = createActionGroup({
  source: 'Challenge',
  events: {
    ['Load Challenge']: props<{ challengeId: number }>(),
    ['Load Challenge Success']: props<{ challenge: Challenge }>(),
    ['Load Challenge Failure']: props<ActionError>(),
    ['Load Community Overview']: emptyProps(),
    ['Load Community Overview Success']: props<{
      overview: CommunityChallengeOverview;
    }>(),
    ['Load Community Overview Failure']: props<ActionError>(),
    ['Load Partner Overview']: emptyProps(),
    ['Load Partner Overview Success']: props<{
      overview: PartnerChallengeOverview;
    }>(),
    ['Load Partner Overview Failure']: props<ActionError>(),
    ['Join Challenge']: props<{ challengeId: number }>(),
    ['Join Challenge Success']: props<{ challenge: Challenge }>(),
    ['Join Challenge Failure']: props<ActionError>(),
    ['Add Invited Challenge Id']: props<{ challengeId: number }>(),
    ['Mark Completed Challenges Shown']: props<{ challengeIds: number[] }>(),
    ['Mark Completed Challenges Shown Success']: props<{
      challenges: Challenge[];
    }>(),
    ['Mark Completed Challenges Shown Failure']: props<ActionError>(),
    ['Leave Challenge']: props<{ challengeId: number }>(),
    ['Leave Challenge Success']: props<{ challenge: Challenge }>(),
    ['Leave Challenge Failure']: props<ActionError>()
  }
});
