import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  HostBinding,
  Directive,
  ContentChildren,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export interface FooterDescription {
  icon: string;
  label: string;
  labelColor?: string;
}

export type CardSize = 'small' | 'large';

@Directive({
  selector: '[appCardFooter]',
  standalone: true,
})
export class CardFooterDirective implements FooterDescription {
  @Input()
  public icon: string | undefined;

  @Input()
  public label: string | undefined;

  @Input()
  public labelColor: string | undefined;
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, CommonModule, TranslateModule],
  standalone: true,
})
export class CardComponent {
  @Input()
  public imgUrl: string | undefined;

  @Input()
  public showBookmark = false;

  @Input()
  public showEditButton = false;

  @Input()
  public label: string | undefined;

  @Input()
  public cardSize: CardSize = 'small';

  @Input()
  public labelColor: string | undefined = 'var(--fu-color-primary)';

  @Input()
  public title: string | undefined;

  @Input()
  public centerTitle = false;

  @Input()
  public centerHeader = false;

  @Input()
  public description: string | undefined;

  @Input()
  public buttonLabel: string | undefined;

  @Input()
  public footerDescription: FooterDescription[] = [];

  @Input()
  public isActive = false;

  @Input()
  public showActiveLabel = true;

  @Input()
  public activeLabel: string | undefined;

  @Input()
  public centerActiveLabel = false;

  @Input()
  public isInactive = false;

  @Input()
  public isLoading = false;

  @Input()
  public isSelected = false;

  @Input()
  public selectedLabel: string | undefined;

  @Input()
  public set isBookmarked(isBookmarked: boolean) {
    this.bookmark$.next(isBookmarked);
  }

  @Input()
  public showPremiumBadge = false;

  @Output()
  public updateBookmark = new EventEmitter<boolean>();

  @Output()
  public buttonClick = new EventEmitter<void>();

  @Output()
  public editClick = new EventEmitter<void>();

  @ContentChildren(CardFooterDirective)
  public footerItems: CardFooterDirective[] | undefined;

  public bookmark$ = new BehaviorSubject<boolean>(false);

  public onEditClick(event: MouseEvent): void {
    event.stopPropagation();
    this.editClick.emit();
  }

  public toggleBookmark(event: MouseEvent, showBookmark: boolean): void {
    event.stopPropagation();
    const updatedValue = !showBookmark;
    this.bookmark$.next(updatedValue);
    this.updateBookmark.emit(updatedValue);
  }

  @HostBinding('class.active') public get active(): boolean {
    return this.isActive;
  }

  @HostBinding('class') public get size(): string {
    return this.cardSize;
  }

  public getFooterItems(): FooterDescription[] {
    return this.footerItems.length ? this.footerItems : this.footerDescription;
  }
}
