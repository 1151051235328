import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import type { PurchasesPlugin } from '@revenuecat/purchases-capacitor/dist/esm/definitions';
import { CustomerInfo, PACKAGE_TYPE, PurchasesOffering } from '@revenuecat/purchases-capacitor';
import { PURCHASES } from '../../capacitor-injection-tokens';
import { CustomerSelectService } from '../customer-select/customer-select.service';
import { environment } from '../../environment';
import {
  MembershipPackage,
  MembershipPlan,
  PlanType,
  PurchaseProductWithDefaultOption
} from '../../model/purchase/purchase';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  constructor(
    private readonly platform: Platform,
    @Inject(PURCHASES) private readonly purchases: PurchasesPlugin,
    private readonly customerSelectService: CustomerSelectService
  ) {}

  public async init(): Promise<void> {
    await this.platform.ready();
    if (this.platform.is('capacitor')) {
      const customer = await this.customerSelectService.selectCustomer();
      const setupConfig = {
        apiKey: this.getApiKey(),
        appUserID: customer.id.toString()
      };
      await this.purchases.configure(setupConfig);
    }
  }

  private getApiKey(): string | null {
    if (this.platform.is('ios')) {
      return environment.revenueCat.apple;
    }
    if (this.platform.is('android')) {
      return environment.revenueCat.google;
    }
    return null;
  }

  private toPlanType(packageType: string): PlanType {
    switch (packageType) {
      case PACKAGE_TYPE.ANNUAL:
        return PlanType.annually;
      case PACKAGE_TYPE.MONTHLY:
        return PlanType.monthly;
      case PACKAGE_TYPE.THREE_MONTH:
        return PlanType.quarterly;
      default:
        return undefined;
    }
  }

  private async getCurrentOffering(): Promise<PurchasesOffering> {
    const offerings = await this.purchases.getOfferings();
    return offerings.current;
  }

  public async getCurrentMembershipPackage(): Promise<MembershipPackage> {
    if (this.platform.is('capacitor')) {
      const currentOffering = await this.getCurrentOffering();
      console.log('currentOffering', currentOffering);
      console.log('currentOffering', JSON.stringify(currentOffering));
      return {
        id: currentOffering.identifier,
        name: currentOffering.serverDescription,
        plans: currentOffering.availablePackages.map(p => ({
          id: p.product.identifier,
          name: p.product.title,
          description: p.product.description,
          planType: this.toPlanType(p.packageType),
          packageType: p.packageType,
          product: p.product,
          offeringIdentifier: currentOffering.identifier,
          packageIdentifier: p.identifier,
          price: p.product.price,
          promotionOfferPrice: p.product.discounts
            ? undefined // right now we can't apply discounts
            : this.getPriceOfNextPricingPhase(p.product as any),
          introductoryPrice: p.product.introPrice?.price,
          currencyCode: p.product.currencyCode
        }))
      };
    } else {
      return {
        id: 'fitness-nutrition',
        name: 'Fitness & Nutrition',
        plans: [
          {
            id: 'fitup_nutrition_annually:fitup-nutrition-annually',
            name: 'Fitness & Nutrition Annually',
            planType: PlanType.annually,
            packageType: PACKAGE_TYPE.ANNUAL,
            product: null,
            packageIdentifier: 'fitness_nutrition_annually',
            offeringIdentifier: 'fitness-nutrition',
            price: 119.99,
            introductoryPrice: 0,
            promotionOfferPrice: 59.99,
            currencyCode: 'EUR'
          },
          {
            id: 'fitup_nutrition:fitup-nutrition-monthly',
            name: 'Fitness & Nutrition Monthly',
            planType: PlanType.quarterly,
            packageType: PACKAGE_TYPE.THREE_MONTH,
            product: null,
            packageIdentifier: 'fitness_nutrition_monthly',
            offeringIdentifier: 'fitness-nutrition',
            price: 59.99,
            introductoryPrice: 0,
            promotionOfferPrice: 24.99,
            currencyCode: 'EUR'
          }
        ]
      };
    }
  }

  private getPriceOfNextPricingPhase(product: PurchaseProductWithDefaultOption): number | undefined {
    const microFactor = 1000000;
    const defaultPriceMicros = product.price * microFactor;
    const priceInAmountMicros = product.defaultOption?.pricingPhases.find(
      phase => phase.price.amountMicros > 0 && phase.price.amountMicros !== defaultPriceMicros
    )?.price.amountMicros;
    return priceInAmountMicros ? priceInAmountMicros / microFactor : undefined;
  }

  /**
   * Purchase a membership plan
   *
   * @param plan plan to purchase
   * @param oldSKU if the user is upgrading/downgrading, the old SKU
   * (not sure what SKU is meant here: the plan's packageIdentifier or the product's identifier)
   *
   * Will throw an error if there's already an active subscription:
   *   Error: This product is already active for the user.
   */
  public async purchase(plan: MembershipPlan, oldSKU?: string): Promise<CustomerInfo | null> {
    if (this.platform.is('capacitor')) {
      console.log('purchasing', JSON.stringify(plan));

      try {
        const { customerInfo } = await this.purchases.purchasePackage({
          aPackage: {
            identifier: plan.packageIdentifier,
            packageType: plan.packageType,
            product: null,
            offeringIdentifier: plan.offeringIdentifier,
            presentedOfferingContext: null
          }
        });
        console.log('purchase result', JSON.stringify(customerInfo));
        return customerInfo;
      } catch (error) {
        throw error;
      }
    } else {
      return {
        entitlements: {
          active: {
            fitness: {
              isActive: true
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            fitness_nutrition: {
              isActive: true
            }
          }
        }
      } as any as CustomerInfo;
    }
  }

  public async getInfo(): Promise<CustomerInfo> {
    if (this.platform.is('capacitor')) {
      return (await this.purchases.getCustomerInfo()).customerInfo;
    } else {
      return undefined;
    }
  }

  public async restorePurchases(): Promise<CustomerInfo> {
    return (await this.purchases.restorePurchases()).customerInfo;
  }
}
