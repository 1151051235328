import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export interface ToastConfig {
  message: string;
  primaryButtonLabel: string;
  dismissButtonLabel?: string;
  primaryAction: () => void | Promise<void>;
  canDismiss?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private readonly toastController: ToastController,
    private readonly translateService: TranslateService
  ) {}

  public async showError(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message: this.translateService.instant(message),
      duration: 5000,
      position: 'middle',
      cssClass: ['fu-toast', 'fu-toast--error'],
    });
    await toast.present();
  }

  public async showSuccess(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message: this.translateService.instant(message),
      duration: 3000,
      position: 'top',
      cssClass: ['fu-toast', 'fu-toast--success'],
    });
    await toast.present();
  }

  public async showToast(config: ToastConfig): Promise<void> {
    const toast = await this.toastController.create({
      message: this.translateService.instant(config.message),
      position: 'top',
      cssClass: ['fu-action-toast'],
      buttons: [
        ...(config.canDismiss
          ? [{ text: this.translateService.instant(config.dismissButtonLabel ?? 'NOT_NOW'), role: 'dismiss' }]
          : []),
        {
          text: this.translateService.instant(config.primaryButtonLabel),
          role: 'primary',
          handler: () => config.primaryAction(),
        },
      ],
    });
    await toast.present();
  }
}
