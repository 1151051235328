import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubscriptionSuccessComponent } from '../subscription-success/subscription-success.component';
import { dismissModalIfExistent } from '../subscription.utils';
import { MembershipPlan } from '@fitup-monorepo/core/lib/model/purchase/purchase';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { SubscriptionFreeTrialPageModule } from './subscription-free-trial.module';

@Component({
  selector: 'app-subscription-free-trial-modal',
  templateUrl: './subscription-free-trial-modal.component.html',
  styleUrls: ['./subscription-free-trial-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderComponent, SubscriptionFreeTrialPageModule]
})
export class SubscriptionFreeTrialModalComponent {
  @Input()
  public plan: MembershipPlan | undefined;

  private static readonly modalId = 'subscription-trial';
  constructor(private readonly modalController: ModalController) {}

  public static async openModal(modalController: ModalController, plan?: MembershipPlan): Promise<void> {
    const modal = await modalController.create({
      component: SubscriptionFreeTrialModalComponent,
      id: SubscriptionFreeTrialModalComponent.modalId,
      cssClass: 'fu-subscription-modal',
      componentProps: { plan }
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  public static closeModal(modalController: ModalController): void {
    dismissModalIfExistent(modalController, SubscriptionFreeTrialModalComponent.modalId);
  }

  public onClose(): void {
    SubscriptionFreeTrialModalComponent.closeModal(this.modalController);
  }

  public async onSuccess(): Promise<void> {
    await SubscriptionSuccessComponent.openModal(this.modalController);
  }
}
