import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { badgeActions } from './badge.actions';
import { EarnedBadge } from '@fitup-monorepo/core/lib/model/badge';

export interface BadgeState extends EntityState<EarnedBadge> {
  loading: boolean;
}

export const badgeAdapter: EntityAdapter<EarnedBadge> = createEntityAdapter();

const initialState: BadgeState = badgeAdapter.getInitialState({
  loading: false
});

export const badgeReducer = createReducer(
  initialState,
  on(badgeActions.loadEarnedBadgesSuccess, (state, { badges }) => badgeAdapter.setMany(badges, state))
);
