import { Component, DestroyRef, inject, OnDestroy } from '@angular/core';
import { LiveClassesService } from '@fitup-monorepo/core/lib/services/live-classes/live-classes.service';
import { NavController } from '@ionic/angular';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { TeamSelectionService } from '@fitup-monorepo/core/lib/services/team-selection/team-selection.service';
import { Store } from '@ngrx/store';
import { Permission, PermissionsService } from '@fitup-monorepo/core/lib/services/permissions/permissions.service';
import { CustomerSelectService } from '@fitup-monorepo/core/lib/services/customer-select/customer-select.service';
import { selectOwnProfile } from './account.page.selectors';
import { errorHandlingAndMapToUndefined } from '@fitup-monorepo/core/lib/util/rxjs-util';
import { Feature } from '@fitup-monorepo/core/lib/services/features/feature.service';
import { Session, SessionWithRating } from '@fitup-monorepo/core/lib/entities/session.model';
import { Customer } from '@fitup-monorepo/core/lib/model/customer';
import { PromotionCode } from '@fitup-monorepo/core/lib/model/promotion-code';
import { CustomerMembership } from '@fitup-monorepo/core/lib/model/customer-membership';
import { TeamCustomer } from '@fitup-monorepo/core/lib/model/team-customer';
import { selectHasSubscription, selectSubscriptionInfo } from '@fitup-monorepo/core/lib/state/profile/profile.selector';
import { StartGuideService } from '@fitup-monorepo/start-guide/lib/start-guide.service';
import { StartGuideOverview } from '@fitup-monorepo/start-guide/lib/model/start-guide';
import { isAppFitup } from '@fitup-monorepo/core/lib/util/app-checker.util';

export interface AccountPageState {
  customer: Customer;
  futureSessions: SessionWithRating[];
}

export interface SubscriptionState {
  promotionCode?: PromotionCode;
  hasTeams: boolean;
  currentMembership?: CustomerMembership;
  teamCustomer?: TeamCustomer;
}

@Component({
  selector: 'app-account',
  templateUrl: 'account.page.html',
  styleUrls: ['account.page.scss']
})
export class AccountPage implements OnDestroy {
  protected readonly subscriptionState$: Observable<SubscriptionState> = this.store.select(selectSubscriptionInfo).pipe(
    filter(() => this.isInView),
    switchMap(info =>
      this.teamSelectionService.getSelectableTeams().pipe(map(teams => ({ ...info, hasTeams: !!teams.length })))
    )
  );
  protected readonly ownProfile$ = this.store.select(selectOwnProfile);

  // this is horrible...we need to create a single endpoint for this
  public readonly state$: Observable<AccountPageState> = this.customerSelectService.selectCustomer$().pipe(
    filter(() => this.isInView),
    tap(customer => {
      this.customer = customer;
    }),
    switchMap(customer =>
      this.liveClassesService.getFutureSessionsByCustomerId(customer.id).pipe(
        map(r => ({ customer, futureSessions: r.body })),
        catchError(() => of({ customer, futureSessions: [] }))
      )
    ),
    switchMap(state => {
      const defaultState = {
        ...state,
        futureSessions: state.futureSessions.map(s => ({
          ...s,
          averageRating: 0,
          ratingAmount: 0
        }))
      };
      return state.futureSessions.length
        ? this.liveClassesService.getAverageRatingForLiveSession(state.futureSessions.map(s => s.id)).pipe(
            map(r => ({
              ...state,
              futureSessions: state.futureSessions.map((session: Session) => ({
                ...session,
                averageRating: r.body[session.product.id][0],
                ratingAmount: r.body[session.product.id][1]
              }))
            })),
            catchError(error => {
              console.error('Live class average rating error', JSON.stringify(error));
              return of(defaultState);
            })
          )
        : of(defaultState);
    })
  );

  protected readonly startGuideOverview$: Observable<StartGuideOverview> = this.startGuideService
    .getStartGuideOverview()
    .pipe(errorHandlingAndMapToUndefined());

  protected readonly selectHasSubscription$: Observable<boolean> = this.store.select(selectHasSubscription);

  public currentTime: number | undefined;
  public userSubscription: Subscription;
  public mostRecentSessionsIds: number[];

  public customer: Customer;
  public customerSubscription: Subscription;
  public isTrainer = false;

  private readonly destroyRef = inject(DestroyRef);
  private isInView = true;
  protected readonly feature = Feature;

  protected readonly isAppFitup = isAppFitup();

  constructor(
    private readonly liveClassesService: LiveClassesService,
    public readonly navController: NavController,
    private readonly teamSelectionService: TeamSelectionService,
    private readonly store: Store,
    private readonly permissionsService: PermissionsService,
    private readonly customerSelectService: CustomerSelectService,
    private readonly startGuideService: StartGuideService
  ) {}

  public ionViewWillLeave(): void {
    this.isInView = false;
  }

  public ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }

  public ionViewWillEnter(): void {
    this.isTrainer = this.permissionsService.hasPermission(Permission.trainer);
    this.isInView = true;
  }
}
