import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { Observable } from 'rxjs';
import { MiniChallenge } from './model/mini-challenge';

@Injectable({ providedIn: 'root' })
export class MiniChallengesService {
  constructor(private readonly httpClient: HttpClient) {}

  public getMiniChallenges(): Observable<MiniChallenge[]> {
    return this.httpClient.get<MiniChallenge[]>(`${ApiService.API_URL}/mini-challenges`);
  }

  public joinMiniChallenge(miniChallengeId: number): Observable<MiniChallenge> {
    return this.httpClient.post<MiniChallenge>(`${ApiService.API_URL}/mini-challenges/${miniChallengeId}/join`, null);
  }

  public cancelMiniChallenge(miniChallengeId: number): Observable<MiniChallenge> {
    return this.httpClient.post<MiniChallenge>(`${ApiService.API_URL}/mini-challenges/${miniChallengeId}/cancel`, null);
  }
}
