import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getErrorMessage } from './error-util';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ToastService } from '../services/toast/toast.service';

export interface ErrorResult {
  error: string;
}

/**
 * Helper for typing in template
 */
export function asType<T>(data: unknown): T {
  return data as unknown as T;
}

export function isErrorResult(data: unknown): data is ErrorResult {
  return data !== null && typeof data === 'object' && 'error' in data;
}

export function defaultErrorHandling(): <T>(source: Observable<T>) => Observable<T | ErrorResult> {
  return <T>(source: Observable<T>): Observable<T | ErrorResult> =>
    source.pipe(
      errorLogging(),
      catchError(error => of({ error: getErrorMessage(error) }))
    );
}

export function errorHandlingAndMapToUndefined(): <T>(source: Observable<T>) => Observable<T | undefined> {
  return <T>(source: Observable<T>): Observable<T | undefined> =>
    source.pipe(
      defaultErrorHandling(),
      map(result => (isErrorResult(result) ? undefined : result))
    );
}

export interface ErrorHandlingConfig<T> {
  observable$: Observable<T>;
}

export type ErrorHandlingWithToastConfig<T> = ErrorHandlingConfig<T> & {
  toastService: ToastService;
  errorMessage?: string;
  badRequestErrorMessage?: string;
  notFoundErrorMessage?: string;
};

export function executeWithErrorHandling<T>(config: ErrorHandlingConfig<T>): Promise<T | ErrorResult> {
  return firstValueFrom(config.observable$.pipe(defaultErrorHandling()));
}

export function executeWithToastErrorHandling<T>(config: ErrorHandlingWithToastConfig<T>): Promise<T | ErrorResult> {
  return firstValueFrom(
    config.observable$.pipe(
      errorHandlingWithToast(
        config.toastService,
        config.errorMessage,
        config.badRequestErrorMessage,
        config.notFoundErrorMessage
      )
    )
  );
}

export function errorHandlingWithToast(
  toastService: ToastService,
  errorMessage?: string,
  badRequestErrorMessage?: string,
  notFoundErrorMessage?: string
): <T>(source: Observable<T>) => Observable<T | ErrorResult> {
  return <T>(source: Observable<T>): Observable<T | ErrorResult> =>
    source.pipe(
      errorLogging(),
      catchError(error => {
        toastService.showError(
          getErrorString({
            error,
            errorMessage,
            badRequestErrorMessage,
            notFoundErrorMessage
          })
        );
        return of({ error: getErrorMessage(error) });
      })
    );
}

export interface ErrorStringConfig {
  error: unknown;
  errorMessage?: string;
  badRequestErrorMessage?: string;
  notFoundErrorMessage?: string;
}

export function getErrorString(config: ErrorStringConfig): string {
  const defaultError = config.errorMessage ?? 'GENERIC_ERROR';
  if (!(config.error instanceof HttpErrorResponse)) {
    return defaultError;
  }
  if (config.error.status === HttpStatusCode.BadRequest) {
    return config.badRequestErrorMessage ?? defaultError;
  }
  if (config.error.status === HttpStatusCode.NotFound) {
    return config.notFoundErrorMessage ?? defaultError;
  }
  return defaultError;
}

export function errorHandlingWithToastMapToUndefined(
  toastService: ToastService,
  errorMessage?: string
): <T>(source: Observable<T>) => Observable<T | undefined> {
  return <T>(source: Observable<T>): Observable<T | undefined> =>
    source.pipe(
      errorHandlingWithToast(toastService, errorMessage),
      map(result => (isErrorResult(result) ? undefined : result))
    );
}

export function errorLogging(): <T>(source: Observable<T>) => Observable<T> {
  return <T>(source: Observable<T>): Observable<T> =>
    source.pipe(
      catchError(error => {
        console.error(error);
        return throwError(() => error);
      })
    );
}
