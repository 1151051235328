import { Component, ContentChildren, Directive, Input, TemplateRef, ViewChild } from '@angular/core';
import { SwiperComponent, SwiperSlideDirective } from '../swiper/swiper.component';
import Swiper from 'swiper/types/swiper-class';
import { CommonModule } from '@angular/common';

@Directive({
  selector: '[appCardSlide]',
  standalone: true,
})
export class CardSlideDirective {
  @Input()
  public slideClasses: string[] | { [className: string]: boolean } | string = ['fu-swiper__slide'];

  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'app-card-swiper',
  templateUrl: './card-swiper.component.html',
  styleUrls: ['./card-swiper.component.scss'],
  imports: [CommonModule, SwiperComponent, SwiperSlideDirective],
  standalone: true,
})
export class CardSwiperComponent {
  @Input()
  public spaceBetween = 0;

  @Input()
  public initialSlide = 0;

  @Input()
  public speed = 400;

  @Input()
  public freeMode = false;

  @Input()
  public slidesPerView: number | 'auto' = 'auto';

  @Input()
  public direction = 'horizontal';

  @Input()
  public slideClass = 'fu-swiper__slide';

  @Input()
  public scrollbar = false;

  @Input()
  public centeredSlides = false;

  @Input()
  public centeredSlidesBounds = true;

  @Input()
  public pagination = false;

  @Input()
  public slideWidth: number | undefined;

  @ViewChild(SwiperComponent)
  public swiper: SwiperComponent | undefined;

  @ContentChildren(CardSlideDirective)
  public slides: CardSlideDirective[];

  public get swiperContainer(): Swiper | undefined {
    return this.swiper?.swiperContainer;
  }
}
