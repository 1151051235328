import { CustomerMembership, PaymentProvider, SubscriptionStatus } from '../model/customer-membership';

export function hasVoucher(membership: CustomerMembership): boolean {
  return membership?.paymentProvider === PaymentProvider.voucher;
}

export function hasMembership(membership: CustomerMembership): boolean {
  return (
    (membership?.status === SubscriptionStatus.active && membership && !membership.trial) || hasVoucher(membership)
  );
}

export function isMembershipExpired(membership: CustomerMembership): boolean {
  return membership.status === SubscriptionStatus.cancelled && !hasVoucher(membership);
}

export function hasTrialPeriod(membership: CustomerMembership): boolean {
  return membership?.status === SubscriptionStatus.active && membership?.trial;
}
