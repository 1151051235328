// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description {
  padding: var(--fu-spacing-m);
  color: var(--fu-grey-3);
  background-color: var(--fu-grey-9);
  margin: var(--fu-spacing-xl) 0 60px 0;
  border-radius: var(--fu-spacing-s);
}
.description__highlighted {
  color: var(--fu-body-color);
}`, "",{"version":3,"sources":["webpack://./libs/subscription/src/lib/subscription-plan/components/info-label/info-label.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,uBAAA;EACA,kCAAA;EACA,qCAAA;EACA,kCAAA;AACF;AACE;EACE,2BAAA;AACJ","sourcesContent":[".description {\n  padding: var(--fu-spacing-m);\n  color: var(--fu-grey-3);\n  background-color: var(--fu-grey-9);\n  margin: var(--fu-spacing-xl) 0 60px 0;\n  border-radius: var(--fu-spacing-s);\n\n  &__highlighted {\n    color: var(--fu-body-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
