import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Session } from '../../entities/session.model';
import { ApiService } from '../api/api.service';
import { createRequestOption } from '../../util/request-util';
import { SessionRating } from '@fitup-monorepo/components/lib/live-class-card-item/model/session-rating';

@Injectable({ providedIn: 'root' })
export class LiveClassesService {
  constructor(protected http: HttpClient) {}

  public bookSession(sessionId: number): Observable<HttpResponse<Session>> {
    return this.http.put<Session>(`${ApiService.API_URL}/sessions/${sessionId}/bookings`, null, {
      observe: 'response'
    });
  }

  public cancelSessionBooking(sessionId: number): Observable<HttpResponse<Session>> {
    return this.http.delete<Session>(`${ApiService.API_URL}/sessions/${sessionId}/bookings`, { observe: 'response' });
  }

  public getSessionsByCustomerId(id: number): Observable<HttpResponse<Session[]>> {
    return this.http.get<Session[]>(`${ApiService.API_URL}/sessions/${id}/customer`, { observe: 'response' });
  }

  public getUpcomingGroupSessions(req?: any): Observable<HttpResponse<Session[]>> {
    const options = createRequestOption(req);
    return this.http.get<Session[]>(`${ApiService.API_URL}/sessions/group/withCustomerCount`, {
      params: options,
      observe: 'response'
    });
  }

  public getFutureSessionsByCustomerId(id: number): Observable<HttpResponse<Session[]>> {
    return this.http.get<Session[]>(`${ApiService.API_URL}/sessions/${id}/customer/futureSessions`, {
      observe: 'response'
    });
  }

  public getAverageRatingForLiveSession(sessionIds: number[]): Observable<HttpResponse<SessionRating>> {
    return this.http.get<SessionRating>(`${ApiService.API_URL}/sessions/${sessionIds}/getAverageRatingForSession`, {
      observe: 'response'
    });
  }

  public getSessionsForSessionIds(sessionIds: number[]): Observable<HttpResponse<Session[]>> {
    return this.http.get<Session[]>(`${ApiService.API_URL}/sessions/${sessionIds}/bySessionIds`, {
      observe: 'response'
    });
  }
}
