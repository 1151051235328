import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { EarnedBadge } from '@fitup-monorepo/core/lib/model/badge';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {
  constructor(private readonly httpClient: HttpClient) {}

  public getEarnedBadges(): Observable<EarnedBadge[]> {
    return this.httpClient.get<EarnedBadge[]>(`${ApiService.API_URL}/badges/earned`);
  }
}
