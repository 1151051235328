import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { NumberInputComponent } from '@fitup-monorepo/components/lib/number-input/number-input.component';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MealType } from '../model/plan';
import { SearchedCustomMeal, CustomMealAction } from '../model/custom-meal';
import { LocalizedNumberPipe } from '@fitup-monorepo/core/lib/pipes/localized-number.pipe';
import { isObjectOfType } from '@fitup-monorepo/core/lib/util/type-util';
import { MealSearchConfig } from '../components/meal-search/meal-search.component';
import { MealItemComponent } from '../components/meal-item/meal-item.component';
import { CustomMealButtonsComponent } from '../components/custom-meal-buttons/custom-meal-buttons.component';
import { customMealImage } from '../custom-meal-image';

export interface AddMealState {
  meal: SearchedCustomMeal;
  actions: CustomMealAction[];
  config: MealSearchConfig;
}

@Component({
  selector: 'app-add-custom-meal',
  templateUrl: './add-custom-meal.page.html',
  styleUrls: ['./add-custom-meal.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    HeaderComponent,
    NumberInputComponent,
    ReactiveFormsModule,
    TranslateModule,
    LocalizedNumberPipe,
    MealItemComponent,
    CustomMealButtonsComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddCustomMealPage {
  private basePortionAmount = 1;

  public addFoodForm = new FormBuilder().group({
    portions: new FormControl(this.basePortionAmount),
    mealType: MealType.breakfast
  });
  public readonly state$: Observable<AddMealState> = this.activatedRoute.queryParams.pipe(
    map(() => {
      const state = this.router.getCurrentNavigation()?.extras?.state;
      if (!this.isMealState(state)) {
        this.router.navigate(['/tabs/nutrition-planner']);
        return undefined;
      }
      return state;
    }),
    filter(state => !!state),
    tap(state => {
      this.basePortionAmount = state.meal.portionsAmount;
      this.referenceAmount = state.meal.referenceAmount;
      this.defaultUnitAmount = state.meal.defaultUnit.amount;
      this.addFoodForm.patchValue({ portions: state.meal.portionsAmount });
    })
  );

  public readonly mealTypes = MealType;
  public readonly action = CustomMealAction;

  private referenceAmount: number | undefined;
  private defaultUnitAmount: number | undefined;

  public readonly defaultImage = customMealImage;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  private isMealState(data: unknown): data is AddMealState {
    return isObjectOfType<AddMealState>(data, ['actions', 'meal', 'config']);
  }

  public calculatePerPortion(grams: number): number {
    return (grams / this.referenceAmount) * this.defaultUnitAmount * this.addFoodForm.value.portions;
  }

  public containsSwap(actions: CustomMealAction[]): boolean {
    return actions.includes(CustomMealAction.swap);
  }
}
