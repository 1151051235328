import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { getDaysLeftUntil, getHoursLeftUntil, getMinutesLeftUntil, getRoundedDaysLeftUntil } from '../util/date.util';

export type TimeLeftType = 'time-until-start' | 'time-until-end';
export interface TranslationConfig {
  amount: number;
  singularTimeUntil: string;
  pluralTimeUntil: string;
  singularStart: string;
  pluralStart: string;
  timeLeftType: TimeLeftType;
  translateService: TranslateService;
}

export function getTimeLeft(
  value: Date | string,
  timeLeftType: TimeLeftType,
  translateService: TranslateService
): string {
  const daysLeftUntilEnd = getDaysLeftUntil(value);
  if (daysLeftUntilEnd > 0) {
    return pluralOrSingular({
      amount: getRoundedDaysLeftUntil(value),
      pluralTimeUntil: 'DAYS_LEFT',
      singularTimeUntil: 'DAY_LEFT',
      pluralStart: 'STARTS_IN_DAYS',
      singularStart: 'STARTS_IN_DAY',
      translateService,
      timeLeftType
    });
  }
  const hoursLeftUntilEnd = getHoursLeftUntil(value);
  if (hoursLeftUntilEnd > 0) {
    return pluralOrSingular({
      amount: hoursLeftUntilEnd,
      pluralTimeUntil: 'HOURS_LEFT',
      singularTimeUntil: 'HOUR_LEFT',
      pluralStart: 'STARTS_IN_HOURS',
      singularStart: 'STARTS_IN_HOUR',
      translateService,
      timeLeftType
    });
  }
  return pluralOrSingular({
    amount: getMinutesLeftUntil(value),
    pluralTimeUntil: 'MINUTES_LEFT',
    singularTimeUntil: 'MINUTE_LEFT',
    pluralStart: 'STARTS_IN_MINUTES',
    singularStart: 'STARTS_IN_MINUTE',
    translateService,
    timeLeftType
  });
}

function pluralOrSingular(config: TranslationConfig): string {
  const singularTranslation =
    config.timeLeftType === 'time-until-end' ? config.singularTimeUntil : config.singularStart;
  const pluralTranslation = config.timeLeftType === 'time-until-end' ? config.pluralTimeUntil : config.pluralStart;
  const translationKey = config.amount === 1 ? singularTranslation : pluralTranslation;
  return config.translateService.instant(translationKey, { amount: config.amount });
}

@Pipe({
  name: 'timeLeft',
  pure: false,
  standalone: true
})
export class TimeLeftPipe implements PipeTransform, OnDestroy {
  #timeLeftType: TimeLeftType = 'time-until-end';
  #value: string | undefined;
  #langChangeSubscription: Subscription | undefined;

  constructor(private readonly translateService: TranslateService) {}
  public transform(value: Date | string, timeLeftType?: TimeLeftType): string {
    if (timeLeftType) {
      this.#timeLeftType = timeLeftType;
    }
    this.setCurrentValue(value);
    if (!this.#langChangeSubscription) {
      this.#langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
        this.setCurrentValue(value);
      });
    }
    return this.#value;
  }

  private setCurrentValue(value: Date | string): void {
    this.#value = getTimeLeft(value, this.#timeLeftType, this.translateService);
  }

  public ngOnDestroy(): void {
    this.#langChangeSubscription?.unsubscribe();
  }
}
