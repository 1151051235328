import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../environment';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  private static platform = environment.production ? 'undefined' : 'dev';
  private static version = environment.production ? 'undefined' : 'dev';

  public static setPlatformAndVersion(platform: string[], version: string): void {
    this.platform = platform.join(',');
    this.version = version;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        'x-fitup-platform': AppVersionInterceptor.platform,
        'x-fitup-app-version': AppVersionInterceptor.version,
        'x-fitup-app-id': environment.urlScheme
      }
    });

    return next.handle(req);
  }
}
