import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { trialDays } from '../subscription.utils';
import { BehaviorSubject } from 'rxjs';
import { PlatformService } from '@fitup-monorepo/core/lib/services/platform/platform.service';
import { ListItem } from '../components/list/list.component';

@Component({
  selector: 'app-subscription-free-trial',
  templateUrl: './subscription-free-trial.component.html',
  styleUrls: ['./subscription-free-trial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionFreeTrialComponent implements OnInit {
  @Output()
  public startSubscriptionSuccess = new EventEmitter<void>();

  public readonly infos: ListItem[] = [
    {
      title: 'SUBSCRIPTION_FREE_TRIAL.INFO_1',
      text: 'SUBSCRIPTION_FREE_TRIAL.LABEL_1',
      icon: 'check-circle-solid',
      state: 'done'
    },
    {
      title: 'SUBSCRIPTION_FREE_TRIAL.INFO_2',
      text: 'SUBSCRIPTION_FREE_TRIAL.LABEL_2',
      icon: 'gift-solid',
      state: 'active'
    },
    {
      title: 'SUBSCRIPTION_FREE_TRIAL.INFO_3',
      text: 'SUBSCRIPTION_FREE_TRIAL.LABEL_3',
      icon: 'clock-stopwatch-solid',
      state: 'upcoming'
    }
  ];
  public readonly buttonVisibility$ = new BehaviorSubject<boolean>(false);
  protected readonly trialDays = trialDays;

  constructor(private readonly platformService: PlatformService) {}

  public ngOnInit(): void {
    // note: we need this otherwise this annoying button doesn't show up initially on ios
    setTimeout(() => {
      this.buttonVisibility$.next(true);
    }, 100);
  }

  public isNative(): boolean {
    return this.platformService.isNative();
  }
  public async onSubscriptionSuccess(): Promise<void> {
    this.startSubscriptionSuccess.emit();
  }
}
