import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BadgeState } from './badge.reducer';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { sortByCreatedAt } from '@fitup-monorepo/core/lib/util/array-util';
import { EarnedBadge } from '@fitup-monorepo/core/lib/model/badge';

export const selectBadgeFeature = createFeatureSelector<BadgeState>('badge');

export const selectAllBadges: MemoizedSelector<BadgeState, EarnedBadge[]> = createSelector(selectBadgeFeature, state =>
  sortByCreatedAt(Object.values(state.entities), 'desc', 'earnedAt')
);

export const selectEarnedBadgesPreview: MemoizedSelector<BadgeState, EarnedBadge[]> = createSelector(
  selectAllBadges,
  badges => badges.slice(0, 10)
);
