import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoginService } from '@fitup-monorepo/core/lib/services/login/login.service';
import { NavController } from '@ionic/angular';
import * as moment from 'moment';
import { CustomerSelectService } from '@fitup-monorepo/core/lib/services/customer-select/customer-select.service';
import {Customer} from "@fitup-monorepo/core/lib/model/customer";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'oauth2-resolve',
  template: ''
})
export class Oauth2ResolveComponent implements OnInit {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private navController: NavController,
    private readonly customerSelectService: CustomerSelectService
  ) {}

  private async doLogin(params: Params): Promise<void> {
    try {
      const token = params.token;
      const entranceRoute = params.entranceRoute;
      if (token) {
        await this.loginService.loginWithToken(token);

        const customer = await this.customerSelectService.selectCustomer();
        await this.router.navigate(
          !this.#hasLoggedInBefore(customer) && entranceRoute ? [entranceRoute] : ['tabs/home']
        );
      }
    } catch (e) {
      this.navigateWelcomePage();
      console.log('promise rejected with ' + e);
    }
  }

  public ngOnInit(): void {
    this.route.queryParams.subscribe(async params => {
      await this.doLogin(params);
    });
  }

  public navigateWelcomePage(): void {
    this.navController.navigateBack('', { queryParams: { token: 'error' } });
  }

  // workaround as long as we don't get info from the backend
  #hasLoggedInBefore(customer: Customer): boolean {
    return Math.abs(moment(customer.createdAt).diff(moment(), 'minute')) > 1;
  }
}
