import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SubscriptionPlanModalComponent } from '../subscription-plan-modal.component';
import { dismissModalIfExistent } from '../subscription.utils';
import { StartSubscriptionModalComponent } from '../start-subscription/start-subscription-modal.component';
import { SubscriptionFreeTrialModalComponent } from '../subscription-free-trial/subscription-free-trial-modal.component';

@Component({
  selector: 'app-subscription-success',
  templateUrl: './subscription-success.component.html',
  styleUrls: ['./subscription-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, TranslateModule, RouterModule],
})
export class SubscriptionSuccessComponent {
  private static readonly modalId = 'subscription-success';
  constructor(private readonly modalController: ModalController) {}

  public static async openModal(
    modalController: ModalController,
  ): Promise<void> {
    const modal = await modalController.create({
      component: SubscriptionSuccessComponent,
      cssClass: 'fu-subscription-modal',
      id: SubscriptionSuccessComponent.modalId,
    });
    await modal.present();
  }

  public static closeModal(modalController: ModalController): void {
    dismissModalIfExistent(
      modalController,
      SubscriptionSuccessComponent.modalId,
    );
  }

  public async onButtonClick(): Promise<void> {
    SubscriptionPlanModalComponent.closeModal(this.modalController);
    SubscriptionSuccessComponent.closeModal(this.modalController);
    StartSubscriptionModalComponent.closeModal(this.modalController);
    SubscriptionFreeTrialModalComponent.closeModal(this.modalController);
  }
}
