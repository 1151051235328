import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { isAuthenticated } from './auth.guard';
import { SplashScreenService } from '../services/splash-screen/splash-screen.service';
import { AuthServerProvider } from '../services/auth/auth-jwt.service';

export const loggedOutOnlyGuard: CanActivateFn = async () => {
  const splashScreenService = inject(SplashScreenService);
  const router = inject(Router);
  const authServerProvider = inject(AuthServerProvider);

  try {
    let result = (await isAuthenticated(authServerProvider)) ? router.createUrlTree(['']) : true;
    await splashScreenService.hide();
    return result;
  } catch (e) {
    console.error(e);
    return false;
  }
};
