import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PointsPage } from './points.page';
import { FitPointEarningSummary } from '@fitup-monorepo/core/lib/model/fit-point-earning-summary';

@Injectable({
  providedIn: 'root'
})
export class PointsService {
  constructor(private readonly modalController: ModalController) {}

  public async showFitPointModal(fitPointEarningSummary: FitPointEarningSummary, buttonTitle?: string): Promise<void> {
    if (fitPointEarningSummary && fitPointEarningSummary.earnedPoints > 0) {
      const modal = await this.modalController.create({
        component: PointsPage,
        componentProps: {
          totalPoints: fitPointEarningSummary.currentMonthTotalPoints,
          currentPoints: fitPointEarningSummary.earnedPoints,
          buttonTitle
        },
        cssClass: ['fu-modal-default']
      });
      await modal.present();
      await modal.onWillDismiss();
    }
  }
}
