// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background: var(--fu-black);
}`, "",{"version":3,"sources":["webpack://./libs/subscription/src/lib/subscription-plan/start-subscription/start-subscription-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF","sourcesContent":[":host {\n  background: var(--fu-black);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
