import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { Avatar } from '../image-picker-modal/model/avatar';
import { Store } from '@ngrx/store';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { InfoBannerComponent } from '../info-banner/info-banner.component';
import { BannerComponent, BannerTheme } from '../banner/banner.component';
import { ImagePickerDirective } from '../image-picker-modal/image-picker.directive';
import { LocalizedDatePipe } from '@fitup-monorepo/core/lib/pipes/localized-date.pipe';
import { LocalizedNumberPipe } from '@fitup-monorepo/core/lib/pipes/localized-number.pipe';
import { TranslatableTextPipe } from '@fitup-monorepo/core/lib/pipes/translatable-text.pipe';
import { DurationPipe } from '@fitup-monorepo/core/lib/pipes/duration-pipe';
import { FeatureDirective } from '@fitup-monorepo/core/lib/directives/feature.directive';
import { PublicProfile, UserMembership } from '@fitup-monorepo/core/lib/model/public-profile';
import { PrivacyLevel } from '@fitup-monorepo/core/lib/model/privacy-level';
import { Feature } from '@fitup-monorepo/core/lib/services/features/feature.service';
import { profileApiActions } from '@fitup-monorepo/core/lib/state/profile/profile.actions';
import { isNil } from '@fitup-monorepo/core/lib/util/type-util';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    ProfilePictureComponent,
    ImagePickerDirective,
    TranslateModule,
    RouterModule,
    LocalizedDatePipe,
    LocalizedNumberPipe,
    TranslatableTextPipe,
    DurationPipe,
    InfoBannerComponent,
    BannerComponent,
    FeatureDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent {
  @Input({ required: true })
  public profile!: PublicProfile;

  @Input()
  public isOwnProfile = false;

  protected readonly currentDate = new Date();
  protected readonly bannerTheme = BannerTheme;
  protected readonly privacyLevels = PrivacyLevel;
  protected readonly feature = Feature;

  constructor(private readonly store: Store) {}

  protected getColorVariable(membership: UserMembership): string {
    const colorVariable =
      membership === UserMembership.corporate ? '--fu-color-premium-corporate' : '--fu-color-premium-private';
    return `var(${colorVariable})`;
  }

  public async onUpdateImageUrl(image: string): Promise<void> {
    this.updateImageUrl(image);
  }

  public updateImageUrl(image: string): void {
    this.store.dispatch(profileApiActions.uploadImage({ image }));
  }

  public async onImageDelete(): Promise<void> {
    this.store.dispatch(profileApiActions.deleteImage());
  }

  public async onAvatarChange(avatar: Avatar): Promise<void> {
    this.store.dispatch(profileApiActions.setAvatar({ avatarId: avatar.id }));
  }

  protected hasStats(profile: PublicProfile): boolean {
    return (
      !isNil(profile.currentMonthActiveDurationSeconds) &&
      !isNil(profile.currentMonthFitPoints) &&
      !isNil(profile.numOfBadges)
    );
  }
}
