import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { groupActions } from './group.actions';
import { of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GroupService } from '../../services/group.service';
import { NavController } from '@ionic/angular';

@Injectable()
export class GroupEffects {
  public readonly loadGroupOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupActions.loadGroupOverview),
      switchMap(() =>
        this.groupService.getGroupOverview().pipe(
          map(groupOverview => groupActions.loadGroupOverviewSuccess({ groupOverview })),
          catchError(error => of(groupActions.loadGroupOverviewFailure({ error })))
        )
      )
    )
  );

  public readonly joinGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupActions.joinGroup),
      switchMap(({ invitationCode }) =>
        this.groupService.joinGroup(invitationCode).pipe(
          map(group => groupActions.joinGroupSuccess({ group })),
          catchError(error => of(groupActions.joinGroupFailure({ error })))
        )
      )
    )
  );

  public readonly leaveGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupActions.leaveGroup),
      switchMap(({ groupId }) =>
        this.groupService.leaveGroup(groupId).pipe(
          map(group => groupActions.leaveGroupSuccess({ group })),
          catchError(error => of(groupActions.leaveGroupFailure({ error })))
        )
      )
    )
  );

  public readonly createGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupActions.createGroup),
      switchMap(({ request }) =>
        this.groupService.createGroup(request).pipe(
          map(group => groupActions.createGroupSuccess({ group })),
          tap(() => {
            this.navController.navigateBack('tabs/challenges');
          }),
          catchError(error => of(groupActions.createGroupFailure({ error })))
        )
      )
    )
  );

  public readonly updateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupActions.updateGroup),
      switchMap(({ groupId, request }) =>
        this.groupService.updateGroup(groupId, request).pipe(
          map(group => groupActions.updateGroupSuccess({ group })),
          tap(() => {
            this.navController.navigateBack('tabs/challenges');
          }),
          catchError(error => of(groupActions.updateGroupFailure({ error })))
        )
      )
    )
  );

  public readonly deleteGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupActions.deleteGroup),
      switchMap(({ groupId }) =>
        this.groupService.deleteGroup(groupId).pipe(
          map(() => groupActions.deleteGroupSuccess()),
          tap(() => {
            this.navController.navigateBack('tabs/challenges');
          }),
          catchError(error => of(groupActions.deleteGroupFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly groupService: GroupService,
    private readonly navController: NavController
  ) {}
}
