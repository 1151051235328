export interface Texts {
  title: string;
  description: string;
}

export function getTexts(completedTasksAmount: number, allTasksAmount: number): Texts {
  if (completedTasksAmount === allTasksAmount) {
    return {
      title: 'START_GUIDE.COMPLETED.TITLE',
      description: 'START_GUIDE.COMPLETED.DESCRIPTION',
    };
  }
  if (completedTasksAmount) {
    return {
      title: 'START_GUIDE.PROGRESS.TITLE',
      description: 'START_GUIDE.PROGRESS.DESCRIPTION',
    };
  }
  return {
    title: 'START_GUIDE.NOT_STARTED.TITLE',
    description: 'START_GUIDE.NOT_STARTED.DESCRIPTION',
  };
}
