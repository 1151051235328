import { Meal } from '../model/plan';
import { Router } from '@angular/router';
import { FbRecipe } from '../model/firestore-plan';
import { FooterDescription } from '@fitup-monorepo/components/lib/card/card.component';
import { FooterInfo } from '@fitup-monorepo/components/lib/footer-info/footer-info.component';

export async function navigateToDetail(
  router: Router,
  mealIndex: number | string,
  meal: Meal,
  onlyReview?: boolean
): Promise<void> {
  await router.navigate([`/meal-detail`], {
    queryParams: {
      numOfWeek: meal?.numOfWeek,
      weekDay: meal?.weekDay,
      planId: meal?.planId,
      mealIndex,
      onlyReview
    }
  });
}

export function recipeCardFooter(recipe: FbRecipe): FooterDescription[] {
  return [
    {
      icon: 'svg/fill-icons/clock-white-solid.svg',
      label: recipe.preparationTimeMinutes + ' min'
    },
    {
      icon: 'svg/fill-icons/restaurant-white.svg',
      label: recipe.caloriesAmount + ' kcal'
    }
  ];
}

export const footerInfoRecipe: FooterInfo[] = [
  {
    icon: 'svg/check-done-solid.svg',
    text: 'NUTRITION.EXPLORE.INFO.FIRST'
  },
  {
    icon: 'svg/bell-ringing-solid.svg',
    text: 'NUTRITION.EXPLORE.INFO.SECOND',
    showComingSoonBadge: true
  },
  {
    icon: 'svg/bowl-rice-solid.svg',
    text: 'NUTRITION.EXPLORE.INFO.THIRD'
  },
  {
    icon: 'svg/shopping-bag-solid.svg',
    text: 'NUTRITION.EXPLORE.INFO.FOURTH',
    showComingSoonBadge: true
  }
];
