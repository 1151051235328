// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { initializeEnvironment } from '@fitup-monorepo/core/lib/environment';

//export const apiHost = 'https://api.fit-up.com';
export const apiHost = 'https://dev.fit-up.com';
// export const apiHost = 'https://dev.fit-up.com';
export const fireBasePrefix = 'dev-';
export const init = () => {
  console.log('init dev environment');
  initializeEnvironment({
    production: false,
    forceNativePlatform: false, //true: see all stuff like on a mobile phone, false: see stuff like it should look on desktop
    apiHost,
    apiUrl: apiHost + '/api',
    managementApiUrl: apiHost,
    appUrl: 'https://app.dev.fit-up.com',
    bugfender: {
      appKey: undefined
    },
    revenueCat: {
      google: 'goog_RbmuyCraUGVbCDTnTQoRvJIisGc',
      apple: 'appl_VdqPsoJkKlLXKcikxfmDQesHqrh'
    },
    googleAnalytics: {
      trackerId: 'UA-154130837-1'
    },
    firebase: {
      databaseURL: 'https://catapult-268006.firebaseio.com',
      prefix: fireBasePrefix,
      apiKey: 'AIzaSyCtgzvGo3Ro_j5rfkH6TjxYsDy-irLnfTg',
      authDomain: 'catapult-268006.firebaseapp.com',
      projectId: 'catapult-268006',
      storageBucket: 'catapult-268006.appspot.com',
      messagingSenderId: '614321696120',
      appId: '1:614321696120:web:580c87af788c0009f06c8d',
      vapidKey: 'BCACQuZuH7IP9UM0ya7wmkdTcLCdEPigc3RYvhaLp0efniSLQjEsxLiyE1-J1dFFdwNVkmhTtAHdLx_Q0lbtWuo'
    },
    tinymce: {
      apiKey: 'fafjc1ivko5xzaoe5voi7v2kfgb84ebpxu66tmx2cm0w5gnq'
    },
    isCustomApp: false,
    urlScheme: 'fitup',
    appName: 'fitup',
    preferredThemes: {
      welcomePage: 'fu-theme fu-theme--dark',
      registerPage: 'fu-theme fu-theme--light-settings'
    }
  });
};
