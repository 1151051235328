import { createActionGroup, emptyProps } from '@ngrx/store';

export const challengesPageActions = createActionGroup({
  source: 'Challenges Page',
  events: {
    ['Load Community Overview']: emptyProps(),
    ['Load Partner Overview']: emptyProps(),
    ['Load Group Overview']: emptyProps()
  }
});
