import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

export enum BannerTheme {
  grey = 'grey',
  primary = 'primary',
  orange = 'orange',
}

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, IonicModule],
  standalone: true,
})
export class BannerComponent {
  @Input()
  public imageSrc: string | undefined;

  @Input()
  public iconName: string | undefined;

  @Input()
  public bannerTitle: string | undefined;

  @Input()
  public titleColor: string | undefined;

  @Input()
  public description: string | undefined;

  @Input()
  public theme: BannerTheme = BannerTheme.primary;

  @HostBinding('class')
  public get className(): string {
    return this.theme;
  }

  @HostBinding('class.has-image')
  private get hasImage(): boolean {
    return !!this.imageSrc;
  }

  @HostBinding('class.has-icon')
  private get hasIcon(): boolean {
    return !!this.iconName;
  }
}
