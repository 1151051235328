import {
  IngredientUnit,
  InternalMealType,
  MealStatus,
  MealType,
  PlanCategory,
  PlanGender,
  PlanStatus,
  Target,
  WeeklyPlanOverview,
  WeeklyPlanStatus
} from './plan';
import { FirestoreEntity } from '@fitup-monorepo/core/lib/services/firestore/firestore.model';
import { TranslatableString } from '@fitup-monorepo/core/lib/services/translatable-text/translatable-string';
import { WeekDay } from '@fitup-monorepo/core/lib/model/weekday';

export interface MealPlanResource {
  names: TranslatableString;
  description: TranslatableString;
  url: string;
}

export interface FbPlanTemplate extends FirestoreEntity {
  id: string;
  targets: { [key in PlanGender]: Target };
  category: PlanCategory[];
  titles: TranslatableString;
  descriptions: TranslatableString;
  resources?: MealPlanResource[];
  completionResources?: MealPlanResource[];
  image: string;
  recipeAmount: number;
  fatsPercentage: number;
  carbsPercentage: number;
  proteinPercentage: number;
  mealSuggestionsAmount: number;
  durationInWeeks: number;
  weeks: WeeklyPlanOverview[];
  isFree: boolean;
}

export interface FbMealBase {
  type: InternalMealType;
  mealType: MealType;
  consumedAt: string;
  status: MealStatus;
  titles: TranslatableString;
  caloriesAmount: number;
  carbsInGramsAmount: number;
  proteinInGramsAmount: number;
  fatInGramsAmount: number;
  numOfPortionsConsumed?: number;
  image?: string;
}

export type FbNutritionMeal = FbMealBase & {
  type: InternalMealType.nutritionPlan;
  recipeId: string;
  preparationTimeMinutes: number;
  tags: string[];
};

export type FbCustomMeal = FbMealBase & {
  type: InternalMealType.custom;
  customMealId: number;
  portionUnitName: string;
  portionAmount: number;
  verified?: boolean;
};

export type FbMeal = FbNutritionMeal | FbCustomMeal;

export interface FbPlanDay {
  day: WeekDay;
  date: string;
  caloriesIntakeAmount: number;
  caloriesBurntAmount: number;
  caloriesLeftAmount: number;
  carbsInGramsIntakeAmount: number;
  proteinInGramsIntakeAmount: number;
  fatInGramsIntakeAmount: number;
  meals: FbMeal[];
}

export interface FbPlanWeek {
  title: string;
  description: string;
  image: string;
  numOfWeek: number;
  days: FbPlanDay[];
  status: WeeklyPlanStatus;
  caloriesBurnedAmount: number;
  caloriesIntakeAmount: number;
  completedDaysAmount: number;
}

export interface FbCustomerPlan extends Omit<FbPlanTemplate, 'weeks' | 'targets'> {
  tags: string[];
  target: Target;
  sourceTemplateId: string;
  status: PlanStatus;
  weeks: FbPlanWeek[];
  startedAt: string;
  updatedAt: string;
  skippedMealsAmount: number;
  trackedMealsAmount: number;
  progressPercentage: number;
  weeksCompletedAmount: number;
  caloriesBurntAmount: number;
  caloriesIntakeAmount: number;
}

export interface FbIngredient {
  names: TranslatableString;
  amount: number;
  unit: IngredientUnit;
}

export interface FbRecipe extends FirestoreEntity {
  id: string;
  titles: TranslatableString;
  image: string;
  categories: string[];
  caloriesAmount: number;
  preparationTimeMinutes: number;
  proteinInGramsAmount: number;
  fiberInGramsAmount: number;
  sugarInGramsAmount: number;
  fatInGramsAmount: number;
  carbsInGramsAmount: number;
  portionAmount: number;
  ingredients: FbIngredient[];
  preparationInstructions: TranslatableString[];
  tags: string[];
  allergens: string[];
  mealTypes: FbMealType[];
}

export enum FbMealType {
  breakfast = 'BREAKFAST',
  lunch = 'LUNCH',
  dinner = 'DINNER',
  snack = 'SNACK'
}

export type GroupedRecipeResponse = {
  [key in FbMealType]?: {
    recipes: FbRecipe[];
  };
};
