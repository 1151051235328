import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TeamMemberListComponent } from '@fitup-monorepo/components/lib/team-member-list/team-member-list.component';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-team-member-list-modal',
  templateUrl: './team-member-list-modal.component.html',
  styleUrls: ['./team-member-list-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TeamMemberListComponent, HeaderComponent, IonicModule, TranslateModule],
  standalone: true
})
export class TeamMemberListModalComponent {
  @Input({ required: true })
  public teamId!: number;

  @Input()
  public customerUuid: string;

  constructor(private readonly modalController: ModalController) {}

  public static async openModal(modalController: ModalController, teamId: number, customerUuid: string): Promise<void> {
    const modal = await modalController.create({
      component: TeamMemberListModalComponent,
      componentProps: { teamId, customerUuid },
      cssClass: ['fu-modal-default']
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  protected async closeModal(): Promise<void> {
    await this.modalController.dismiss();
  }
}
