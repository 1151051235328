import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomMealButtonsComponent } from '../components/custom-meal-buttons/custom-meal-buttons.component';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InputElementComponent } from '@fitup-monorepo/components/lib/input-element/input-element.component';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NumberInputComponent } from '@fitup-monorepo/components/lib/number-input/number-input.component';

import { TranslateModule } from '@ngx-translate/core';
import { MealSearchConfig } from '../components/meal-search/meal-search.component';
import { isObjectOfType } from '@fitup-monorepo/core/lib/util/type-util';
import { AddMealState } from '../add-custom-meal/add-custom-meal.page';
import { ImagePickerDirective } from '@fitup-monorepo/components/lib/image-picker-modal/image-picker.directive';
import { CustomAddedMeal, CustomMealAction, ReferenceUnit } from '../model/custom-meal';
import { MealType } from '../model/plan';

export const customMealImage =
  'https://res.cloudinary.com/catapult/image/upload/v1684157545/fitup/nutrition/00_-_No_Meal_Picture_ewzqge.jpg';

export interface CreateMealState {
  actions: CustomMealAction[];
  config: MealSearchConfig;
}

@Component({
  selector: 'app-create-custom-meal',
  templateUrl: './create-custom-meal.page.html',
  styleUrls: ['./create-custom-meal.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    HeaderComponent,
    ImagePickerDirective,
    CustomMealButtonsComponent,
    InputElementComponent,
    ReactiveFormsModule,
    NumberInputComponent,
    TranslateModule
  ]
})
export class CreateCustomMealPage {
  public readonly defaultImage = customMealImage;
  public readonly mealTypes = MealType;
  public readonly referenceUnit = ReferenceUnit;

  private baseAmount = 0;

  public createMealForm = new FormBuilder().group({
    image: null,
    mealName: '',
    servingSize: 0,
    servingUnit: ReferenceUnit.grams,
    portion: new FormControl(1),
    mealType: MealType.breakfast,
    calories: new FormControl(this.baseAmount),
    carbs: new FormControl(this.baseAmount),
    proteins: new FormControl(this.baseAmount),
    fats: new FormControl(this.baseAmount)
  });

  public readonly state$: Observable<CreateMealState> = this.activatedRoute.queryParams.pipe(
    map(() => {
      const state = this.router.getCurrentNavigation()?.extras?.state;
      if (!this.isMealState(state)) {
        return undefined;
      }
      return state;
    }),
    filter(state => !!state)
  );

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  private isMealState(data: unknown): data is AddMealState {
    return isObjectOfType<AddMealState>(data, ['actions', 'config']);
  }
  public async onUpdateImageUrl(image: string): Promise<void> {
    this.createMealForm.patchValue({ image });
  }

  public async onImageDelete(): Promise<void> {
    this.createMealForm.patchValue({ image: null });
  }

  public containsSwap(actions: CustomMealAction[]): boolean {
    return actions.includes(CustomMealAction.swap);
  }

  public getMeal(): CustomAddedMeal {
    const value = this.createMealForm.value;
    return {
      defaultUnit: undefined, //FIXME
      defaultUnitCaloriesAmount: 0, //FIXME
      id: 0, //FIXME
      title: value.mealName,
      image: value.image as string,
      servingSize: value.servingSize,
      servingUnit: value.servingUnit,
      portionsAmount: value.portion,
      proteinsInGrams: value.proteins,
      carbsInGrams: value.carbs,
      fatsInGrams: value.fats,
      caloriesAmount: value.calories
    };
  }
}
