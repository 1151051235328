import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { ProfileComponent } from '../profile/profile.component';
import { UserProfileService } from './user-profile.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { PublicProfile } from '@fitup-monorepo/core/lib/model/public-profile';
import { errorHandlingAndMapToUndefined } from '@fitup-monorepo/core/lib/util/rxjs-util';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile-modal.component.html',
  styleUrls: ['./user-profile-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, HeaderComponent, ProfileComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileModalComponent {
  @Input({ required: true })
  public set uuid(uuid: string) {
    this.uuid$.next(uuid);
  }

  private readonly uuid$ = new BehaviorSubject<string>(undefined);

  protected readonly profile$: Observable<PublicProfile> = this.uuid$.pipe(
    filter(uuid => !!uuid),
    switchMap(uuid =>
      this.userProfileService.getPublicProfile(uuid).pipe(
        tap(profile => this.nickName.set(profile.nickname)),
        errorHandlingAndMapToUndefined()
      )
    )
  );

  protected readonly nickName = signal<string>(undefined);
  constructor(
    private readonly userProfileService: UserProfileService,
    private readonly modalController: ModalController
  ) {}

  public static async openModal(modalController: ModalController, uuid: string): Promise<void> {
    const ref = await modalController.create({
      component: UserProfileModalComponent,
      componentProps: {
        uuid
      },
      cssClass: 'fu-modal-default'
    });
    await ref.present();
    await ref.onDidDismiss();
  }

  protected async onClose(): Promise<void> {
    await this.modalController.dismiss();
  }
}
