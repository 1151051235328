import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Challenge, CommunityChallengeOverview, MemberMetrics, PartnerChallengeOverview, TeamMetrics } from '../model';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { PageResult } from '@fitup-monorepo/core/lib/model/page-result';

@Injectable({
  providedIn: 'root'
})
export class ChallengeService {
  constructor(private readonly httpClient: HttpClient) {}

  public getChallenge(challengeId: number): Observable<Challenge> {
    return this.httpClient.get<Challenge>(`${ApiService.API_URL}/challenges/${challengeId}`);
  }

  public getCommunityChallengeOverview(): Observable<CommunityChallengeOverview> {
    return this.httpClient.get<CommunityChallengeOverview>(`${ApiService.API_URL}/challenges/overview/community`);
  }

  public getPartnerChallengeOverview(): Observable<PartnerChallengeOverview> {
    return this.httpClient.get<PartnerChallengeOverview>(`${ApiService.API_URL}/challenges/overview/partner`);
  }

  public joinCommunityChallenge(challengeId: number): Observable<Challenge> {
    return this.httpClient.post<Challenge>(`${ApiService.API_URL}/challenges/${challengeId}/join`, null);
  }

  public markCompletedShown(challengeId: number): Observable<Challenge> {
    return this.httpClient.put<Challenge>(`${ApiService.API_URL}/challenges/${challengeId}/completed-shown`, null);
  }

  public getChallengeRanking(
    challengeId: number,
    pageSize: number,
    pageNum: number
  ): Observable<PageResult<MemberMetrics>> {
    return this.httpClient.get<PageResult<MemberMetrics>>(
      `${ApiService.API_URL}/challenges/${challengeId}/ranking?pageSize=${pageSize}&pageNum=${pageNum}`
    );
  }

  public getChallengeRankingCsv(challengeId: number): Observable<string> {
    return this.httpClient.get(`${ApiService.API_URL}/challenges/${challengeId}/ranking/csv`, {
      responseType: 'text'
    });
  }

  public leaveChallenge(challengeId: number): Observable<Challenge> {
    return this.httpClient.post<Challenge>(`${ApiService.API_URL}/challenges/${challengeId}/leave`, null);
  }

  public getPartnerMemberRanking(mode: string, startDate: string): Observable<MemberMetrics[]> {
    return this.httpClient.get<MemberMetrics[]>(
      `${ApiService.API_URL}/challenges/partner/ranking/member?start=${startDate}&mode=${mode}`
    );
  }

  public getPartnerTeamRanking(mode: string, startDate: string): Observable<TeamMetrics[]> {
    return this.httpClient.get<TeamMetrics[]>(
      `${ApiService.API_URL}/challenges/partner/ranking/team?start=${startDate}&mode=${mode}`
    );
  }
}
