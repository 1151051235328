// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-grow: 1;
}

.fu-button-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  --shadow-color-rgb: var(--fu-pure-white-rgb);
}
.fu-button-bottom-container .confirm.button-disabled {
  --background: var(--fu-editor-disabled-button);
  --color: var(--fu-grey-5);
}
.fu-button-bottom-container .discard {
  background-color: var(--fu-body-bg);
}

.button-container {
  margin-top: var(--fu-spacing-xs);
}
.button-container .confirm.button-disabled {
  opacity: 0.5;
}

@media screen and (min-width: 768px) {
  .fu-button-bottom-container {
    border-radius: var(--fu-radius-xl);
    overflow: hidden;
  }
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/editor/editor.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,4CAAA;AACF;AAEI;EACE,8CAAA;EACA,yBAAA;AAAN;AAIE;EACE,mCAAA;AAFJ;;AAMA;EACE,gCAAA;AAHF;AAMI;EACE,YAAA;AAJN;;AASA;EACE;IACE,kCAAA;IACA,gBAAA;EANF;AACF","sourcesContent":[":host {\n  display: flex;\n  flex-grow: 1;\n}\n\n.fu-button-bottom-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  --shadow-color-rgb: var(--fu-pure-white-rgb);\n\n  .confirm {\n    &.button-disabled {\n      --background: var(--fu-editor-disabled-button);\n      --color: var(--fu-grey-5);\n    }\n  }\n\n  .discard {\n    background-color: var(--fu-body-bg);\n  }\n}\n\n.button-container {\n  margin-top: var(--fu-spacing-xs);\n\n  .confirm {\n    &.button-disabled {\n      opacity: 0.5;\n    }\n  }\n}\n\n@media screen and (min-width: 768px) {\n  .fu-button-bottom-container {\n    border-radius: var(--fu-radius-xl);\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
