// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .content {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
}
:host .content__title {
  margin: var(--fu-spacing-xl) 0;
}
:host .content__icon {
  font-size: 36px;
}
:host .content__description {
  font-size: var(--fu-font-size-xs);
}`, "",{"version":3,"sources":["webpack://./libs/subscription/src/lib/subscription-plan/subscription-success/subscription-success.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AAAJ;AAEI;EACE,8BAAA;AAAN;AAGI;EACE,eAAA;AADN;AAII;EACE,iCAAA;AAFN","sourcesContent":[":host {\n  .content {\n    display: flex;\n    align-items: center;\n    text-align: center;\n    height: 100%;\n\n    &__title {\n      margin: var(--fu-spacing-xl) 0;\n    }\n\n    &__icon {\n      font-size: 36px;\n    }\n\n    &__description {\n      font-size: var(--fu-font-size-xs);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
