import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-membership-card',
  templateUrl: './membership-card.component.html',
  styleUrls: ['./membership-card.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembershipCardComponent {
  @Input({ required: true })
  public title!: string;

  @Input({ required: true })
  public buttonTitle!: string;

  @Output()
  public buttonClick = new EventEmitter<void>();

  @HostBinding('class')
  protected readonly theme = 'fu-theme fu-theme--light-membership';
}
