import { PACKAGE_TYPE, PurchasesStoreProduct } from '@revenuecat/purchases-capacitor';

export type PurchaseProductWithDefaultOption = PurchasesStoreProduct & {
  defaultOption: AndroidDefaultOption;
};

export interface Price {
  amountMicros: number;
  currencyCode: string;
  formatted: string;
}

export interface PricingPhase {
  billingCycleCount: number;
  offerPaymentMode: string;
  price: Price;
}

export interface AndroidDefaultOption {
  pricingPhases: PricingPhase[];
}

export interface MembershipPackage {
  id: string;
  name: string;
  plans: MembershipPlan[];
}

export interface MembershipPlan {
  id: string;
  name: string;
  description?: string;
  planType: PlanType;
  packageType: PACKAGE_TYPE;
  product: PurchasesStoreProduct;
  packageIdentifier: string;
  offeringIdentifier: string;
  price: number;
  introductoryPrice?: number;
  promotionOfferPrice?: number;
  currencyCode: string;
}

export enum PlanType {
  annually,
  monthly,
  quarterly
}
