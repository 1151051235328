import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { challengeDetailActions } from './challenge-detail.actions';
import { map } from 'rxjs/operators';
import { challengeActions } from '../../state/challenge.actions';

@Injectable()
export class ChallengeDetailEffects {
  public readonly loadChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengeDetailActions.loadChallenge),
      map(action => challengeActions.loadChallenge(action))
    )
  );

  public readonly joinChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengeDetailActions.joinChallenge),
      map(action => challengeActions.joinChallenge(action))
    )
  );

  public readonly leaveChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(challengeDetailActions.leaveChallenge),
      map(action => challengeActions.leaveChallenge(action))
    )
  );

  constructor(private readonly actions$: Actions) {}
}
