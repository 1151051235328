import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalizedDatePipe } from '@fitup-monorepo/core/lib/pipes/localized-date.pipe';
import { LocalizedCurrencyPipe } from '@fitup-monorepo/core/lib/pipes/localized-currency.pipe';
import { StartSubscriptionButtonComponent } from '../components/start-subscription-button/start-subscription-button.component';
import { formatPrice, monthAmountMap } from '../subscription.utils';
import {MembershipPlan, PlanType} from "@fitup-monorepo/core/lib/model/purchase/purchase";

@Component({
  selector: 'app-start-subscription',
  templateUrl: './start-subscription.component.html',
  styleUrls: ['./start-subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    LocalizedDatePipe,
    LocalizedCurrencyPipe,
    StartSubscriptionButtonComponent
  ],
  standalone: true
})
export class StartSubscriptionComponent {
  @Input()
  public plan: MembershipPlan | undefined;

  @Input()
  public hasFitnessOnlyMembership = false;

  @Input()
  public discount: number | undefined;

  @Output()
  public startSubscriptionSuccess = new EventEmitter<void>();

  protected readonly planTypes = PlanType;

  private readonly includedFitnessPlanItems = [
    'SUBSCRIPTION_PLAN.INCLUDED_FEATURES.LIVE_CLASSES',
    'SUBSCRIPTION_PLAN.INCLUDED_FEATURES.MEDIA_CENTER',
    'SUBSCRIPTION_PLAN.INCLUDED_FEATURES.CHALLENGES',
    'SUBSCRIPTION_PLAN.INCLUDED_FEATURES.ACTIVITY_TRACKING',
    'SUBSCRIPTION_PLAN.INCLUDED_FEATURES.VIRTUAL_COACH'
  ];
  private readonly includedFitnessNutritionPlanItems = [
    ...this.includedFitnessPlanItems,
    'SUBSCRIPTION_PLAN.INCLUDED_FEATURES.NUTRITION_PLANNER'
  ];

  constructor(private readonly translateService: TranslateService) {}

  public getIncludedPlanItems(): string[] {
    if (this.plan.offeringIdentifier === 'fitness') {
      return this.includedFitnessPlanItems;
    }
    return this.includedFitnessNutritionPlanItems;
  }

  public onSuccess(): void {
    this.startSubscriptionSuccess.emit();
  }

  public getMonthlyPrice(plan: MembershipPlan): string {
    return formatPrice(plan, this.translateService);
  }

  protected readonly monthAmountMap = monthAmountMap;
}
