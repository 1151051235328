// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  display: flex;
  flex: 1;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./libs/subscription/src/lib/subscription-plan/subscription-free-trial/subscription-free-trial-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,OAAA;EACA,YAAA;AACF","sourcesContent":[".content{\n  display: flex;\n  flex: 1;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
