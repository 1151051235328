import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';

export interface AppState {
  isTrackingActivity: boolean;
}

@Injectable({ providedIn: 'root' })
export class StateService {
  private readonly state$ = new BehaviorSubject<AppState>({
    isTrackingActivity: false
  });

  constructor(private readonly localStorageService: LocalStorageService) {
    console.log('instantiated state service', JSON.stringify(this.state$.value));
    this.updateState(this.localStorageService.retrieve('state'));
    this.localStorageService.clear('state');
  }

  public updateState(patch: Partial<AppState>): void {
    this.state$.next({ ...this.state$.value, ...patch });
  }

  public getState(): AppState {
    return this.state$.value;
  }
}
