import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { IonicModule, NavController } from '@ionic/angular';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { FeedbackDropdownMenuComponent } from '../feedback-dropdown-menu/feedback-dropdown-menu.component';
import { selectProfilePicture } from '@fitup-monorepo/core/lib/state/profile/profile.selector';
import { defaultProfileImage } from '@fitup-monorepo/core/lib/constants';
import { HeaderTheme } from '@fitup-monorepo/core/lib/util/style.util';
import { environment } from '@fitup-monorepo/core/lib/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [CommonModule, IonicModule, RouterLink, FeedbackDropdownMenuComponent],
  standalone: true
})
export class HeaderComponent {
  @Input()
  public showBackButton = true;

  @Input()
  public defaultBackButtonAction = true;

  @Input()
  public showProfile = false;

  @Input()
  public showLogo = false;

  @Input()
  public showCancelButton = false;

  @Input()
  public backButtonHref: string | undefined;

  @Input()
  public navigateBackToLastLocation = false;

  @Input()
  public headerTitle: string | undefined;

  @Input()
  public activeProfile = false;

  @Input()
  public isTitleCapitalize = true;

  @Input()
  public theme: HeaderTheme = 'light';

  @Input()
  public showUserFeedbackOptions = false;

  @Input()
  public userFeedbackTopic = '';

  @Output()
  public cancelClick = new EventEmitter<void>();

  @Output()
  public backButtonClick = new EventEmitter<void>();

  public profilePicture$ = this.store.select(selectProfilePicture);

  @HostBinding('class')
  private get headerTheme(): string {
    if (this.theme === 'dark') {
      return 'fu-theme fu-theme--dark';
    } else if (this.theme === 'light') {
      return 'fu-theme fu-theme--light';
    }
    return '';
  }

  constructor(
    private readonly navController: NavController,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store
  ) {}

  public async onBackButtonClick(): Promise<void> {
    this.backButtonClick.emit();
    if (!this.defaultBackButtonAction) {
      return;
    }
    if (this.navigateBackToLastLocation) {
      this.navController.back();
    } else if (this.backButtonHref) {
      await this.navController.navigateBack([this.backButtonHref]);
    } else {
      await this.navController.navigateBack(['../'], { relativeTo: this.activatedRoute });
    }
  }

  protected readonly defaultProfileImage = defaultProfileImage;

  protected getLogo(): string {
    return environment.appName === 'fitup' ? 'fitup-logo' : 'moveathlon-logo';
  }
}
