import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'duration', standalone: true })
export class DurationPipe implements PipeTransform {
  public transform(durationInSeconds: unknown, format = 'h:mm:ss'): string {
    if (typeof durationInSeconds === 'number') {
      const roundedValue = Math.round(durationInSeconds);

      const hasSeconds = format.includes('s');
      const hasHours = format.includes('h');
      const hasMinutes = format.includes('m');

      let seconds = roundedValue % 60;
      let minutes = Math.floor((roundedValue / 60) % 60);
      const hours = Math.floor(roundedValue / 60 / 60);

      if (!hasHours && (hasMinutes || hasSeconds)) {
        minutes += hours * 60;
        if (!hasMinutes && hasSeconds) {
          seconds += minutes * 60;
        }
      }

      const secondsString = String(seconds);
      const minutesString = String(minutes);
      const hoursString = String(hours);

      if (format.includes('X') || format.includes('Y') || format.includes('Z')) {
        if (roundedValue === 0) {
          return format.replace('X', hoursString).replace('Y', '').replace('m', '').trim();
        }
        if (hours === 0) {
          format = format.replace('X', '').replace('h', '');
        }
        if (minutes === 0) {
          format = format.replace('Y', '').replace('m', '');
        }
        if (seconds === 0) {
          format = format.replace('Z', '').replace('s', '');
        }
        return format.replace('X', hoursString).replace('Y', minutesString).replace('Z', secondsString).trim();
      }

      return format
        .replace('hh', hoursString.padStart(2, '0'))
        .replace('h', hoursString)
        .replace('mm', minutesString.padStart(2, '0'))
        .replace('m', minutesString)
        .replace('ss', secondsString.padStart(2, '0'))
        .replace('s', secondsString);
    }
    return JSON.stringify(durationInSeconds);
  }
}
