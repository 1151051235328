import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

export const checkErrorIsForbidden = (error: unknown): boolean => {
  return error instanceof HttpErrorResponse && error.status === HttpStatusCode.Forbidden;
};

export function getErrorMessage(error: unknown): string {
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object' && error !== null) {
    if (hasMessage(error) && typeof error.message === 'string') {
      return error.message;
    }
    if (hasError(error)) {
      return getErrorMessage(error);
    }
  }
  return JSON.stringify(error);
}

function hasMessage(error: object): error is { message: unknown } {
  return 'message' in error;
}

function hasError(error: object): error is { error: unknown } {
  return 'error' in error;
}
