import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Group, GroupCreateOrUpdateRequest, GroupOverview } from '../model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GroupService {
  constructor(private readonly httpClient: HttpClient) {}

  public getGroupOverview(): Observable<GroupOverview> {
    return this.httpClient.get<GroupOverview>(`${ApiService.API_URL}/groups`);
  }

  public joinGroup(invitationCode: string): Observable<Group> {
    return this.httpClient.put<Group>(`${ApiService.API_URL}/groups/join/${invitationCode}`, null);
  }

  public leaveGroup(groupId: number): Observable<Group> {
    return this.httpClient.put<Group>(`${ApiService.API_URL}/groups/${groupId}/leave`, null);
  }

  public createGroup(request: GroupCreateOrUpdateRequest): Observable<Group> {
    console.log('about to create group', request);
    return this.httpClient.post<Group>(`${ApiService.API_URL}/groups`, request, { observe: 'response' }).pipe(
      map(response => {
        console.log('created group', response.status, response.body, response.headers);
        return response.body;
      }),
      catchError(err => {
        console.log('error creating group', err);
        throw err;
      })
    );
  }

  public updateGroup(groupId: number, request: GroupCreateOrUpdateRequest): Observable<Group> {
    return this.httpClient.put<Group>(`${ApiService.API_URL}/groups/${groupId}`, request);
  }

  public deleteGroup(groupId: number): Observable<void> {
    return this.httpClient.delete<void>(`${ApiService.API_URL}/groups/${groupId}`);
  }
}
