import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { AnimationController, IonicModule, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { interval, Observable, startWith } from 'rxjs';
import { RouterModule } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { LocalizedDatePipe } from '@fitup-monorepo/core/lib/pipes/localized-date.pipe';

@Component({
  selector: 'app-points',
  templateUrl: './points.page.html',
  styleUrls: ['./points.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IonicModule, HeaderComponent, TranslateModule, LocalizedDatePipe, RouterModule],
  standalone: true
})
export class PointsPage implements AfterViewInit {
  @Input()
  public set currentPoints(point: number) {
    if (point) {
      this.progress$ = this.initProgress(point);
    }
  }

  @Input()
  public totalPoints: number | undefined;

  @Input()
  public buttonTitle: string | undefined;

  public readonly currentDate = new Date();

  public readonly isAnimating = signal<boolean>(true);
  protected progress$: Observable<number>;

  constructor(
    private readonly animationCtrl: AnimationController,
    private readonly modalController: ModalController
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    await this.playAnimation();
  }

  private initProgress(point: number): Observable<number> {
    const intervalTime = 10;
    return interval(intervalTime).pipe(
      startWith(0),
      take(point + 2),
      map(v => v)
    );
  }

  public async playAnimation(): Promise<void> {
    const buttonAnimation = this.animationCtrl
      .create('button')
      .addElement(document.querySelector('.point-button-box'))
      .duration(1000)
      .delay(400)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'translateY(500px)' },
        { offset: 1, opacity: '1', transform: 'translateY(0)' }
      ]);

    const labelAnimation = this.animationCtrl
      .create('info')
      .addElement(document.querySelector('.point-info'))
      .duration(1000)
      .delay(800)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'translateY(500px)' },
        { offset: 1, opacity: '1', transform: 'translateY(0)' }
      ]);

    const parentAnimation = this.animationCtrl
      .create('parent')
      .iterations(1)
      .easing('linear')
      .direction('alternate')
      .addAnimation([buttonAnimation, labelAnimation]);

    await parentAnimation.play();
    this.isAnimating.set(false);
  }

  public async onCloseModal(): Promise<void> {
    await this.modalController.dismiss();
  }
}
