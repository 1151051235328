import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ReportProblemComponent, ReportProblemConfig } from './report-problem.component';

@Injectable({
  providedIn: 'root',
})
export class ReportProblemService {
  constructor(private readonly modalController: ModalController) {}

  public async openReportModal(topic: string, config?: ReportProblemConfig): Promise<void> {
    const modal = await this.modalController.create({
      component: ReportProblemComponent,
      componentProps: {
        topic,
        config,
      },
      cssClass: ['fu-report-modal'],
    });
    await modal.present();
    await modal.onDidDismiss();
  }
}
