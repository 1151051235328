import { Component, HostBinding, Input, signal } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { StartGuideTask } from '../model/start-guide';
import { TranslateModule } from '@ngx-translate/core';
import { getTexts, Texts } from '../start-guide.util';

@Component({
  selector: 'app-start-guide-overview',
  templateUrl: './start-guide-overview.component.html',
  styleUrls: ['./start-guide-overview.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterModule, TranslateModule],
})
export class StartGuideOverviewComponent {
  @Input({ required: true })
  public set tasks(tasks: StartGuideTask[]) {
    this.completedTasks.set(tasks.filter(t => t.completed));
    this.incompleteTasks.set(tasks.filter(t => !t.completed));
    this.allTasks.set(tasks);
  }

  @Input()
  public boxStyle: 'rounded' | 'squared' = 'rounded';

  @HostBinding('class')
  protected readonly theme = 'fu-theme fu-theme--light-guide';

  @HostBinding('class.rounded')
  public get isRounded(): boolean {
    return this.boxStyle === 'rounded';
  }

  @HostBinding('class.hide')
  public get isHide(): boolean {
    return !this.isDisplay();
  }

  protected readonly completedTasks = signal<StartGuideTask[]>([]);
  protected readonly incompleteTasks = signal<StartGuideTask[]>([]);
  protected readonly allTasks = signal<StartGuideTask[]>([]);
  protected readonly isDisplay = signal<boolean>(true);

  protected getTexts(): Texts {
    return getTexts(this.completedTasks().length, this.allTasks().length);
  }
}
