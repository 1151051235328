// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report {
  display: flex;
  flex-direction: column;
  gap: var(--fu-spacing-m);
  padding: var(--fu-spacing-m);
}
.report__icon {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/report-problem/report-problem.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,wBAAA;EACA,4BAAA;AACF;AACE;EACE,mBAAA;AACJ","sourcesContent":[".report {\n  display: flex;\n  flex-direction: column;\n  gap: var(--fu-spacing-m);\n  padding: var(--fu-spacing-m);\n\n  &__icon {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
