import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RecipeQueryService } from '../../recipe-query.service';
import { map, switchMap } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';

import { RecipeListComponent } from './recipe-list/recipe-list.component';
import { TranslatableTextPipe } from '@fitup-monorepo/core/lib/pipes/translatable-text.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FooterInfoComponent } from '@fitup-monorepo/components/lib/footer-info/footer-info.component';
import { footerInfoRecipe } from '../../util/nutrition-planner.util';
import { shuffleArray } from '@fitup-monorepo/core/lib/util/array-util';
import { selectRecipeSelectedFilters } from './state/recipe-filter.selector';
import { LoadingCardsComponent } from '@fitup-monorepo/components/lib/loading-cards/loading-cards.component';
import { RecipeFilterComponent } from './recipe-filter/recipe-filter.component';
import { FbMealType, FbRecipe, GroupedRecipeResponse } from '../../model/firestore-plan';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslatableTextPipe,
    RecipeListComponent,
    LoadingCardsComponent,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    FooterInfoComponent,
    RecipeFilterComponent
  ]
})
export class RecipesComponent {
  protected readonly groupedRecipe$: Observable<GroupedRecipeResponse> = this.store
    .select(selectRecipeSelectedFilters)
    .pipe(
      switchMap(selectedFilters =>
        this.recipeQueryService.getRecipes().pipe(
          map(recipes => filterRecipes(recipes, selectedFilters)),
          map(recipes => this.groupedRecipes(recipes))
        )
      )
    );

  protected readonly footerInfoRecipe = footerInfoRecipe;

  protected readonly mealType = FbMealType;

  constructor(
    private readonly recipeQueryService: RecipeQueryService,
    private readonly store: Store
  ) {}

  public ngOnInit(): void {}

  private groupedRecipes(recipes: FbRecipe[]): GroupedRecipeResponse {
    const categorized: GroupedRecipeResponse = {};
    recipes?.forEach(recipe => {
      recipe.mealTypes.forEach(async type => {
        if (!categorized[type]) {
          categorized[type] = { recipes: [] };
        }

        categorized[type].recipes.push(recipe);
      });
    });

    Object.keys(categorized).forEach(key => {
      shuffleArray(categorized[key].recipes);
    });

    return categorized;
  }
}

export function filterRecipes(recipes: FbRecipe[], selectedFilters: string[]): FbRecipe[] {
  if (selectedFilters.length == 0) return recipes;

  return recipes.filter(recipe => selectedFilters.every(filter => recipe.tags.includes(filter)));
}
