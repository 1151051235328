import { TranslatableString } from '@fitup-monorepo/core/lib/services/translatable-text/translatable-string';
import { WeekDay } from '@fitup-monorepo/core/lib/model/weekday';
import { MealPlanResource } from './firestore-plan';

export interface Target {
  calories: number;
  carbsInGrams: number;
  fatInGrams: number;
  proteinInGrams: number;
}

export enum PlanGender {
  female = 'female',
  male = 'male',
  other = 'other'
}

export interface PlanOverview {
  id: string;
  title: TranslatableString;
  completionResources?: MealPlanResource[];
  image: string;
  durationInWeeks: number;
  recipeAmount: number;
  categories: PlanCategory[];
  isOngoing: boolean;
  target: Target;
  isFree: boolean;
}

export enum PlanCategory {
  balanced = 'Balanced',
  fasting = 'Fasting',
  highProtein = 'HighProtein',
  popular = 'Popular'
}

export type PlanDetail = PlanOverview & {
  mealSuggestionsAmount: number;
  descriptions: TranslatableString;
  resources?: MealPlanResource[];
  weeks: WeeklyPlanOverview[];
};

export interface WeeklyPlanOverview {
  title: string;
  description: string;
  image: string;
}

export enum PlanStatus {
  active = 'Active',
  cancelled = 'Cancelled',
  completed = 'Completed'
}

export interface OngoingPlan {
  id: string;
  status: PlanStatus;
}

export type PlanProgressDetail = PlanOverview & {
  status: PlanStatus;
  dailyCaloriesAmount: number;
  progressPercentage: number;
  trackedMealsAmount: number;
  skippedMealsAmount: number;
  caloriesIntakeAmount: number;
  caloriesBurntAmount: number;
  weeksCompletedAmount: number;
  totalWeekAmount: number;
  weeks: WeeklyPlanDetail[];
};

export type WeeklyPlanDetail = WeeklyPlanOverview & {
  daysAmount: number;
  numOfWeek: number;
  completedDaysAmount: number;
  status: WeeklyPlanStatus;
};

export enum WeeklyPlanStatus {
  completed = 'Completed',
  active = 'Active',
  upcoming = 'Upcoming'
}

export interface PlanOngoingDetail {
  id: string;
  sourceTemplateId: string;
  title: TranslatableString;
  startDate: Date;
  endDate: Date;
  target: Target;
  currentWeekPlan: WeeklyPlanSummary;
  dailyPlanDetails: DailyPlanDetails;
}

export type WeeklyPlanSummary = WeeklyPlanDetail & {
  caloriesIntakeAmount: number;
  caloriesBurntAmount: number;
};

export interface DailyPlanDetails {
  date: Date;
  numOfWeek: number;
  numOfDay: number;
  weekDay: WeekDay;
  caloriesIntakeAmount: number;
  caloriesBurntAmount: number;
  caloriesLeftAmount: number;
  carbsInGramsIntakeAmount: number;
  proteinInGramsIntakeAmount: number;
  fatInGramsIntakeAmount: number;
  meals: Meal[];
}

export interface MealBase {
  type: InternalMealType;
  planId: string;
  numOfWeek: number;
  mealType: MealType;
  weekDay: WeekDay;
  date: Date;
  status: MealStatus;
  title: TranslatableString;
  image: string;
  caloriesAmount: number;
  carbsInGramsAmount: number;
  proteinInGramsAmount: number;
  fatInGramsAmount: number;
  tags: string[];
  trackedPortionAmount?: number;
}

export type NutritionPlanMeal = MealBase & {
  type: InternalMealType.nutritionPlan;
  recipeId: string;
  preparationTimeMinutes: number;
};

type CustomMeal = MealBase & {
  type: InternalMealType.custom;
  verified?: boolean;
};

export type Meal = NutritionPlanMeal | CustomMeal;

export type NutritionPlanMealDetail = NutritionPlanMeal & {
  portionAmount: number;
  ingredients: Ingredient[];
  instructions: TranslatableString[];
};

export type CustomMealDetail = CustomMeal & {
  portionUnitName: string;
  portionAmount: number;
};

export type MealDetail = NutritionPlanMealDetail | CustomMealDetail;

export interface Ingredient {
  name: TranslatableString;
  amount: number;
  unit: IngredientUnit;
}

export interface MealAlternative {
  id: string;
  image: string;
  title: TranslatableString;
  preparationTimeMinutes: number;
  caloriesAmount: number;
}

export enum IngredientUnit {
  gram = 'gr',
  milliliter = 'Milliliter',
  piece = 'pc',
  pinch = 'pinch',
  teaSpoon = 'TL',
  tableSpoon = 'EL',
  knifePoint = 'KnifePoint',
  can = 'can',
  cup = 'Cup',
  stick = 'Stick'
}

export enum MealStatus {
  tracked = 'tracked',
  skipped = 'skipped',
  open = 'open'
}

export enum InternalMealType {
  custom = 'custom-meal',
  nutritionPlan = 'nutrition-plan-meal',
  customAdded = 'custom-meal-added'
}

export enum MealType {
  breakfast = 'Breakfast',
  lunch = 'Lunch',
  dinner = 'Dinner',
  snack = 'Snack',
  snack1 = 'Snack1',
  snack2 = 'Snack2'
}

export type GroupedPlanResponse = {
  [key in PlanCategory]?: {
    plans: PlanOverview[];
    color: string;
  };
};
