import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubscriptionPlanComponent } from './subscription-plan.component';
import { IonicModule, ModalController, NavController } from '@ionic/angular';
import { StartSubscriptionModalComponent } from './start-subscription/start-subscription-modal.component';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { SubscriptionFreeTrialModalComponent } from './subscription-free-trial/subscription-free-trial-modal.component';
import { dismissModalIfExistent } from './subscription.utils';
import { TranslateModule } from '@ngx-translate/core';
import {MembershipPlan} from "@fitup-monorepo/core/lib/model/purchase/purchase";

@Component({
  selector: 'app-subscription-plan-modal',
  templateUrl: './subscription-plan-modal.component.html',
  styleUrls: ['./subscription-plan-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SubscriptionPlanComponent, HeaderComponent, IonicModule, TranslateModule],
  standalone: true
})
export class SubscriptionPlanModalComponent {
  @Input()
  public hasFitnessOnlyMembership = false;

  @Input()
  public fromInitialModal = false;

  @Input()
  public isTrialExpired = false;

  private static readonly modalId = 'subscription-plan';

  constructor(
    private readonly modalController: ModalController,
    private readonly navController: NavController
  ) {}

  public static async openModal(
    modalController: ModalController,
    hasFitnessOnly = false,
    fromInitialModal = false,
    isTrialExpired = false
  ): Promise<void> {
    const modal = await modalController.create({
      component: SubscriptionPlanModalComponent,
      componentProps: {
        hasFitnessOnlyMembership: hasFitnessOnly,
        fromInitialModal,
        isTrialExpired
      },
      id: SubscriptionPlanModalComponent.modalId,
      cssClass: 'fu-subscription-modal',
      backdropDismiss: false
    });
    await modal.present();
    await modal.onDidDismiss();
  }

  public static closeModal(modalController: ModalController): void {
    dismissModalIfExistent(modalController, SubscriptionPlanModalComponent.modalId);
  }

  public async onContinue(event: { selectedPlan: MembershipPlan; discount: number }): Promise<void> {
    if (this.fromInitialModal) {
      await SubscriptionFreeTrialModalComponent.openModal(this.modalController, event.selectedPlan);
    } else {
      await StartSubscriptionModalComponent.openModal(
        this.modalController,
        event.selectedPlan,
        this.hasFitnessOnlyMembership,
        event.discount
      );
    }
  }

  public onClose(): void {
    this.dismissSelf();
  }

  public onEnterPromoCode(): void {
    this.modalController.dismiss(null, null, 'subscription-initial');
    this.dismissSelf();
    this.navController.navigateForward(['/promo-code']);
  }

  public onContinueNonNative(): void {
    this.dismissSelf();
  }

  private dismissSelf(): void {
    SubscriptionPlanModalComponent.closeModal(this.modalController);
  }

  public onBackButtonClick(): void {
    this.dismissSelf();
  }
}
