import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { WaterIntake } from '../model/water-intake';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';

export const waterIntakeActions = createActionGroup({
  source: 'Water Intake',
  events: {
    ['Load Water Intake']: emptyProps(),
    ['Load Water Intake Success']: props<{ waterIntake: WaterIntake }>(),
    ['Load Water Intake Failure']: props<ActionError>(),
    ['Add Water Intake']: props<{ amountMl: number }>(),
    ['Add Water Intake Success']: props<{ waterIntake: WaterIntake }>(),
    ['Add Water Intake Failure']: props<ActionError>()
  }
});
