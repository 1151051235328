import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PageResult } from '../../model/page-result';
import { ActionError } from '../../model/common-types';
import { Notification } from '../../model/notification/notification';

export const notificationsActions = createActionGroup({
  source: 'Notifications',
  events: {
    ['Load Notifications']: emptyProps(),
    ['Load Notifications Success']: props<PageResult<Notification>>(),
    ['Load Notifications Failure']: props<ActionError>(),
    ['Mark Read Notification']: props<{ notificationId: number }>(),
    ['Mark Read Notification Success']: props<{ notifications: Notification[] }>(),
    ['Mark Read Notification Failure']: props<ActionError>(),
    ['Mark All Notifications As Read']: emptyProps(),
    ['Mark All Notifications As Read Success']: emptyProps(),
    ['Mark All Notifications As Read Failure']: props<ActionError>(),
    ['Load More Notifications']: emptyProps(),
    ['Load More Notifications Success']: props<PageResult<Notification>>()
  }
});
