import { createFeatureSelector, createSelector } from '@ngrx/store';
import { notificationAdapter, NotificationsState } from './notifications.reducer';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { Notification } from '../../model/notification/notification';
import { sortByCreatedAt } from '../../util/array-util';

export const selectNotificationsFeature = createFeatureSelector<NotificationsState>('notifications');
export const selectNotificationsState = createSelector(selectNotificationsFeature, state => state);
export const { selectEntities: selectNotificationsEntities } =
  notificationAdapter.getSelectors(selectNotificationsState);

export const selectNotificationsPageNumber = createSelector(selectNotificationsState, state =>
  state.pageNum === 0 ? state.pageNum + 1 : state.pageNum
);

export const selectNotificationsLastPageSize = createSelector(selectNotificationsState, state => state.lastPageSize);

export const selectNotifications: MemoizedSelector<NotificationsState, Notification[]> = createSelector(
  selectNotificationsState,
  state => sortByCreatedAt(Object.values(state.entities), 'desc', 'createdAt')
);

export const selectHasUnreadNotification: MemoizedSelector<NotificationsState, boolean> = createSelector(
  selectNotificationsState,
  state => Object.values(state.entities).some(n => !n.isRead)
);
