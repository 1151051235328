import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { BrowserPlugin } from '@capacitor/browser';
import { environment } from '../../environment';
import { BROWSER } from '../../capacitor-injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  #window: Window;

  constructor(
    @Inject(DOCUMENT) document: Document,
    @Inject(BROWSER) private readonly browser: BrowserPlugin,
    private readonly platform: Platform
  ) {
    this.#window = document.defaultView;
  }

  public async openUrlInApp(url: string, noAppInstalledCallback: () => void | Promise<void>): Promise<void> {
    const currentRef = window.location.href;
    window.location.href = `${environment.urlScheme}://${url}`;
    await new Promise(resolve => setTimeout(resolve, 1000));
    noAppInstalledCallback();
    window.location.href = currentRef;
  }

  public async openUrl(url: string, newWindow: boolean): Promise<void> {
    try {
      if (this.platform.is('cordova')) {
        await this.browser.open({
          url
        });
      } else {
        this.#window.open(url, newWindow ? '_blank' : '_self');
      }
    } catch (error) {
      console.log(error);
    }
  }
}
