import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { StartGuideOverview } from './model/start-guide';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { FirestoreService } from '@fitup-monorepo/core/lib/services/firestore/firestore.service';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { selectCustomer } from '@fitup-monorepo/core/lib/state/profile/profile.selector';

@Injectable({
  providedIn: 'root'
})
export class StartGuideService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly firestoreService: FirestoreService,
    private readonly store: Store
  ) {}

  public getStartGuideOverview(): Observable<StartGuideOverview> {
    return this.store.select(selectCustomer).pipe(
      filter(customer => !!customer),
      switchMap(customer =>
        this.firestoreService.observeDocument<StartGuideOverview>(`customer/${customer.uuid}/startGuide/startGuide`)
      )
    );
  }

  public dismissStartGuide(): Observable<void> {
    return this.httpClient.post<void>(`${ApiService.API_URL}/start-guide/dismiss`, null);
  }
}
