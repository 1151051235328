import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController, NavController } from '@ionic/angular';
import { SegmentComponent } from '@fitup-monorepo/components/lib/segment/segment.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalizedNumberPipe } from '@fitup-monorepo/core/lib/pipes/localized-number.pipe';
import { LocalizedDatePipe } from '@fitup-monorepo/core/lib/pipes/localized-date.pipe';
import { LocalizedCurrencyPipe } from '@fitup-monorepo/core/lib/pipes/localized-currency.pipe';
import { CtaButtonComponent } from '@fitup-monorepo/components/lib/cta-button/cta-button.component';
import { BehaviorSubject, from, Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NativeOnlyDirective, PlatformService } from '@fitup-monorepo/core/lib/services/platform/platform.service';
import { InfoBannerComponent } from '@fitup-monorepo/components/lib/info-banner/info-banner.component';
import { formatPrice, monthAmountMap, trialDays } from './subscription.utils';
import { PriceTableConfig, StripeConfigService } from './stripe-config.service';
import { errorLogging } from '@fitup-monorepo/core/lib/util/rxjs-util';
import { Store } from '@ngrx/store';
import { SubscriptionPlanModalComponent } from './subscription-plan-modal.component';
import { PurchaseService } from '@fitup-monorepo/core/lib/services/purchase/purchase.service';
import { MembershipPackage, MembershipPlan, PlanType } from '@fitup-monorepo/core/lib/model/purchase/purchase';
import { selectManageSubscriptionUrl } from '@fitup-monorepo/core/lib/state/profile/profile.selector';

export interface SubscriptionOffers {
  iconName: string;
  label: string;
}

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    SegmentComponent,
    ReactiveFormsModule,
    TranslateModule,
    LocalizedNumberPipe,
    LocalizedDatePipe,
    LocalizedCurrencyPipe,
    CtaButtonComponent,
    NativeOnlyDirective,
    InfoBannerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SubscriptionPlanComponent implements OnInit {
  @Input()
  public set hasFitnessOnlyMembership(hasFitnessOnly: boolean) {
    this.hasFitnessOnly = hasFitnessOnly;
    this.hasFitnessOnly$.next(hasFitnessOnly);
  }

  @Input()
  public isTrialExpired = false;

  @Output()
  public readonly continue = new EventEmitter<{
    selectedPlan: MembershipPlan;
    discount: number;
  }>();

  @Output()
  public readonly continueNonNative = new EventEmitter<void>();

  public hasFitnessOnly = false;
  public currencySymbol = '€';
  public discount = 0;

  private readonly hasFitnessOnly$ = new BehaviorSubject<boolean>(this.hasFitnessOnly);

  public readonly trialDays = trialDays;

  public readonly selectedPlanControl = new FormControl<MembershipPlan>(null, Validators.required);
  public readonly currentPackage$: Observable<MembershipPackage> = this.hasFitnessOnly$.pipe(
    switchMap(() =>
      from(this.purchaseService.getCurrentMembershipPackage()).pipe(
        tap(currentPackage => {
          const annualPlan = this.getAnnualPlan(currentPackage);
          this.currencySymbol = annualPlan.currencyCode;
          this.selectedPlanControl.setValue(annualPlan);
        })
      )
    )
  );

  public readonly priceTableConfig$: Observable<PriceTableConfig> = this.stripeConfigService
    .getPriceTableConfig()
    .pipe(errorLogging());

  public readonly manageSubscriptionUrl$: Observable<string | undefined> =
    this.store.select(selectManageSubscriptionUrl);

  public readonly subscriptionOffers: SubscriptionOffers[] = [
    { iconName: 'salad-bowl', label: 'SUBSCRIPTION_PLAN.OFFERS.NUTRITION' },
    {
      iconName: 'bicycle-man',
      label: 'SUBSCRIPTION_PLAN.OFFERS.ACTIVITY_TRACKING'
    },
    {
      iconName: 'play-square-grey',
      label: 'SUBSCRIPTION_PLAN.OFFERS.MEDIA_CENTER_LIVE'
    },
    {
      iconName: 'fitness-list',
      label: 'SUBSCRIPTION_PLAN.OFFERS.TRAINING_PLANS'
    },
    {
      iconName: 'person-in-lotus-position',
      label: 'SUBSCRIPTION_PLAN.OFFERS.MINDFULNESS'
    }
  ];
  public readonly planTypes = PlanType;
  public readonly buttonVisibility$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly purchaseService: PurchaseService,
    private readonly platformService: PlatformService,
    private readonly translateService: TranslateService,
    private readonly stripeConfigService: StripeConfigService,
    private readonly store: Store,
    private readonly modalController: ModalController,
    private readonly navController: NavController
  ) {}

  public ngOnInit(): void {
    // note: we need this otherwise this annoying button doesn't show up initially on ios
    setTimeout(() => {
      this.buttonVisibility$.next(true);
    }, 100);
  }

  public async onContinue(): Promise<void> {
    if (this.isNative()) {
      this.continue.emit({
        selectedPlan: this.selectedPlanControl.value,
        discount: this.getDiscount(this.selectedPlanControl.value)
      });
    } else {
      this.continueNonNative.emit();
    }
  }

  public getDiscount(plan: MembershipPlan): number {
    const originalPrice = plan.price;
    const reducedPrice = plan.promotionOfferPrice;

    return reducedPrice ? -1 * (Math.round(((originalPrice - reducedPrice) / originalPrice) * 100) / 100) : undefined;
  }

  public getAnnualPlan(currentPackage: MembershipPackage): MembershipPlan {
    return currentPackage.plans.find(p => p.planType === PlanType.annually);
  }

  public typedPlans(plans: unknown): MembershipPlan[] {
    return plans as MembershipPlan[];
  }

  public isNative(): boolean {
    return this.platformService.isNative();
  }

  public formatPrice(plan: MembershipPlan, useOriginalPrice = false): string {
    return formatPrice(plan, this.translateService, useOriginalPrice);
  }

  public getCurrentLang(): string {
    return this.translateService.currentLang;
  }

  public async goProfile(): Promise<void> {
    await this.navController.navigateForward('/tabs/account');
    SubscriptionPlanModalComponent.closeModal(this.modalController);
  }

  protected readonly monthAmountMap = monthAmountMap;
}
