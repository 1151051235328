import { Query } from '@angular/fire/compat/firestore';

export interface TokenResponse {
  token: string;
}

export interface FirestoreEntity {
  id: string;
}

export interface FilterDefinition {
  field: string;
  operator: '==' | '<' | '<=' | '>' | '>=';
  value: any;
}

export class Filter {
  constructor(private readonly definition: FilterDefinition) {}

  public apply(query: Query): Query {
    return query.where(this.definition.field, this.definition.operator, this.definition.value);
  }
}

export class FilterSet {
  constructor(private readonly filters: Filter[]) {}

  public apply(query: Query): Query {
    return this.filters.reduce((q, f) => f.apply(query), query);
  }
}
