// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.toggle {
  padding: 5px;
}

.toggle-label {
  font-size: 13px;
}

body {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  --ion-background-color: #F4F6F8 !important;
}
body ion-button {
  font-weight: bold;
}

@media (min-width: 1440px) {
  ion-app {
    width: 1440px;
    margin: auto;
  }
}`, "",{"version":3,"sources":["webpack://./apps/fitup/src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,uDAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,uDAAA;EACA,0CAAA;AACF;AACE;EACE,iBAAA;AACJ;;AAGA;EACE;IACE,aAAA;IACA,YAAA;EAAF;AACF","sourcesContent":[":host {\n  font-family: \"Plus Jakarta Sans\", sans-serif !important;\n}\n\n.toggle {\n  padding: 5px;\n}\n\n.toggle-label {\n  font-size: 13px;\n}\n\nbody {\n  font-family: \"Plus Jakarta Sans\", sans-serif !important;\n  --ion-background-color: #F4F6F8 !important;\n\n  ion-button {\n    font-weight: bold;\n  }\n}\n\n@media (min-width: 1440px){\n  ion-app{\n    width: 1440px;\n    margin: auto;\n  }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
