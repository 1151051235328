// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --fu-color-primary: var(--fu-turquoise) ;
}`, "",{"version":3,"sources":["webpack://./libs/nutrition-planner/src/lib/meal-search/meal-search.page.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;AACF","sourcesContent":[":host {\n  --fu-color-primary: var(--fu-turquoise)\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
