import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { accountPageActions } from './account.page.actions';

export const accountPageGuard: CanActivateFn = (): boolean => {
  const store = inject(Store);

  store.dispatch(accountPageActions.loadBadges());

  return true;
};
