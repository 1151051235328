import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, Observable, switchMap } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { errorHandlingWithToast, isErrorResult } from '@fitup-monorepo/core/lib/util/rxjs-util';
import { MealSearchService } from '../../meal-search.service';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { IonicModule, NavController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { BannerComponent } from '@fitup-monorepo/components/lib/banner/banner.component';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedNumberPipe } from '@fitup-monorepo/core/lib/pipes/localized-number.pipe';
import { MealItemComponent } from '../meal-item/meal-item.component';
import { PlanFeatureService } from '../../plan-feature.service';
import { customMealImage } from '../../custom-meal-image';
import { Meal } from '../../model/plan';
import { SearchedCustomMeal } from '../../model/custom-meal';
import { WeekDay } from '@fitup-monorepo/core/lib/model/weekday';

export enum MealSearchAction {
  swap = 'swap',
  add = 'add'
}

export interface MealSearchBase {
  planId: string;
  action: MealSearchAction;
  date: Date;
  numOfWeek: number;
  weekDay: WeekDay;
}

export type MealSearchAdd = MealSearchBase & {
  action: MealSearchAction.add;
};

export type MealSearchSwap = MealSearchBase & {
  action: MealSearchAction.swap;
  currentMeal: Meal;
  mealIndex: number;
};

export type MealSearchConfig = MealSearchSwap | MealSearchAdd;

@Component({
  selector: 'app-meal-search',
  templateUrl: './meal-search.component.html',
  styleUrls: ['./meal-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    BannerComponent,
    ReactiveFormsModule,
    TranslateModule,
    LocalizedNumberPipe,
    MealItemComponent
  ]
})
export class MealSearchComponent {
  @Input()
  public config: MealSearchConfig | undefined;

  public readonly searchControl = new FormControl('');
  public loading = false;

  public readonly meals$: Observable<SearchedCustomMeal[]> = this.searchControl.valueChanges.pipe(
    debounceTime(500),
    tap(() => {
      this.loading = true;
    }),
    filter(value => !!value),
    switchMap(searchString =>
      this.mealSearchService.searchMeals(searchString).pipe(
        errorHandlingWithToast(this.toastService),
        map(data => (isErrorResult(data) ? undefined : data)),
        tap(() => {
          this.loading = false;
        })
      )
    )
  );
  public readonly defaultImage = customMealImage;

  constructor(
    private readonly mealSearchService: MealSearchService,
    private readonly toastService: ToastService,
    private readonly navController: NavController,
    private readonly planFeatureService: PlanFeatureService
  ) {}

  public async onMealClick(meal: SearchedCustomMeal): Promise<void> {
    await this.navController.navigateForward('/add-food', {
      state: {
        meal,
        actions: this.planFeatureService.getActions(this.config.date, this.config.action),
        config: this.config
      }
    });
  }
}
