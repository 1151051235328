import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {ActionError} from "@fitup-monorepo/core/lib/model/common-types";

export const recipeFilterActions = createActionGroup({
  source: 'Recipe Filter',
  events: {
    ['Set Selected Filters']: props<{ selectedFilters: string[] }>(),
    ['Set All Filters']: emptyProps(),
    ['Set All Filters Success']: props<{ filters: string[] }>(),
    ['Set All Filters Failure']: props<ActionError>()
  }
});
