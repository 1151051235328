import { Permission, PermissionsService } from '../permissions/permissions.service';
import { Router } from '@angular/router';

export interface OnboardingStep {
  index: number;
  route: string;
  permission?: Permission;
  isVisible?: () => Promise<boolean>;
}

export interface WizardService {
  navigateToNextStep(): Promise<void>;

  getNextUrl(): Promise<string>;
}

export abstract class OnboardingWizardService implements WizardService {
  protected constructor(
    readonly router: Router,
    readonly permissionsService: PermissionsService
  ) {}

  abstract getSteps(): OnboardingStep[];

  public async navigateToNextStep(): Promise<void> {
    const nextUrl = await this.getNextUrl();
    await this.router.navigate([nextUrl]);
  }

  public async getNextUrl(): Promise<string> {
    const currentRoute = this.router.url;
    const currentStep = this.getSteps().find(o => currentRoute.includes(o.route));
    const nextSteps = this.getSteps().filter(o => o.index > currentStep.index);

    for (const step of nextSteps) {
      const isNextStepVisible = !('isVisible' in step) || (await step.isVisible());
      if (isNextStepVisible && (!step.permission || this.permissionsService.hasPermission(step.permission))) {
        return step.route;
      }
    }

    return '/tabs/home';
  }
}
