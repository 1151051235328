import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Avatar } from './model/avatar';
import { ImagePickerModalComponent } from './image-picker-modal.component';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';

@Directive({
  selector: '[appImagePicker]',
  standalone: true
})
export class ImagePickerDirective {
  @Input()
  public hiddenTab: 'photo' | 'avatar';

  @Input()
  public useFrontCamera = true;

  @Input()
  public modalCssClass: string[] = ['fu-modal-default'];

  @Output()
  public imageChange = new EventEmitter<string>();

  @Output()
  public imageDelete = new EventEmitter<void>();

  @Output()
  public avatarChange = new EventEmitter<Avatar>();

  constructor(
    private readonly translateService: TranslateService,
    private readonly modalCtrl: ModalController,
    private readonly toastService: ToastService
  ) {}

  @HostListener('click')
  public async onClick(): Promise<void> {
    try {
      const modal = await this.modalCtrl.create({
        component: ImagePickerModalComponent,
        componentProps: {
          hiddenTab: this.hiddenTab,
          useFrontCamera: this.useFrontCamera
        },
        canDismiss: true,
        cssClass: this.modalCssClass
      });
      await modal.present();
      const modalData = await modal.onDidDismiss();
      if (modalData.role === 'add_image' && modalData.data) {
        this.imageChange.emit(modalData.data);
      }
      if (modalData.role === 'delete_image') {
        this.imageDelete.emit();
      }
      if (modalData.role === 'add_avatar' && modalData.data) {
        this.avatarChange.emit(modalData.data);
      }
    } catch (error) {
      await this.#handleError();
    }
  }

  async #handleError(): Promise<void> {
    await this.toastService.showError(this.translateService.instant('PROFILE_PICTURE.IMAGE_ERROR'));
  }
}
