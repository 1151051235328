// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info__title {
  margin: 0 0 40px 0;
}
.info__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.info__content__item__icon-badge {
  display: flex;
  gap: 16px;
}
.info__content__item:nth-child(1), .info__content__item:nth-child(2) {
  border-bottom: 1px solid var(--fu-footer-info-border);
}
.info__content__item--bottom-border:nth-child(3), .info__content__item--bottom-border:nth-child(4) {
  border-bottom: 1px solid var(--fu-footer-info-border);
}
.info__content__item__title {
  display: flex;
  align-items: center;
}
.info__content__item__title .icon {
  font-size: 24px;
}
.info__content__item__title .icon-label {
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
}
.info__content__item .text {
  margin: 16px 0 30px 0;
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/footer-info/footer-info.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAGE;EACE,aAAA;EACA,qCAAA;EACA,cAAA;AADJ;AAIM;EACE,aAAA;EACA,SAAA;AAFR;AAKM;EACE,qDAAA;AAHR;AAOQ;EACE,qDAAA;AALV;AASM;EACE,aAAA;EACA,mBAAA;AAPR;AASQ;EACE,eAAA;AAPV;AAUQ;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AARV;AAYM;EACE,qBAAA;AAVR","sourcesContent":[".info {\n  &__title {\n    margin: 0 0 40px 0;\n  }\n\n  &__content {\n    display: grid;\n    grid-template-columns:repeat(2, 1fr);\n    grid-gap: 30px;\n\n    &__item {\n      &__icon-badge {\n        display: flex;\n        gap: 16px;\n      }\n\n      &:nth-child(1), &:nth-child(2) {\n        border-bottom: 1px solid var(--fu-footer-info-border);\n      }\n\n      &--bottom-border {\n        &:nth-child(3), &:nth-child(4) {\n          border-bottom: 1px solid var(--fu-footer-info-border);\n        }\n      }\n\n      &__title {\n        display: flex;\n        align-items: center;\n\n        .icon {\n          font-size: 24px;\n        }\n\n        .icon-label {\n          font-size: 16px;\n          line-height: 20px;\n          margin-left: 10px;\n        }\n      }\n\n      .text {\n        margin: 16px 0 30px 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
