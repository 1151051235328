// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.food-search {
  margin-bottom: 30px;
}

.item-container {
  display: flex;
  flex-direction: column;
}

.meal-item {
  padding-bottom: var(--fu-spacing-l);
}

.meal-item + .meal-item {
  border-top: 1px solid var(--fu-grey-1);
  padding: var(--fu-spacing-l) 0;
}`, "",{"version":3,"sources":["webpack://./libs/nutrition-planner/src/lib/components/meal-search/meal-search.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,mCAAA;AACF;;AAEA;EACE,sCAAA;EACA,8BAAA;AACF","sourcesContent":[".food-search {\n  margin-bottom: 30px;\n}\n\n.item-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.meal-item {\n  padding-bottom: var(--fu-spacing-l);\n}\n\n.meal-item + .meal-item {\n  border-top: 1px solid var(--fu-grey-1);\n  padding: var(--fu-spacing-l) 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
