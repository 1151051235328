// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  color: black;
}
.dashboard-container .skeleton-card {
  color: black;
  margin-right: 0;
  margin-left: 0;
  border-radius: 10px;
  border: none;
}
.dashboard-container .live-class-title {
  display: flex;
  align-items: center;
  gap: var(--fu-spacing-xs);
}
.dashboard-container .live-class-title .icon {
  font-size: 24px;
  color: var(--fu-green);
}`, "",{"version":3,"sources":["webpack://./libs/account/src/lib/dashboard/dashboard.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,YAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;AAAJ;AAEI;EACE,eAAA;EACA,sBAAA;AAAN","sourcesContent":[".dashboard-container {\n  color: black;\n\n  .skeleton-card {\n    color: black;\n    margin-right: 0;\n    margin-left: 0;\n    border-radius: 10px;\n    border: none;\n  }\n\n  .live-class-title {\n    display: flex;\n    align-items: center;\n    gap: var(--fu-spacing-xs);\n\n    .icon {\n      font-size: 24px;\n      color: var(--fu-green);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
