import { NavController } from '@ionic/angular';
import { environment } from '../environment';

export async function navigateNotificationClick(navController: NavController, link: string): Promise<void> {
  if (link && link.includes(`${environment.urlScheme}://`)) {
    const linkParts = link.split(`${environment.urlScheme}:/`);
    await navController.navigateForward(linkParts[1]);
  } else {
    await navController.navigateForward('/tabs/notifications');
  }
}
