import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ProfileState } from './profile.reducer';
import { Customer } from '../../model/customer';
import { CustomerMembership, SubscriptionStatus } from '../../model/customer-membership';
import { TeamCustomer } from '../../model/team-customer';
import { PromotionCode } from '../../model/promotion-code';
import { Partner } from '../../model/partner';
import { AuthProvider } from '../../model/acount/account.model';
import { hasMembership, hasTrialPeriod } from '../../util/subscription.util';

export const selectProfileFeature = createFeatureSelector<ProfileState>('profile');

export const selectCustomer: MemoizedSelector<ProfileState, Customer | undefined> = createSelector(
  selectProfileFeature,
  (state: ProfileState) => state.customer
);
export const selectMembership: MemoizedSelector<ProfileState, CustomerMembership | undefined | null> = createSelector(
  selectProfileFeature,
  state => state?.currentMembership
);

export const selectIsPremiumUser: MemoizedSelector<ProfileState, boolean> = createSelector(
  selectProfileFeature,
  state => state?.currentMembership?.status === SubscriptionStatus.active || !!state.customer?.promotionCode
);

export const selectTeamCustomer: MemoizedSelector<ProfileState, TeamCustomer | undefined | null> = createSelector(
  selectProfileFeature,
  state => state?.teamCustomer
);

export const selectTeamName: MemoizedSelector<ProfileState, string | undefined> = createSelector(
  selectTeamCustomer,
  teamCustomer => teamCustomer?.team?.title
);

export const selectExpiredTrial: MemoizedSelector<ProfileState, boolean | undefined> = createSelector(
  selectProfileFeature,
  state =>
    !state.customer ? undefined : state.customer.freeTrialUsed && !state.currentMembership && !state.promotionCode
);

export const selectManageSubscriptionUrl: MemoizedSelector<ProfileState, string | undefined> = createSelector(
  selectMembership,
  membership => membership?.manageSubscriptionUrl
);
export const selectCustomerUserId: MemoizedSelector<ProfileState, number | undefined> = createSelector(
  selectCustomer,
  customer => customer?.userId
);

export const selectCustomerUUID: MemoizedSelector<ProfileState, string | undefined> = createSelector(
  selectCustomer,
  customer => customer?.uuid
);

export const selectCustomerId: MemoizedSelector<ProfileState, number | undefined> = createSelector(
  selectCustomer,
  customer => customer?.id
);

export const selectCustomerNickname: MemoizedSelector<ProfileState, string | undefined> = createSelector(
  selectCustomer,
  customer => customer?.nickname
);

export const selectPromotionCode: MemoizedSelector<ProfileState, PromotionCode | null | undefined> = createSelector(
  selectProfileFeature,
  (state: ProfileState) => state?.promotionCode
);

export const selectSubscriptionInfo = createSelector(
  selectPromotionCode,
  selectMembership,
  selectTeamCustomer,
  (promotionCode, currentMembership, teamCustomer) => ({
    promotionCode,
    currentMembership,
    teamCustomer
  })
);

export const selectPartner: MemoizedSelector<ProfileState, Partner | undefined> = createSelector(
  selectPromotionCode,
  promotionCode => promotionCode?.partner
);

export const selectIncludeManualActivities: MemoizedSelector<ProfileState, boolean> = createSelector(
  selectPartner,
  partner => !!partner?.includeManualActivities
);

export const selectShowManualActivityNote: MemoizedSelector<ProfileState, boolean> = createSelector(
  selectIncludeManualActivities,
  includeManualActivities => !includeManualActivities
);

export const selectIsFitupUser: MemoizedSelector<ProfileState, boolean> = createSelector(
  selectCustomer,
  customer => customer?.email.includes('fit-up.com')
);

export const selectProfilePicture: MemoizedSelector<ProfileState, string | undefined> = createSelector(
  selectCustomer,
  (customer: Customer) => customer?.image
);

export const selectFirstName: MemoizedSelector<ProfileState, string | undefined> = createSelector(
  selectCustomer,
  (customer: Customer) => customer?.firstName
);

export const selectCustomerWeight: MemoizedSelector<ProfileState, number | undefined> = createSelector(
  selectCustomer,
  (customer: Customer) => customer?.weight
);

export const selectIsPrivateCustomerInfo: MemoizedSelector<ProfileState, boolean | undefined> = createSelector(
  selectProfileFeature,
  state =>
    !state.customer ? undefined : !state.customer.partnerId || (state.promotionCode && !state.promotionCode.partner)
);

export const selectPinboardConfig: MemoizedSelector<ProfileState, { uuid?: string; pinboardId?: string }> =
  createSelector(selectCustomerUUID, selectPartner, (uuid, partner) => ({
    uuid,
    pinboardId: partner?.pinboardId
  }));

export const selectPartnerLogo: MemoizedSelector<ProfileState, string> = createSelector(
  selectPromotionCode,
  promotionCode => promotionCode?.partner.logoUrl
);

export const selectAuthProvider: MemoizedSelector<ProfileState, AuthProvider> = createSelector(
  selectCustomer,
  customer => customer?.authProvider
);

export const selectHasSubscription: MemoizedSelector<ProfileState, boolean> = createSelector(
  selectMembership,
  selectPromotionCode,
  (membership, promoCode) => {
    return hasMembership(membership) || hasTrialPeriod(membership) || !!promoCode;
  }
);

export const selectIsEligibleForFreeTrial: MemoizedSelector<ProfileState, boolean> = createSelector(
  selectProfileFeature,
  state => state.isEligibleForFreeTrial
);
