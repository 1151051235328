import { Inject, Injectable, OnDestroy } from '@angular/core';
import { PushNotificationSchema, PushNotificationsPlugin, Token } from '@capacitor/push-notifications';
import { NavController, Platform } from '@ionic/angular';
import { executeWithErrorHandling, isErrorResult } from '@fitup-monorepo/core/lib/util/rxjs-util';
import { DevicePlugin } from '@capacitor/device';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { AndroidSettings, IOSSettings, NativeSettingsPlugin } from 'capacitor-native-settings';
import { NotificationPreferencesService } from './notification-preferences.service';
import { WebPushNotificationsService } from './web-push-notifications.service';
import { RegisterDeviceService } from './register-device.service';
import { navigateNotificationClick } from '@fitup-monorepo/core/lib/util/push-notifications.util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DEVICE, NATIVE_SETTINGS, PUSH_NOTIFICATIONS } from '@fitup-monorepo/core/lib/capacitor-injection-tokens';
import { LocalStorageService } from 'ngx-webstorage';

export const firebaseNotificationTokenKey = 'firebaseToken';

export interface DeviceRegistration {
  token: string;
  deviceId: string;
  platform: string;
  model: string;
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(PUSH_NOTIFICATIONS) private readonly pushNotifications: PushNotificationsPlugin,
    @Inject(NATIVE_SETTINGS) private readonly nativeSettings: NativeSettingsPlugin,
    @Inject(DEVICE) private readonly device: DevicePlugin,
    private readonly platform: Platform,
    private readonly toastService: ToastService,
    private readonly navController: NavController,
    private readonly notificationPreferencesService: NotificationPreferencesService,
    private readonly webPushNotificationsService: WebPushNotificationsService,
    private readonly registerDeviceService: RegisterDeviceService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async hasNotificationPreferences(): Promise<boolean> {
    const preferences = await executeWithErrorHandling({
      observable$: this.notificationPreferencesService.getNotificationPreferences()
    });
    return !isErrorResult(preferences) && !!preferences.length;
  }

  public async init(): Promise<void> {
    if (this.platform.is('cordova')) {
      const hasPreferences = await this.hasNotificationPreferences();
      await this.registerListeners();
      try {
        if (hasPreferences) {
          const permitted = await this.requestPermissions();
          if (permitted) {
            await this.register();
          }
        }

        await this.pushNotifications.removeAllDeliveredNotifications();
      } catch (e) {
        console.error('Error requesting permissions', JSON.stringify(e));
      }
    } else {
      await this.webPushNotificationsService.registerFirebaseListeners();
    }
  }

  public async register(): Promise<void> {
    await this.pushNotifications.register();
  }

  private async registerListeners(): Promise<void> {
    this.platform.resume.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.pushNotifications.removeAllDeliveredNotifications();
    });

    this.pushNotifications.addListener('registration', token => {
      this.handleRegistrationSuccess(token);
    });

    this.pushNotifications.addListener('registrationError', error => {
      console.error(`Error on registration: ${JSON.stringify(error)}`);
    });

    this.pushNotifications.addListener('pushNotificationReceived', notification => {
      this.handlePushNotificationReceived(notification);
    });

    this.pushNotifications.addListener('pushNotificationActionPerformed', action => {
      console.log(`Push action performed: ${JSON.stringify(action)}`);
      this.handleNotificationClick(action.notification);
    });
  }

  private async handlePushNotificationReceived(notification: PushNotificationSchema): Promise<void> {
    console.log(`Push received: ${JSON.stringify(notification)}`);
    await this.toastService.showToast({
      message: notification.body,
      primaryButtonLabel: 'OPEN',
      primaryAction: async (): Promise<void> => await this.handleNotificationClick(notification)
    });
  }

  private async handleNotificationClick(notification: PushNotificationSchema): Promise<void> {
    const link: string = notification.link ?? notification.data.link;
    await navigateNotificationClick(this.navController, link);
  }

  private async handleRegistrationSuccess(token: Token): Promise<void> {
    console.log(`Push registration success, token: ${JSON.stringify(token)}`);
    const deviceInfo = await this.device.getInfo();
    const deviceId = await this.device.getId();

    this.localStorageService.store(firebaseNotificationTokenKey, token.value);

    await executeWithErrorHandling({
      observable$: this.registerDeviceService.registerDevice({
        token: token.value,
        deviceId: deviceId.identifier,
        platform: deviceInfo.platform,
        model: deviceInfo.model
      })
    });
  }

  public async hasPermissions(): Promise<boolean> {
    try {
      if (this.platform.is('cordova')) {
        const result = await this.pushNotifications.checkPermissions();
        return result.receive === 'granted';
      }
      return false;
    } catch (e) {
      console.error('Error checking permissions', JSON.stringify(e));
      return false;
    }
  }

  public async requestPermissions(): Promise<boolean> {
    try {
      if (this.platform.is('cordova')) {
        const result = await this.pushNotifications.requestPermissions();
        return result.receive === 'granted';
      }
      return false;
    } catch (e) {
      console.error('Error requesting permissions', JSON.stringify(e));
      return false;
    }
  }

  public async enable(): Promise<boolean> {
    const hasPermissions = await this.requestPermissions();
    if (!hasPermissions) {
      await this.nativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App
      });
      return this.hasPermissions();
    }
    return true;
  }
}
