import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ModalService } from '@fitup-monorepo/components/lib/confirmation-modal/confirmation-modal.service';
import { PlanMutationService } from './plan-mutation.service';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { ConfirmMealSwapPage, MealWithIndex, PreferredMeal } from './confirm-meal-swap/confirm-meal-swap.page';
import { ModalController, NavController } from '@ionic/angular';
import { isAfterToday } from '@fitup-monorepo/core/lib/util/date.util';
import { MealSearchAction, MealSearchConfig } from './components/meal-search/meal-search.component';
import { MealStatusRequest } from './model/plan-request';
import { CustomMealAction } from './model/custom-meal';

@Injectable({
  providedIn: 'root'
})
export class PlanFeatureService {
  constructor(
    private readonly modalService: ModalService,
    private readonly modalController: ModalController,
    private readonly planMutationService: PlanMutationService,
    private readonly toastService: ToastService,
    private readonly navController: NavController
  ) {}

  public async swapMeals(
    currentMeal: MealWithIndex,
    preferredMeal: PreferredMeal,
    mealStatus?: MealStatusRequest
  ): Promise<boolean> {
    const modal = await this.modalController.create({
      component: ConfirmMealSwapPage,
      componentProps: {
        meals: {
          currentMeal,
          preferredMeal,
          mealStatus
        }
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss<boolean>();
    return data;
  }

  public navigateToCreate(config: MealSearchConfig): void {
    this.navController.navigateForward('/create-custom-meal', {
      state: {
        actions: this.getActions(config.date, config.action),
        config
      }
    });
  }

  public getActions(date: Date, action: MealSearchAction): CustomMealAction[] {
    const dateIsAfterToday = isAfterToday(date);
    return [
      action === MealSearchAction.swap ? CustomMealAction.swap : CustomMealAction.add,
      ...(!dateIsAfterToday ? [CustomMealAction.track] : [])
    ];
  }

  public async endPlan(planId: string): Promise<void> {
    const confirm = await this.modalService.showConfirmation({
      title: 'NUTRITION.PLAN_PROGRESS.MODAL.TITLE',
      description: 'NUTRITION.PLAN_PROGRESS.MODAL.DESCRIPTION',
      confirmButtonLabel: 'NUTRITION.PLAN_PROGRESS.MODAL.CANCEL',
      cancelButtonLabel: 'NUTRITION.PLAN_PROGRESS.MODAL.CONFIRM'
    });

    if (!confirm) {
      await this.#endPlan(planId);
    } else {
      return Promise.reject();
    }
  }

  async #endPlan(planId: string): Promise<void> {
    try {
      await firstValueFrom(this.planMutationService.endPlan(planId));
    } catch (e) {
      await this.toastService.showError('NUTRITION.PLAN_PROGRESS.ERROR_MESSAGE');
      return Promise.reject();
    }
  }
}
