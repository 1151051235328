import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProfileInformation } from '../../model/profile-information';
import { ActionError } from '../../model/common-types';
import { CustomerUpdateRequest } from '../../model/customer-request';
import { CustomerMembership } from '../../model/customer-membership';

export const profileApiActions = createActionGroup({
  source: 'Profile API',
  events: {
    ['Load Profile']: emptyProps(),
    ['Load Profile Success']: props<{ profile: ProfileInformation }>(),
    ['Load Profile Failure']: props<ActionError>(),
    ['Reset Profile']: emptyProps(),
    ['Update Customer']: props<{ request: CustomerUpdateRequest }>(),
    ['Update Customer Success']: emptyProps(),
    ['Update Customer Failure']: props<ActionError>(),
    ['Upload Image']: props<{ image: string }>(),
    ['Upload Image Success']: props<{ image: string }>(),
    ['Upload Image Failure']: props<ActionError>(),
    ['Set Avatar']: props<{ avatarId: number }>(),
    ['Set Avatar Success']: emptyProps(),
    ['Set Avatar Failure']: props<ActionError>(),
    ['Delete Image']: emptyProps(),
    ['Delete Image Success']: emptyProps(),
    ['Delete Image Failure']: props<ActionError>(),
    ['Update Membership']: props<{ membership: CustomerMembership }>()
  }
});
