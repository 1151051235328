import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EarnedBadge } from '@fitup-monorepo/core/lib/model/badge';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';

export const badgeActions = createActionGroup({
  source: 'Badge',
  events: {
    ['Load Earned Badges']: emptyProps(),
    ['Load Earned Badges Success']: props<{ badges: EarnedBadge[] }>(),
    ['Load Earned Badges Failure']: props<ActionError>()
  }
});
