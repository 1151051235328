export interface Environment {
  production: boolean;
  forceNativePlatform: boolean;
  apiHost: string;
  apiUrl: string;
  managementApiUrl: string;
  appUrl: string;
  bugfender: {
    appKey: string | undefined;
  };
  googleAnalytics: {
    trackerId: string;
  };
  firebase: {
    databaseURL: string;
    prefix: string;
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    vapidKey: string;
  };
  revenueCat: {
    apple: string;
    google: string;
  };
  tinymce: {
    apiKey: string;
  };
  isCustomApp: boolean;
  urlScheme: string;
  appName: string;
  preferredThemes: {
    welcomePage: string;
    registerPage: string;
  };
}
export function initializeEnvironment(e: Environment): void {
  environment = e;
}

export var environment: Environment = {
  production: false,
  forceNativePlatform: false, //true: see all stuff like on a mobile phone, false: see stuff like it should look on desktop
  apiHost: 'https://api.fit-up.com',
  apiUrl: 'https://api.fit-up.com/api',
  managementApiUrl: 'https://api.fit-up.com',
  appUrl: 'https://app.dev.fit-up.com',
  bugfender: {
    appKey: undefined
  },
  revenueCat: {
    google: 'goog_RbmuyCraUGVbCDTnTQoRvJIisGc',
    apple: 'appl_VdqPsoJkKlLXKcikxfmDQesHqrh'
  },
  googleAnalytics: {
    trackerId: 'UA-154130837-1'
  },
  firebase: {
    databaseURL: 'https://catapult-268006.firebaseio.com',
    prefix: '',
    apiKey: 'AIzaSyCtgzvGo3Ro_j5rfkH6TjxYsDy-irLnfTg',
    authDomain: 'catapult-268006.firebaseapp.com',
    projectId: 'catapult-268006',
    storageBucket: 'catapult-268006.appspot.com',
    messagingSenderId: '614321696120',
    appId: '1:614321696120:web:580c87af788c0009f06c8d',
    vapidKey: 'BCACQuZuH7IP9UM0ya7wmkdTcLCdEPigc3RYvhaLp0efniSLQjEsxLiyE1-J1dFFdwNVkmhTtAHdLx_Q0lbtWuo'
  },
  tinymce: {
    apiKey: 'fafjc1ivko5xzaoe5voi7v2kfgb84ebpxu66tmx2cm0w5gnq'
  },
  isCustomApp: false,
  urlScheme: 'fitup',
  appName: 'fitup',
  preferredThemes: {
    welcomePage: 'fu-theme fu-theme--dark-moveathlon',
    registerPage: 'fu-theme fu-theme--dark-moveathlon'
  }
};
