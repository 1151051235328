import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  @Input() yData: number[];
  @Input() xData: number[];
  @Input() title: string;

  chartLabels: any[] = [];
  gradient: any;
  chartColors: [{ borderColor: string; backgroundColor: string }];

  public chartType = 'line';
  public chartLegend = false;
  chartDataSets = [];

  public chartData: ChartConfiguration<'line'>['data'] = {
    datasets: [{ data: this.chartDataSets }]
  };

  public chartOptions: ChartConfiguration<'line'>['options'] = {
    responsive: true,
    animation: false,
    elements: {
      line: {
        tension: 0,
        borderWidth: 1,
        fill: true,
        borderColor: '#45cbd5',
        backgroundColor: '#45cbd566',
        hoverBackgroundColor: '#45cbd566'
      },
      point: {
        radius: 0,
        borderColor: '#45cbd5',
        backgroundColor: '#45cbd5',
        hoverBackgroundColor: '#45cbd566',
        hoverBorderColor: '#45cbd566'
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          padding: 5,
          crossAlign: 'far',
          maxTicksLimit: 6
        }
      },
      x: {
        // offset: true,
        ticks: {
          maxTicksLimit: 8,
          align: 'end'
        }
      }
    }
  };

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    if (this.yData && this.yData.length > 0) {
      this.setChartOptions();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['yData'] &&
      changes['yData'].currentValue &&
      changes['yData'].previousValue !== changes['yData'].currentValue
    ) {
      this.yData = changes['yData'].currentValue;
      if (!this.chartDataSets) {
        this.setChartOptions();
      }
    }
  }

  setChartOptions() {
    // window size for moving average must be an odd number!
    const moveMean = this.yData.length > 100 ? this.movingAverage(13) : this.movingAverage(1);
    this.chartDataSets.push({
      data: moveMean,
      label:
        this.title === 'hr'
          ? this.translateService.instant('ACTIVITY.ACTIVITY_DETAILS.HR')
          : this.translateService.instant('ACTIVITY.ACTIVITY_DETAILS.ALTITUDE'),
      backgroundColor: this.chartColors,
      pointBackgroundColor: '#45cbd5',
      pointBorderColor: '#45cbd5',
      pointHoverBorderColor: '#45cbd5',
      pointHoverBackgroundColor: '#45cbd566'
    });
    // const tmpSeconds = this.xData;
    // const timeFactor = this.title === 'hr' ? 1000 : 10000;
    this.chartLabels = this.xData;
    // for (let i = 0; i < tmpSeconds.length; i++) {
    //   this.chartLabels[i] = new Date(tmpSeconds[i] * timeFactor).toISOString().substring(14, 19);
    // }
  }

  movingAverage(windowSize: number) {
    const moveMean = [];
    for (let i = (windowSize - 1) / 2; i < this.yData.length - (windowSize - 1) / 2; i++) {
      const mean =
        this.yData
          .filter((value, index) => index >= i - (windowSize - 1) / 2 && index <= i + (windowSize - 1) / 2)
          .reduce((sum, current) => sum + current) / windowSize;
      this.title === 'hr' ? moveMean.push(Math.round(mean)) : moveMean.push(Math.round(mean * 100) / 100);
    }
    return moveMean;
  }

  ngAfterViewInit(): void {
    const canvas = document.getElementById('chart') as HTMLCanvasElement;
    if (canvas) {
      this.chartColors = [
        {
          borderColor: '#45cbd5',
          backgroundColor: '#45cbd566'
        }
      ];
    }
  }
}
