import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BannerTheme } from '@fitup-monorepo/components/lib/banner/banner.component';
import { getBorderColorByCategory } from '@fitup-monorepo/components/lib/live-class-card-item/util/live-class-card-item.util';
import { Session, SessionWithRating } from '@fitup-monorepo/core/lib/entities/session.model';
import { Customer } from '@fitup-monorepo/core/lib/model/customer';
import { CustomerMembership } from '@fitup-monorepo/core/lib/model/customer-membership';
import { PromotionCode } from '@fitup-monorepo/core/lib/model/promotion-code';
import { CustomerSelectService } from '@fitup-monorepo/core/lib/services/customer-select/customer-select.service';
import { isAppFitup } from '@fitup-monorepo/core/lib/util/app-checker.util';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, OnChanges {
  @Input()
  public futureSessions: SessionWithRating[] = [];

  @Input()
  public customer: Customer = null;

  @Input()
  public mostRecentSessionsIds: number[] = [];

  public readonly getBorderColorByCategory = getBorderColorByCategory;
  public readonly bannerTheme = BannerTheme;

  public customerMembership: CustomerMembership;
  public customerPromotionCode: PromotionCode;

  protected readonly isAppFitup = isAppFitup();

  constructor(
    private readonly customerSelectService: CustomerSelectService,
    private readonly navController: NavController
  ) {}

  public async ngOnInit(): Promise<void> {
    if (this.customer) {
      this.customerPromotionCode = await this.customerSelectService.selectPromotionCode();
      this.customerMembership = await this.customerSelectService.selectMembership();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.futureSessions && changes.futureSessions.currentValue.length > 0) {
        this.futureSessions = [];
        this.futureSessions = changes.futureSessions.currentValue;
      }

      if (changes.customer) {
        this.ngOnInit();
      }
    }
  }

  protected navigateToSession(session: Session): void {
    const queryParams = {
      session: JSON.stringify(session),
      hasJoined: true
    };
    this.navController.navigateForward('tabs/live-classes/detail/' + session.id + '/view', { queryParams });
  }
}
