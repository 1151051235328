import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getTrialEndDate, trialDays } from '../../subscription.utils';

import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { LocalizedDatePipe } from '@fitup-monorepo/core/lib/pipes/localized-date.pipe';

export interface ListItem {
  title: string;
  text: string;
  icon: string;
  state: 'done' | 'active' | 'upcoming';
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, IonicModule, LocalizedDatePipe]
})
export class ListComponent {
  @Input()
  public lisItems: ListItem[] | undefined;

  protected readonly trialDays = trialDays;
  protected readonly getTrialEndDate = getTrialEndDate;
}
