// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  --background: var(--fu-color-primary);
}
.button--blue {
  --background: var(--fu-blue);
  color: var(--fu-body-color);
}`, "",{"version":3,"sources":["webpack://./libs/subscription/src/lib/subscription-plan/components/start-subscription-button/start-subscription-button.component.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;AACF;AACE;EACE,4BAAA;EACA,2BAAA;AACJ","sourcesContent":[".button{\n  --background: var(--fu-color-primary);\n\n  &--blue{\n    --background: var(--fu-blue);\n    color: var(--fu-body-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
