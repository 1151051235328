import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { challengeActions } from './challenge.actions';
import { Challenge, TeamMetrics } from '../model';

export interface ChallengeState extends EntityState<Challenge> {
  loading: boolean;
  error?: string;
  dataAvailableFrom: string;
  invitedChallengeId?: number;
  communityActiveChallenges: number[];
  partnerActiveChallenges: number[];
  communityUpcomingChallenges: number[];
  communityPastChallenges: number[];
  partnerPastChallenges: number[];
  partnerUpcomingChallenges: number[];
  joinedChallenges: number[];
  ownTeam?: TeamMetrics;
}

export const challengeAdapter: EntityAdapter<Challenge> = createEntityAdapter();

const initialState: ChallengeState = challengeAdapter.getInitialState({
  loading: false,
  communityActiveChallenges: [],
  partnerActiveChallenges: [],
  communityUpcomingChallenges: [],
  partnerUpcomingChallenges: [],
  joinedChallenges: [],
  partnerPastChallenges: [],
  communityPastChallenges: [],
  dataAvailableFrom: new Date().toISOString()
});

export const challengeReducer = createReducer(
  initialState,
  on(
    challengeActions.loadChallenge,
    challengeActions.joinChallenge,
    challengeActions.leaveChallenge,
    (state): ChallengeState => ({ ...state, loading: true })
  ),
  on(
    challengeActions.joinChallengeSuccess,
    challengeActions.loadChallengeSuccess,
    challengeActions.leaveChallengeSuccess,
    (state, { challenge }) => challengeAdapter.upsertOne(challenge, { ...state, loading: false })
  ),
  on(challengeActions.markCompletedChallengesShownSuccess, (state, { challenges }) =>
    challengeAdapter.upsertMany(challenges, { ...state, loading: false })
  ),
  on(
    challengeActions.addInvitedChallengeId,
    (state, { challengeId }): ChallengeState => ({
      ...state,
      invitedChallengeId: challengeId
    })
  ),
  on(
    challengeActions.loadCommunityOverviewSuccess,
    (state, { overview }): ChallengeState =>
      challengeAdapter.upsertMany(
        [
          ...overview.upcomingChallenges,
          ...overview.activeChallenges,
          ...overview.joinedChallenges,
          ...overview.pastChallenges
        ],
        {
          ...state,
          joinedChallenges: overview.joinedChallenges.map(c => c.id),
          communityActiveChallenges: overview.activeChallenges.map(c => c.id),
          communityUpcomingChallenges: overview.upcomingChallenges.map(c => c.id),
          communityPastChallenges: overview.pastChallenges.map(c => c.id)
        }
      )
  ),
  on(
    challengeActions.loadPartnerOverviewSuccess,
    (state, { overview }): ChallengeState =>
      challengeAdapter.upsertMany(
        [...overview.activeChallenges, ...overview.upcomingChallenges, ...overview.pastChallenges],
        {
          ...state,
          ownTeam: overview.ownTeam,
          partnerActiveChallenges: overview.activeChallenges.map(c => c.id),
          partnerUpcomingChallenges: overview.upcomingChallenges.map(c => c.id),
          partnerPastChallenges: overview.pastChallenges.map(c => c.id)
        }
      )
  )
);
