import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { environment } from '../../environment';
import { selectCustomer } from '../../state/profile/profile.selector';

@Injectable({
  providedIn: 'root'
})
export class CustomAppService {
  constructor(
    private readonly store: Store,
    private readonly navController: NavController
  ) {}

  /**
   * Check if the promotion code is set for the customer.
   * @returns true if it's a custom app, the promotion code is not set, and we navigated to enter the promotion code, else false.
   */
  public async isCustomAppAndPromotionCodeMustBeEntered(): Promise<boolean> {
    if (environment.isCustomApp) {
      const customer = await firstValueFrom(this.store.select(selectCustomer).pipe(filter(customer => !!customer)));
      if (!customer.promotionCode || customer.promotionCode?.length === 0) {
        await this.navController.navigateForward('/promo-code/onboard');
        return true;
      }
    }
    return false;
  }
}
