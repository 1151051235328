import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedDatePipe } from './localized-date.pipe';

@Pipe({
  name: 'timeAgo',
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly localizedDate: LocalizedDatePipe
  ) {}

  public transform(value: Date | string): string {
    const now = new Date();
    const date = value instanceof Date ? value : new Date(value);
    const diffMs = Math.abs(now.getTime() - date.getTime());
    const diffMins = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffWeeks = Math.floor(diffDays / 7);

    if (diffMins < 1) {
      return this.translateService.instant('TIME_AGO_PIPE.JUST_NOW');
    } else if (diffMins < 60) {
      return this.translateService.instant(diffMins === 1 ? 'TIME_AGO_PIPE.MINUTE_AGO' : 'TIME_AGO_PIPE.MINUTES_AGO', {
        minutes: diffMins,
      });
    } else if (diffHours < 24) {
      return this.translateService.instant(diffHours === 1 ? 'TIME_AGO_PIPE.HOUR_AGO' : 'TIME_AGO_PIPE.HOURS_AGO', {
        hours: diffHours,
      });
    } else if (diffWeeks >= 1) {
      return this.translateService.instant(diffWeeks === 1 ? 'TIME_AGO_PIPE.WEEK_AGO' : 'TIME_AGO_PIPE.WEEKS_AGO', {
        weeks: diffWeeks,
      });
    } else if (diffDays >= 1) {
      return this.translateService.instant(diffDays === 1 ? 'TIME_AGO_PIPE.DAY_AGO' : 'TIME_AGO_PIPE.DAYS_AGO', {
        days: diffDays,
      });
    } else {
      return this.localizedDate.transform(value, 'longDateTime');
    }
  }
}
