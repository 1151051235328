import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DailyTasksOverview } from '../model/daily-task';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';

export const dailyTasksActions = createActionGroup({
  source: 'Daily Tasks',
  events: {
    ['Load Daily Tasks']: emptyProps(),
    ['Load Daily Tasks Success']: props<DailyTasksOverview>(),
    ['Load Daily Tasks Failure']: props<ActionError>()
  }
});
