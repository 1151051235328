export interface Unit {
  id: string;
  name: string;
  amount: number;
}

export enum CustomMealAction {
  track = 'track',
  swap = 'swap',
  add = 'add'
}

export interface CustomMealResponse {
  id: number;
  name: string;
  referenceAmount: number;
  referenceUnit: ReferenceUnit;
  kCal: number;
  fat: number;
  carbs: number;
  protein: number;
  verified: boolean;
  units: Unit[];
  imageUrl?: string;
}

export enum ReferenceUnit {
  grams = 'g',
  milliliter = 'ml'
}

export type CustomAddedMeal = Pick<
  SearchedCustomMeal,
  | 'title'
  | 'caloriesAmount'
  | 'carbsInGrams'
  | 'fatsInGrams'
  | 'proteinsInGrams'
  | 'portionsAmount'
  | 'defaultUnitCaloriesAmount'
  | 'id'
  | 'defaultUnit'
> & {
  image: string;
  servingSize: number;
  servingUnit: ReferenceUnit;
};
export interface SearchedCustomMeal {
  id: number;
  title: string;
  verified: boolean;
  referenceUnit: ReferenceUnit;
  referenceAmount: number;
  defaultUnit: Unit;
  defaultUnitCaloriesAmount: number;
  caloriesAmount: number;
  portionsAmount: number;
  carbsInGrams: number;
  fatsInGrams: number;
  proteinsInGrams: number;
  imageUrl?: string;
  image?: string;
}
