import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PercentPipe } from '@angular/common';
import { LocalizedNumberPipe } from '@fitup-monorepo/core/lib/pipes/localized-number.pipe';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, TranslateModule, PercentPipe, LocalizedNumberPipe]
})
export class ProgressBarComponent {
  @Input()
  public title: string;

  @Input()
  public value: number;

  @Input()
  public completedAmount: number;

  @Input()
  public totalAmount: number;

  @Input()
  public label: string;
}
