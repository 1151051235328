import { LocalStorageService } from 'ngx-webstorage';
import { Permission, PermissionsService } from '@fitup-monorepo/core/lib/services/permissions/permissions.service';
import { environment } from '@fitup-monorepo/core/lib/environment';
import { hasSelectableTeam, showTrialPage } from '@fitup-monorepo/core/lib/util/wizard.util';
import { firstValueFrom } from 'rxjs';
import { selectTeamCustomer } from '@fitup-monorepo/core/lib/state/profile/profile.selector';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { TeamSelectionService } from '@fitup-monorepo/core/lib/services/team-selection/team-selection.service';
import {
  PromoCodeApplyService,
  showPromocodeOnboardingPage
} from '@fitup-monorepo/onboarding/lib/promo-code/promo-code-apply.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OnboardingStep, OnboardingWizardService } from '@fitup-monorepo/core/lib/services/wizard/wizard.service';
import { PlatformService } from '@fitup-monorepo/core/lib/services/platform/platform.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FitupOnboardingWizardService extends OnboardingWizardService {
  private readonly onboardingFlow: OnboardingStep[] = [
    {
      index: 0,
      route: '/start-onboard'
    },
    {
      index: 1,
      route: '/user-options-onboarding',
      isVisible: () => Promise.resolve(!(environment.isCustomApp || !this.platformService.isNative()))
    },
    {
      index: 2,
      route: '/promo-code/onboard',
      isVisible: () => showPromocodeOnboardingPage(this.store, this.localStorageService, this.promoCodeApplyService)
    },
    {
      index: 2,
      route: '/subscription-free-trial',
      isVisible: () => showTrialPage(this.store)
    },
    {
      index: 3,
      route: '/account-details-editor'
    },
    {
      index: 4,
      route: '/gender-and-birthdate',
      permission: Permission.basic
    },
    {
      index: 5,
      route: '/measurements',
      permission: Permission.basic
    },
    {
      index: 6,
      route: '/fitness-goals',
      permission: Permission.basic
    },
    {
      index: 7,
      route: '/fitness-level',
      permission: Permission.basic
    },
    {
      index: 8,
      route: '/interests',
      permission: Permission.basic
    },
    {
      index: 9,
      route: '/edit-profile-picture'
    },
    {
      index: 10,
      route: '/team-selection/onboard/true',
      permission: Permission.teamChallenge,
      isVisible: () => hasSelectableTeam(this.teamSelectionService, this.toastService)
    },
    {
      index: 11,
      route: '/congratulations',
      permission: Permission.teamChallenge,
      isVisible: async (): Promise<boolean> => {
        const teamCustomer = await firstValueFrom(this.store.select(selectTeamCustomer));
        return !!teamCustomer?.team;
      }
    },
    {
      index: 12,
      route: '/reminder',
      permission: Permission.fitAtWorkReminder
    },
    {
      index: 13,
      route: '/finalize-onboard',
      permission: Permission.basic
    },
    {
      index: 14,
      route: '/success-onboard'
    }
  ];

  constructor(
    private readonly platformService: PlatformService,
    private readonly localStorageService: LocalStorageService,
    private readonly toastService: ToastService,
    private readonly teamSelectionService: TeamSelectionService,
    readonly permissionsService: PermissionsService,
    private readonly promoCodeApplyService: PromoCodeApplyService,
    readonly router: Router,
    private readonly store: Store
  ) {
    super(router, permissionsService);
  }

  public getSteps(): OnboardingStep[] {
    return this.onboardingFlow;
  }
}
