import { Component, Input } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationModalConfiguration } from './confirmation-modal.service';
import { GENERAL_ANIMATIONS } from '@fitup-monorepo/core/lib/app.animations';
import { BrowserService } from '@fitup-monorepo/core/lib/services/browser/browser.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  animations: [GENERAL_ANIMATIONS],
  imports: [IonicModule, TranslateModule],
  standalone: true
})
export class ConfirmationModalComponent {
  @Input({ required: true })
  public configuration!: ConfirmationModalConfiguration;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly browserService: BrowserService
  ) {}

  public cancel(): Promise<boolean> {
    return this.modalCtrl.dismiss(false, 'cancel');
  }

  public confirm(): Promise<boolean> {
    return this.modalCtrl.dismiss(true, 'confirm');
  }

  protected async openUrl(url: string): Promise<void> {
    await this.browserService.openUrl(url, true);
  }
}
