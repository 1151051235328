import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MiniChallenge } from '../model/mini-challenge';
import { createReducer, on } from '@ngrx/store';
import { miniChallengesActions } from './mini-challenges.actions';

export interface MiniChallengeState extends EntityState<MiniChallenge> {
  loading: boolean;
  error?: string;
}

export const miniChallengeAdapter: EntityAdapter<MiniChallenge> = createEntityAdapter();

const initialState: MiniChallengeState = miniChallengeAdapter.getInitialState({
  loading: false,
});

export const miniChallengeReducer = createReducer(
  initialState,
  on(
    miniChallengesActions.loadAllMiniChallenges,
    miniChallengesActions.joinMiniChallenge,
    miniChallengesActions.cancelMiniChallenge,

    (state): MiniChallengeState => ({ ...state, loading: true })
  ),
  on(miniChallengesActions.loadAllMiniChallengesSuccess, (state, { miniChallenges }) =>
    miniChallengeAdapter.upsertMany(miniChallenges, { ...state, loading: false })
  ),
  on(
    miniChallengesActions.joinMiniChallengeSuccess,
    (state, { miniChallenge }): MiniChallengeState =>
      miniChallengeAdapter.upsertOne(miniChallenge, { ...state, loading: false })
  ),
  on(
    miniChallengesActions.cancelMiniChallengeSuccess,
    (state, { miniChallenge }): MiniChallengeState =>
      miniChallengeAdapter.upsertOne(miniChallenge, { ...state, loading: false })
  )
);
