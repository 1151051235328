import { createReducer, on } from '@ngrx/store';
import { pinboardFilterActions } from './pinboard-filter.actions';

export interface PinboardFilterState {
  filter: string;
}

export const initialState: PinboardFilterState = {
  filter: '',
};

export const pinboardFilterReducer = createReducer(
  initialState,
  on(pinboardFilterActions.setFilter, (state, { filter }): PinboardFilterState => ({ ...state, filter }))
);
