import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Notification } from '../../model/notification/notification';
import { createReducer, on } from '@ngrx/store';
import { notificationsActions } from './notifications.actions';

export const defaultNotificationsPageSize = 25;

export interface NotificationsState extends EntityState<Notification> {
  loading: boolean;
  pageNum: number;
  lastPageSize: number;
}

export const notificationAdapter: EntityAdapter<Notification> = createEntityAdapter();

const initialState: NotificationsState = notificationAdapter.getInitialState({
  loading: false,
  pageNum: 0,
  lastPageSize: defaultNotificationsPageSize
});

export const notificationsReducer = createReducer(
  initialState,
  on(
    notificationsActions.loadNotifications,
    notificationsActions.markReadNotification,
    notificationsActions.markAllNotificationsAsRead,
    (state): NotificationsState => ({ ...state, loading: true })
  ),
  on(notificationsActions.loadNotificationsSuccess, (state, result) =>
    notificationAdapter.setAll(result.data, {
      ...state,
      pageNum: 0,
      lastPageSize: result.data.length,
      loading: false
    })
  ),
  on(notificationsActions.loadMoreNotificationsSuccess, (state, result) =>
    notificationAdapter.upsertMany(result.data, {
      ...state,
      pageNum: result.pageNum + 1,
      lastPageSize: result.data.length,
      loading: false
    })
  ),
  on(notificationsActions.markReadNotificationSuccess, (state, { notifications }) =>
    notificationAdapter.upsertMany(notifications, { ...state, loading: false })
  ),
  on(
    notificationsActions.markAllNotificationsAsReadSuccess,
    (state): NotificationsState =>
      notificationAdapter.upsertMany(
        Object.values(state.entities).map(e => ({ ...e, isRead: true })),
        { ...state, loading: false }
      )
  )
);
