import { ChangeDetectionStrategy, Component } from '@angular/core';
import { getTrialEndDate, trialDays } from '../../subscription.utils';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedDatePipe } from '@fitup-monorepo/core/lib/pipes/localized-date.pipe';

@Component({
  selector: 'app-info-label',
  templateUrl: './info-label.component.html',
  styleUrls: ['./info-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, LocalizedDatePipe]
})
export class InfoLabelComponent {
  protected readonly getTrialEndDate = getTrialEndDate;
  protected readonly trialDays = trialDays;
}
