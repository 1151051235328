import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslatableString } from './translatable-string';

@Injectable({
  providedIn: 'root',
})
export class TranslatableTextService {
  constructor(private readonly translateService: TranslateService) {}

  public translate(input: TranslatableString): string {
    if (input === undefined || input === null) {
      return undefined;
    }

    const candidates = [this.translateService.currentLang, this.translateService.defaultLang, 'en'];

    for (const lang of candidates) {
      if (input[lang] !== undefined && input[lang] !== null) {
        return input[lang];
      }
    }

    return undefined;
  }
}
