import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { navigateToDetail, recipeCardFooter } from '../util/nutrition-planner.util';
import { RecipeQueryService } from '../recipe-query.service';
import { CardComponent } from '@fitup-monorepo/components/lib/card/card.component';
import { TranslatableTextPipe } from '@fitup-monorepo/core/lib/pipes/translatable-text.pipe';
import { LoadingCardsComponent } from '@fitup-monorepo/components/lib/loading-cards/loading-cards.component';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { RecipeFilterComponent } from '../nutrition-plan/recipes/recipe-filter/recipe-filter.component';
import { Store } from '@ngrx/store';
import { selectRecipeSelectedFilters } from '../nutrition-plan/recipes/state/recipe-filter.selector';
import { filterRecipes } from '../nutrition-plan/recipes/recipes.component';
import { InfoBannerComponent } from '@fitup-monorepo/components/lib/info-banner/info-banner.component';
import { FbMealType, FbRecipe } from '../model/firestore-plan';

export interface RecipeOverview {
  mealType: FbMealType;
  recipes: FbRecipe[];
}

@Component({
  selector: 'app-recipe-overview',
  templateUrl: './recipe-overview.page.html',
  styleUrls: ['./recipe-overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    CardComponent,
    TranslatableTextPipe,
    TranslateModule,
    LoadingCardsComponent,
    HeaderComponent,
    RecipeFilterComponent,
    InfoBannerComponent
  ]
})
export class RecipeOverviewPage {
  public readonly overview$: Observable<RecipeOverview> = this.route.queryParamMap.pipe(
    map(params => params.get('mealType') as FbMealType),
    filter(mealType => !!mealType),
    switchMap(mealType =>
      combineLatest([
        this.recipeQueryService.getRecipesByCategory(mealType),
        this.store.select(selectRecipeSelectedFilters)
      ]).pipe(
        map(([recipes, filters]) => ({
          mealType,
          recipes: filterRecipes(recipes, filters)
        }))
      )
    )
  );

  protected readonly recipeCardFooter = recipeCardFooter;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly recipeQueryService: RecipeQueryService,
    private readonly store: Store
  ) {}

  protected async navigateToDetail(recipe: FbRecipe): Promise<void> {
    await navigateToDetail(this.router, recipe.id, undefined, true);
  }
}
