// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meal__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.meal__item__image {
  width: 100px;
  height: 100px;
  border-radius: 16px;
  object-fit: cover;
  object-position: center;
  flex-shrink: 0;
}
.meal__item__meal {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-left: 15px;
  overflow: hidden;
}
.meal__item__meal .title {
  margin-bottom: 10px;
  max-width: 100%;
}
.meal__item__meal .info {
  display: flex;
  align-items: center;
}
.meal__item__meal .info__item {
  display: flex;
  align-items: center;
  gap: var(--fu-spacing-xxs);
  margin-right: 20px;
}
.meal__item__meal .info__item__icon {
  font-size: 18px;
  color: var(--fu-grey-5);
}
.meal__item__icon {
  font-size: 18px;
  color: var(--fu-grey-3);
}`, "",{"version":3,"sources":["webpack://./libs/nutrition-planner/src/lib/components/meal-item/meal-item.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AAEE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AAAJ;AAGE;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,OAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AAGI;EACE,mBAAA;EACA,eAAA;AADN;AAII;EACE,aAAA;EACA,mBAAA;AAFN;AAIM;EACE,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,kBAAA;AAFR;AAIQ;EACE,eAAA;EACA,uBAAA;AAFV;AAQE;EACE,eAAA;EACA,uBAAA;AANJ","sourcesContent":[".meal__item {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n\n  &__image {\n    width: 100px;\n    height: 100px;\n    border-radius: 16px;\n    object-fit: cover;\n    object-position: center;\n    flex-shrink: 0;\n  }\n\n  &__meal {\n    display: flex;\n    align-items: flex-start;\n    flex-direction: column;\n    flex: 1;\n    justify-content: center;\n    margin-left: 15px;\n    overflow: hidden;\n\n    .title {\n      margin-bottom: 10px;\n      max-width: 100%;\n    }\n\n    .info {\n      display: flex;\n      align-items: center;\n\n      &__item {\n        display: flex;\n        align-items: center;\n        gap: var(--fu-spacing-xxs);\n        margin-right: 20px;\n\n        &__icon {\n          font-size: 18px;\n          color: var(--fu-grey-5);\n        }\n      }\n    }\n  }\n\n  &__icon {\n    font-size: 18px;\n    color: var(--fu-grey-3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
