import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Chart, ChartConfiguration } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { BehaviorSubject } from 'rxjs';

export enum FuChartType {
  bar = 'bar',
  line = 'line',
}

@Component({
  selector: 'app-fu-chart',
  templateUrl: './fu-chart.component.html',
  styleUrls: ['./fu-chart.component.scss'],
  imports: [CommonModule, NgChartsModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuChartComponent<T = number> {
  @Input()
  public set type(type: FuChartType) {
    this.type$.next(type);
  }

  @Input()
  public options: ChartConfiguration<any, T, string>['options'] | undefined;

  @Input()
  public data: ChartConfiguration<any, T, string>['data'] | undefined;

  public readonly type$ = new BehaviorSubject<FuChartType>(FuChartType.line);
}
