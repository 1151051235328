import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompletedActivityDetails } from '../models/activity-list';
import { Observable } from 'rxjs';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { ActivityCreateRequest } from '../models/activity-request';

@Injectable({
  providedIn: 'root'
})
export class ActivityMutationService {
  constructor(private readonly httpClient: HttpClient) {}

  public saveActivity(activity: ActivityCreateRequest): Observable<CompletedActivityDetails> {
    return this.httpClient.post<CompletedActivityDetails>(`${ApiService.API_URL}/activities/details`, activity);
  }

  public deleteActivity(activityId: number): Observable<void> {
    return this.httpClient.delete<void>(`${ApiService.API_URL}/activities/details/${activityId}`);
  }
}
