import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { notificationsActions } from './notifications.actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { defaultNotificationsPageSize } from './notifications.reducer';
import { selectNotificationsPageNumber } from './notifications.selector';
import { Store } from '@ngrx/store';

@Injectable()
export class NotificationsEffects {
  public readonly loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.loadNotifications, notificationsActions.loadMoreNotifications),
      concatLatestFrom(() => this.store.select(selectNotificationsPageNumber)),
      switchMap(([{ ...action }, pageNum]) =>
        this.notificationService
          .getNotifications(
            defaultNotificationsPageSize,
            action.type === notificationsActions.loadNotifications.type ? 0 : pageNum
          )
          .pipe(
            map(notificationPageResult =>
              action.type === notificationsActions.loadNotifications.type
                ? notificationsActions.loadNotificationsSuccess(notificationPageResult)
                : notificationsActions.loadMoreNotificationsSuccess(notificationPageResult)
            ),
            catchError(error => of(notificationsActions.loadNotificationsFailure({ error })))
          )
      )
    )
  );

  public readonly markReadNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.markReadNotification),
      switchMap(({ notificationId }) =>
        this.notificationService.markNotificationAsRead(notificationId).pipe(
          map(notifications => notificationsActions.markReadNotificationSuccess({ notifications })),
          catchError(error => of(notificationsActions.markReadNotificationFailure({ error })))
        )
      )
    )
  );

  public readonly markAllNotificationsAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.markAllNotificationsAsRead),
      switchMap(() =>
        this.notificationService.markAllNotificationsAsRead().pipe(
          map(() => notificationsActions.markAllNotificationsAsReadSuccess()),
          catchError(error => of(notificationsActions.markAllNotificationsAsReadFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationsService,
    private readonly store: Store
  ) {}
}
