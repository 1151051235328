import { Injectable } from '@angular/core';
import { DeviceRegistration } from './push-notifications.service';
import { Observable } from 'rxjs';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegisterDeviceService {
  constructor(private readonly httpClient: HttpClient) {}

  public registerDevice(registration: DeviceRegistration): Observable<void> {
    return this.httpClient.post<void>(`${ApiService.API_URL}/push-notification/register-device`, registration);
  }
}
