import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-subscription-box',
  templateUrl: './subscription-box.component.html',
  styleUrls: ['./subscription-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, TranslateModule],
})
export class SubscriptionBoxComponent {
  @Input({ required: true })
  public description!: string;

  @Input()
  public image: string | undefined;

  @Input({ required: true })
  public label!: string;

  @Input()
  public boxColor: string | undefined;
}
