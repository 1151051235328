import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { LocalizedNumberPipe } from '@fitup-monorepo/core/lib/pipes/localized-number.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-meal-item',
  templateUrl: './meal-item.component.html',
  styleUrls: ['./meal-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, LocalizedNumberPipe, TranslateModule]
})
export class MealItemComponent {
  @Input()
  public image: string | undefined;

  @Input()
  public itemTitle: string | undefined;

  @Input()
  public durationInMinutes: number | undefined;

  @Input()
  public calories: number | undefined;

  @Input()
  public showChevron = true;

  @Input()
  public showVerifiedBadge = false;

  @Output()
  public mealItemClick = new EventEmitter<void>();
}
