import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubscriptionPlanModalComponent } from './subscription-plan-modal.component';
import { CustomerSelectService } from '@fitup-monorepo/core/lib/services/customer-select/customer-select.service';
import { environment } from '@fitup-monorepo/core/lib/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanService {
  private readonly isCustomApp = environment.isCustomApp;

  constructor(
    private readonly modalController: ModalController,
    private readonly customerSelectService: CustomerSelectService
  ) {}

  private async isTrialExpired(): Promise<boolean> {
    return this.customerSelectService.selectExpiredTrial();
  }

  public async startSubscriptionNutrition(): Promise<void> {
    if (this.isCustomApp) {
      console.error('custom app start subscription nutrition');
    } else {
      await SubscriptionPlanModalComponent.openModal(this.modalController, true, false, await this.isTrialExpired());
    }
  }

  public async startSubscription(): Promise<void> {
    if (this.isCustomApp) {
      console.error('custom app start subscription');
    } else {
      await SubscriptionPlanModalComponent.openModal(this.modalController, false, false, await this.isTrialExpired());
    }
  }
}
