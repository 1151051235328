import { AfterViewInit, ChangeDetectorRef, Directive, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../services/permissions/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private readonly permissionsService: PermissionsService) {}

  public async isAdmin(): Promise<boolean> {
    return this.permissionsService.hasRole('ROLE_PARTNER_ADMIN') || this.permissionsService.hasRole('ADMIN');
  }
}

@Directive({
  selector: '[appAdminOnly]',
  standalone: true
})
export class AdminOnlyDirective implements AfterViewInit {
  constructor(
    private readonly adminService: AdminService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly view: ViewContainerRef,
    private readonly template: TemplateRef<any>
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    if (await this.adminService.isAdmin()) {
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
    this.changeDetectorRef.detectChanges();
  }
}
