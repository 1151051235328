import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';


export interface LiveClassCardItem {
  image: string;
  title: string;
}
@Component({
  selector: 'app-live-class-card-item',
  templateUrl: './live-class-card-item.component.html',
  styleUrls: ['./live-class-card-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, TranslateModule],
})
export class LiveClassCardItemComponent {
  @Input({ required: true })
  public data!: LiveClassCardItem;

  @Input()
  public timeLabel: string;

  @Input()
  public duration: number;

  @Input()
  public averageRating: number | string;

  @Input()
  public ratingAmount: string | number;

  @Input()
  public userLabel: string;

  @Input()
  public borderColor: string;

  @Input()
  public footerActionActiveName: string;

  @Input()
  public footerActionInactiveName: string;

  @Input()
  public isFooterActionActive: boolean;

  @Input()
  public isInActive: boolean;

  @Output()
  public footerActionCLick = new EventEmitter<void>();
}
