import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { miniChallengesActions } from './mini-challenges.actions';
import { of, switchMap } from 'rxjs';
import { MiniChallengesService } from '../mini-challenges.service';
import { catchError, map } from 'rxjs/operators';
import { Permission, PermissionsService } from '@fitup-monorepo/core/lib/services/permissions/permissions.service';
import { HttpStatusCode } from '@angular/common/http';
import { ChallengeModalService } from '@fitup-monorepo/challenge/lib/services/challenge-modal.service';

@Injectable()
export class MiniChallengesEffects {
  public readonly loadAllMiniChallenges$ = createEffect(() =>
    this.action$.pipe(
      ofType(miniChallengesActions.loadAllMiniChallenges),
      switchMap(() =>
        this.miniChallengesService.getMiniChallenges().pipe(
          map(miniChallenges =>
            miniChallengesActions.loadAllMiniChallengesSuccess({
              miniChallenges
            })
          ),
          catchError(error => of(miniChallengesActions.loadAllMiniChallengesFailure({ error })))
        )
      )
    )
  );

  public readonly joinMiniChallenge$ = createEffect(() =>
    this.action$.pipe(
      ofType(miniChallengesActions.joinMiniChallenge),
      switchMap(({ miniChallengeId }) =>
        this.miniChallengesService.joinMiniChallenge(miniChallengeId).pipe(
          map(miniChallenge => miniChallengesActions.joinMiniChallengeSuccess({ miniChallenge })),
          catchError(error => {
            if (!this.permissionsService.hasPermission(Permission.basic) && error.status === HttpStatusCode.Forbidden) {
              this.challengeModalService.showModalForFreeUser(error.status);
            }
            return of(miniChallengesActions.joinMiniChallengeFailure({ error }));
          })
        )
      )
    )
  );

  public readonly cancelMiniChallenge$ = createEffect(() =>
    this.action$.pipe(
      ofType(miniChallengesActions.cancelMiniChallenge),
      switchMap(({ miniChallengeId }) =>
        this.miniChallengesService.cancelMiniChallenge(miniChallengeId).pipe(
          map(miniChallenge => miniChallengesActions.cancelMiniChallengeSuccess({ miniChallenge })),
          catchError(error => of(miniChallengesActions.cancelMiniChallengeFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly action$: Actions,
    private readonly miniChallengesService: MiniChallengesService,
    private readonly permissionsService: PermissionsService,
    private readonly challengeModalService: ChallengeModalService
  ) {}
}
