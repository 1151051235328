import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SubscriptionFreeTrialPageRoutingModule } from './subscription-free-trial-routing.module';

import { SubscriptionFreeTrialOnboardPage } from './subscription-free-trial-onboard.page';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { SetupContainerComponent } from '@fitup-monorepo/components/lib/setup-container/setup-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriptionFreeTrialComponent } from './subscription-free-trial.component';
import { LocalizedDatePipe } from '@fitup-monorepo/core/lib/pipes/localized-date.pipe';
import { StartSubscriptionButtonComponent } from '../components/start-subscription-button/start-subscription-button.component';
import { LocalizedCurrencyPipe } from '@fitup-monorepo/core/lib/pipes/localized-currency.pipe';
import { NativeOnlyDirective } from '@fitup-monorepo/core/lib/services/platform/platform.service';
import { ListComponent } from '../components/list/list.component';
import { InfoLabelComponent } from '../components/info-label/info-label.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SubscriptionFreeTrialPageRoutingModule,
    HeaderComponent,
    SetupContainerComponent,
    TranslateModule,
    LocalizedDatePipe,
    StartSubscriptionButtonComponent,
    LocalizedCurrencyPipe,
    NativeOnlyDirective,
    ListComponent,
    InfoLabelComponent
  ],
  declarations: [SubscriptionFreeTrialOnboardPage, SubscriptionFreeTrialComponent],
  exports: [SubscriptionFreeTrialComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SubscriptionFreeTrialPageModule {}
