import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Group, GroupCreateOrUpdateRequest, GroupOverview } from '../../model';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';

export const groupActions = createActionGroup({
  source: 'Group',
  events: {
    ['Load Group Overview']: emptyProps(),
    ['Load Group Overview Success']: props<{
      groupOverview: GroupOverview;
    }>(),
    ['Load Group Overview Failure']: props<ActionError>(),
    ['Join Group']: props<{ invitationCode?: string }>(),
    ['Join Group Success']: props<{ group: Group }>(),
    ['Join Group Failure']: props<ActionError>(),
    ['Leave Group']: props<{ groupId: number }>(),
    ['Leave Group Success']: props<{ group: Group }>(),
    ['Leave Group Failure']: props<ActionError>(),
    ['Create Group']: props<{ request: GroupCreateOrUpdateRequest }>(),
    ['Create Group Success']: props<{ group: Group }>(),
    ['Create Group Failure']: props<ActionError>(),
    ['Update Group']: props<{ groupId: number; request: GroupCreateOrUpdateRequest }>(),
    ['Update Group Success']: props<{ group: Group }>(),
    ['Update Group Failure']: props<ActionError>(),
    ['Delete Group']: props<{ groupId: number }>(),
    ['Delete Group Success']: emptyProps(),
    ['Delete Group Failure']: props<ActionError>()
  }
});
