import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from '../../environment';

/**
 * This class is used by the TranslateModule to load translations. It will load
 * the default translation file and then load the additional translations if
 * existing for the current app flavor.
 */
export class CustomTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    public prefix: string = '/assets/i18n/',
    public suffix: string = '.json'
  ) {}

  public getTranslation(lang: string): Observable<Object> {
    const appFlavor: string = environment.urlScheme;

    return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
      switchMap(translations =>
        this.http.get(`${this.prefix}${appFlavor}/${lang}${this.suffix}`).pipe(
          catchError(e => of({})),
          map(additionalTranslations => ({ ...translations, ...additionalTranslations }))
        )
      )
    );
  }
}
