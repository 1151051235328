export function getMostRecentObject<T extends object>(data: T[], timeStampPropName: keyof T): T | undefined {
  return data.reduce((acc, obj) => {
    if (!acc || toDate(obj[timeStampPropName]) > toDate(acc[timeStampPropName])) {
      return obj;
    }
    return acc;
  }, undefined);
}

export function sortByCreatedAt<T extends object>(
  data: T[],
  sortDirection: 'asc' | 'desc' = 'asc',
  propertyName = 'createdAt'
): T[] {
  const dataCopy = [...data];
  return dataCopy.sort((a, b) => {
    const timeA = new Date(a[propertyName]).getTime();
    const timeB = new Date(b[propertyName]).getTime();

    return sortDirection === 'asc' ? timeA - timeB : timeB - timeA;
  });
}

export function sortByBoolean<T extends object>(data: T[], propertyName = 'isFree'): T[] {
  return data.sort((a, b) => Number(b[propertyName] ?? false) - Number(a[propertyName] ?? false));
}

export function sortByAlphabet<T extends object>(data: T[], sortDirection: 'asc' | 'desc', propertyName: keyof T): T[] {
  return data.sort((a, b) => {
    const valueA = a[propertyName].toString().toLowerCase();
    const valueB = b[propertyName].toString().toLowerCase();

    if (sortDirection === 'asc') {
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    } else if (sortDirection === 'desc') {
      if (valueA > valueB) {
        return -1;
      }
      if (valueA < valueB) {
        return 1;
      }
      return 0;
    } else {
      throw new Error("Invalid order parameter. Use 'ascending' or 'descending'.");
    }
  });
}

function toDate(data: unknown): Date {
  if (data instanceof Date || typeof data === 'string' || typeof data === 'number') {
    return new Date(data);
  }
  throw new Error(`unsupported data type for ${data}`);
}

export function groupByProperty<T>(data: T[], property: keyof T): Record<string, T[]> {
  return data.reduce(
    (result, item) => {
      const keyValue = String(item[property]);

      if (!result[keyValue]) {
        result[keyValue] = [];
      }

      result[keyValue].push(item);

      return result;
    },
    {} as Record<string, T[]>
  );
}

export function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
