import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { IActivity, ITeamMembersActivity } from '../../model/activity/old/activity.model';
import { HealthApiActivities, HealthStatsResponse } from '../../model/health-stats-types';

@Injectable({ providedIn: 'root' })
export class ActivityService {
  constructor(protected http: HttpClient) {}

  public getActivitiesForCustomerWithCustomerId(options): Observable<ITeamMembersActivity[]> {
    const params = options;
    return this.http.get<IActivity[]>(`${ApiService.API_URL}/activities/allActivities/byCustomerId/andDateBetween`, {
      params
    });
  }

  public storeActivitiesAndGetHealthStats(activities: HealthApiActivities): Observable<HealthStatsResponse> {
    return this.http.post<HealthStatsResponse>(`${ApiService.API_URL}/activities/health-stats`, activities);
  }
}
