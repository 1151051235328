import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  forwardRef,
  HostBinding,
  inject,
  Input,
  OnInit,
} from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent implements ControlValueAccessor, OnInit {
  @Input()
  public min: number | undefined;

  @Input()
  public max: number | undefined;

  @Input()
  public stepSize = 1;

  @Input()
  public buttonSize: number;

  @Input()
  public iconSize: number;

  @HostBinding('class')
  @Input()
  public inputStyle: 'rounded' | 'square' | 'clear' = 'rounded';

  public readonly input = new FormControl<number | undefined>(undefined);
  private readonly destroyRef = inject(DestroyRef);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: number) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => void = () => {};

  public ngOnInit(): void {
    this.input.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.onChange(+value);
      this.onTouched();
    });
  }

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: unknown): void {
    if (this.isNumberOrUndefined(value)) {
      this.input.setValue(value);
    } else {
      throw new Error('Unsupported type for number component');
    }
  }

  private isNumberOrUndefined(value: unknown): value is number | undefined {
    return value === null || value === undefined || typeof value === 'number';
  }

  public decrementValue(): void {
    const newValue = this.input.value - this.stepSize;
    this.input.setValue(this.min && newValue < this.min ? this.min : newValue);
  }

  public incrementValue(): void {
    const newValue = this.input.value + this.stepSize;
    this.input.setValue(this.max && newValue > this.max ? this.max : newValue);
  }

  public disableDecrement(): boolean {
    return this.input.value <= this.min;
  }

  public disableIncrement(): boolean {
    return this.input.value >= this.max;
  }
}
