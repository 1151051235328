import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { ProfileInformation } from '../../model/profile-information';
import { Customer } from '../../model/customer';
import { CustomerUpdateRequest } from '../../model/customer-request';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private readonly httpClient: HttpClient) {}

  public getProfileInformation(): Observable<ProfileInformation> {
    return this.httpClient.get<ProfileInformation>(`${ApiService.API_URL}/profile`);
  }

  public updateCustomer(request: CustomerUpdateRequest): Observable<void> {
    return this.httpClient.put<void>(`${ApiService.API_URL}/account`, request);
  }

  public uploadImage(image: string, customerId: number): Observable<Customer> {
    return this.httpClient.post<Customer>(`${ApiService.API_URL}/customers/${customerId}/image`, image);
  }

  public setAvatar(avatarId: number): Observable<void> {
    return this.httpClient.put<void>(`${ApiService.API_URL}/profile/avatar?avatarId=${avatarId}`, null);
  }

  public deleteImage(): Observable<void> {
    return this.httpClient.delete<void>(`${ApiService.API_URL}/profile/picture`);
  }
}
