import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

export interface DropdownItem {
  label: string;
  iconName: string;
  callback: () => void | Promise<void>;
  color?: string;
}

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, TranslateModule],
})
export class DropdownMenuComponent {
  @Input()
  public items: DropdownItem[];

  @Input()
  public iconName = 'dots-vertical';

  @Input()
  public iconColor: string;

  @Input()
  public menuColor: string;

  @Input()
  public popoverClass: string;

  @Output()
  public visibility = new EventEmitter<boolean>();

  @ViewChild('popover')
  public popover: HTMLIonPopoverElement | undefined;

  public readonly isOpen = signal(false);

  public presentPopover($event: MouseEvent): void {
    this.popover.event = $event;
    this.isOpen.set(true);
    this.visibility.emit(true);
  }
}
