import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import BackgroundGeolocation, { Location } from '@transistorsoft/capacitor-background-geolocation';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { environment } from '@fitup-monorepo/core/lib/environment';
import { timeout } from '@fitup-monorepo/core/lib/util/timeout';
import { LocationLoggingService, LogRequest } from './location-logging.service';
import { DEVICE } from '@fitup-monorepo/core/lib/capacitor-injection-tokens';
import { DevicePlugin } from '@capacitor/device';
import { Platform } from '@ionic/angular';

export interface GpsLocation {
  latitude: number;
  longitude: number;
  altitude: number;
  bearing: number;
  timestamp: string;
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private headingSubject = new BehaviorSubject<number | undefined>(undefined);
  public heading$: Observable<number | undefined> = this.headingSubject.asObservable();

  private destroy$ = new Subject<void>();

  constructor(
    private readonly translateService: TranslateService,
    private readonly platform: Platform,
    @Inject(DEVICE) private readonly device: DevicePlugin,
    private readonly locationLoggingService: LocationLoggingService
  ) {
    BackgroundGeolocation.onAuthorization(e => console.log('onAuthorization', JSON.stringify(e)));
    BackgroundGeolocation.onPowerSaveChange(e => console.log('onPowerSaveChange', JSON.stringify(e)));
    BackgroundGeolocation.onProviderChange(e => console.log('onProviderChange', JSON.stringify(e)));
  }

  public async sendLogs(): Promise<void> {
    try {
      if (this.platform.is('capacitor')) {
        const deviceId = await this.device.getId();
        const logs = await BackgroundGeolocation.logger.getLog();
        const request: LogRequest = {
          lines: logs.split('\n\n').map(line => line.trim())
        };
        await firstValueFrom(this.locationLoggingService.sendLogs(deviceId.identifier, request));
        await BackgroundGeolocation.logger.destroyLog();
      }
    } catch (e) {
      console.error('sendLogs error', e);
    }
  }

  private toGpsLocation(location: Location): GpsLocation {
    return {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      altitude: location.coords.altitude,
      bearing: location.coords.heading,
      timestamp: location.timestamp
    };
  }

  /**
   * Start continuous location tracking.
   *
   * @returns Observable of locations
   */
  public startLocationTracking(): Observable<GpsLocation> {
    if (!!this.destroy$) {
      this.destroy$.next();
    }
    this.destroy$ = new Subject<void>();

    console.log('start watchLocation');

    const location$ = new Subject<Location>();

    this.startWatchPosition(location$);

    return location$.pipe(
      takeUntil(this.destroy$),
      tap(location => this.headingSubject.next(location.coords.heading)),
      map(location => this.toGpsLocation(location)),
      filter(location => !!location)
    );
  }

  private async restartWatchPosition(location$: Subject<Location>): Promise<void> {
    try {
      await BackgroundGeolocation.stopWatchPosition();
      console.log('stopWatchPosition promise success');
    } catch (e) {
      console.log('error stopWatchPosition', e);
    }

    await timeout(500);

    try {
      const state = await BackgroundGeolocation.stop();
      console.log('stop promise success', state);
    } catch (e) {
      console.log('error stop', e);
    }

    await timeout(500);

    try {
      await this.initialize();
    } catch (e) {
      console.log('error initialize', e);
    }

    await timeout(500);

    this.startWatchPosition(location$);
  }

  private startWatchPosition(location$: Subject<Location>): void {
    console.log('start watchPosition');
    BackgroundGeolocation.watchPosition(
      location => {
        location$.next(location);
      },
      error => {
        console.error('watchPosition error', error);
        this.restartWatchPosition(location$).catch(e => console.error('restartWatchPosition error', e));
      },
      {
        interval: 500,
        desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
        persist: true,
        timeout: 10000
      }
    );
  }

  /**
   * Stop the continuous location tracking.
   */
  public async stopLocationTracking(): Promise<void> {
    console.log('stop watchLocation');
    try {
      await BackgroundGeolocation.stopWatchPosition();
    } catch (e) {
      console.log('error stopWatchPosition', e);
    }
    await BackgroundGeolocation.stop();
    this.destroy$.next();
  }

  public async initialize(): Promise<boolean> {
    try {
      const readyState = await BackgroundGeolocation.ready({
        // Geolocation Config
        desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
        distanceFilter: 0,
        desiredOdometerAccuracy: 2,
        locationAuthorizationRequest: 'WhenInUse',
        isMoving: true,
        stopTimeout: 5, //minutes
        disableMotionActivityUpdates: false,
        disableStopDetection: true,
        heartbeatInterval: 60,

        //iOS only
        stationaryRadius: 25, //25 is the plugins minimum value
        showsBackgroundLocationIndicator: true,
        activityType: 3, //ACTIVITY_TYPE_FITNESS
        preventSuspend: true,
        pausesLocationUpdatesAutomatically: false,
        stopDetectionDelay: 0,

        //Android only
        allowIdenticalLocations: true,
        foregroundService: true,
        locationUpdateInterval: 500,
        backgroundPermissionRationale: {
          title: this.translateService.instant('LOCATION.ENABLE_LOCATION_TITLE'),
          message: this.translateService.instant('LOCATION.ENABLE_LOCATION_MESSAGE'),
          positiveAction: this.translateService.instant('LOCATION.ALLOW'),
          negativeAction: this.translateService.instant('ENABLE_LOCATION_CANCEL')
        },
        notification: {
          title: 'FIT-UP Tracking',
          text: 'FIT-UP is tracking your location'
        },
        geofenceModeHighAccuracy: true,

        // Application config
        debug: false, // <-- enable this hear sounds for background-geolocation life-cycle.
        logLevel: environment.production
          ? BackgroundGeolocation.LOG_LEVEL_INFO
          : BackgroundGeolocation.LOG_LEVEL_VERBOSE
      });

      console.log('ready state', readyState);

      const permissionState = await BackgroundGeolocation.requestPermission();

      console.log('permission state', permissionState);

      const startState = await BackgroundGeolocation.start();

      console.log('start state', startState);
      return true;
    } catch (error) {
      console.error('initialize error', error);
      throw error;
    }
  }
}
