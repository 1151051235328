import { Injectable } from '@angular/core';
import { environment } from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public static get API_URL(): string {
    return environment.apiUrl;
  }
}
