import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from '@fitup-monorepo/core/lib/services/activity/activity.service';
import { FuBarChartData } from '@fitup-monorepo/components/lib/fu-chart/fu-bar-chart/fu-bar-chart.component';
import { DateRange, RangeMode } from '@fitup-monorepo/components/lib/date-select/model/date-select';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { Customer } from '@fitup-monorepo/core/lib/model/customer';
import { IActivity } from '@fitup-monorepo/core/lib/model/activity/old/activity.model';

interface DateCalories {
  date: string;
  value: number;
}

enum DashboardChartType {
  calories = 'CALORIES',
  steps = 'STEPS'
}

@Component({
  selector: 'app-personal-dashboard',
  templateUrl: './personal-dashboard.component.html',
  styleUrls: ['./personal-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalDashboardComponent implements OnInit {
  @Input()
  public customer: Customer;

  public chartType = {
    calories: DashboardChartType.calories,
    steps: DashboardChartType.steps
  };
  public dataAvailableFrom: Date | undefined;
  public readonly rangeMode$: BehaviorSubject<RangeMode> = new BehaviorSubject<RangeMode>(RangeMode.week);
  public readonly dateRangeState$ = new BehaviorSubject<DateRange | undefined>(undefined);
  public metrics$: Observable<IActivity[] | undefined>;

  public readonly segmentOptions = [
    { label: 'DATE_SELECT.WEEKLY', value: RangeMode.week },
    { label: 'DATE_SELECT.MONTHLY', value: RangeMode.month },
    { label: 'DATE_SELECT.YEARLY', value: RangeMode.year }
  ];

  private readonly dashboardChartConfigs = {
    [DashboardChartType.calories]: {
      chartColor: '#FB942E',
      chartLabel: 'ACCOUNT.PERSONAL_DASHBOARD.CALORIES.CALORIES_COUNT',
      chartLabelUnit: 'ACCOUNT.PERSONAL_DASHBOARD.CALORIES.KCAL'
    },
    [DashboardChartType.steps]: {
      chartColor: '#45D56D',
      chartLabel: 'ACCOUNT.PERSONAL_DASHBOARD.STEPS.STEPS_COUNT',
      chartLabelUnit: 'ACCOUNT.PERSONAL_DASHBOARD.STEPS.STEPS'
    }
  };

  constructor(
    public readonly translateService: TranslateService,
    private readonly activityService: ActivityService,
    private readonly toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.metrics$ = this.customer ? this.initMetrics() : of(undefined);
  }

  public changeDateRangeMode(rangeMode: RangeMode): void {
    this.rangeMode$.next(rangeMode);
  }

  public setDateRange($event: DateRange): void {
    this.dateRangeState$.next($event);
  }

  private initMetrics(): Observable<IActivity[]> {
    const convertToUnixTimeStamp = (date: Date): number => Math.floor(date.getTime() / 1000);

    return this.dateRangeState$.pipe(
      filter(range => !!range),
      switchMap(({ startDate, endDate }) =>
        this.activityService
          .getActivitiesForCustomerWithCustomerId({
            customerId: this.customer.id,
            fromDate: convertToUnixTimeStamp(startDate),
            toDate: convertToUnixTimeStamp(endDate)
          })
          .pipe(
            catchError((error: unknown) => {
              if (!this.isForbidden(error)) {
                this.toastService.showError('An error appeared while fetching Activity data!');
              }
              return of(undefined);
            })
          )
      )
    );
  }

  private isForbidden(error: unknown): boolean {
    return error instanceof HttpErrorResponse && error.status === 403;
  }

  public getActivityBarChartData(
    metric: IActivity[],
    dateRangeState: DateRange,
    chartFor: DashboardChartType
  ): FuBarChartData<number> {
    if (!metric) return;

    const chartConfigs = this.dashboardChartConfigs[chartFor];
    const metricArray = this.getMetricArrayByDateRange(metric, dateRangeState, chartFor);
    const chartLabels = metricArray.map(m => this.formatLabel(m.date, dateRangeState.rangeMode));
    const chartValues = metricArray.map(m => Math.round(m.value));

    return {
      xAxisLabels: chartLabels,
      datasets: [
        {
          color: chartConfigs.chartColor,
          values: chartValues,
          label: this.translateService.instant(chartConfigs.chartLabel),
          labelUnit: this.translateService.instant(chartConfigs.chartLabelUnit)
        }
      ]
    };
  }

  private getMetricArrayByDateRange(
    metrics: IActivity[],
    dateRangeState: DateRange,
    chartFor: DashboardChartType
  ): DateCalories[] {
    const { startDate, endDate, rangeMode } = dateRangeState;
    const dates: { [date: string]: number } = {};

    const currentDate = new Date(startDate);

    const generateDateByRange = (mode: RangeMode, date: Date): string => {
      if (mode === RangeMode.year) {
        return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}`;
      } else {
        return date.toISOString().slice(0, 10);
      }
    };

    while (currentDate <= endDate) {
      currentDate.setDate(currentDate.getDate() + 1);
      const date = generateDateByRange(rangeMode, currentDate);
      dates[date] = 0;
    }

    for (const metric of metrics) {
      const date = generateDateByRange(rangeMode, new Date(metric.startDate));

      if (dates[date] !== undefined) {
        if (chartFor === DashboardChartType.steps) {
          dates[date] += metric.steps;
        } else {
          dates[date] += metric.calories;
        }
      }
    }

    return Object.entries(dates).map(([date, value]) => ({ date, value }));
  }

  private formatLabel(timeStamp: string, rangeMode: RangeMode): string {
    if (rangeMode === RangeMode.week) {
      return new Date(timeStamp)
        .toLocaleString(this.translateService.currentLang, {
          weekday: 'short'
        })
        .charAt(0);
    }
    if (rangeMode === RangeMode.year) {
      return new Date(timeStamp).toLocaleString(this.translateService.currentLang, {
        month: 'short',
        year: '2-digit'
      });
    }
    return new Date(timeStamp).toLocaleDateString(this.translateService.currentLang, {
      month: '2-digit',
      day: '2-digit'
    });
  }
}
