import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { challengeDetailActions } from './challenge-detail.actions';
import { LocalStorageService } from 'ngx-webstorage';
import { challengeIdToJoinKey } from '@fitup-monorepo/core/lib/guards/join-challenge.guard';

export const challengeDetailGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const localStorageService = inject(LocalStorageService);

  const challengeId = route.paramMap.get('challengeId');
  const challengeIdToJoin: number = localStorageService.retrieve(challengeIdToJoinKey);

  store.dispatch(challengeDetailActions.loadChallenge({ challengeId: +challengeId }));

  if (challengeIdToJoin) {
    store.dispatch(challengeDetailActions.joinChallenge({ challengeId: challengeIdToJoin }));
  }

  return true;
};
