import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { HttpClient } from '@angular/common/http';
import { SelectableTeam } from '../../model/team-selection/selectable-team';

@Injectable({ providedIn: 'root' })
export class TeamSelectionService {
  constructor(public readonly httpClient: HttpClient) {}

  public joinTeam(teamId: number): Observable<void> {
    return this.httpClient.post<void>(`${ApiService.API_URL}/team/${teamId}/join`, null);
  }

  public getSelectableTeams(): Observable<SelectableTeam[]> {
    return this.httpClient.get<SelectableTeam[]>(`${ApiService.API_URL}/partner/selectable-teams`);
  }
}
