import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedNumberPipe } from '@fitup-monorepo/core/lib/pipes/localized-number.pipe';
import { InfoBannerComponent } from '@fitup-monorepo/components/lib/info-banner/info-banner.component';
import { PlanQueryService } from '../../plan-query.service';
import { PlanFeatureService } from '../../plan-feature.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ProgressBarComponent } from '@fitup-monorepo/components/lib/progress-bar/progress-bar.component';
import { TranslatableTextPipe } from '@fitup-monorepo/core/lib/pipes/translatable-text.pipe';
import { PlanStatus, WeeklyPlanStatus } from '../../model/plan';
import { BrowserService } from '@fitup-monorepo/core/lib/services/browser/browser.service';

@Component({
  selector: 'app-plan-progress',
  templateUrl: './plan-progress.component.html',
  styleUrls: ['./plan-progress.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    LocalizedNumberPipe,
    InfoBannerComponent,
    ProgressBarComponent,
    TranslatableTextPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class PlanProgressComponent {
  public readonly planProgressDetails$ = this.planQueryService.getPlanProgressDetails().pipe(
    tap(plan => {
      this.nextWeekIndex = plan?.weeks.findIndex(week => week.status === WeeklyPlanStatus.upcoming);
    })
  );

  public nextWeekIndex: number | undefined;

  public readonly weeklyPlanStatus = WeeklyPlanStatus;

  constructor(
    private readonly planQueryService: PlanQueryService,
    private readonly endPlanService: PlanFeatureService,
    private readonly browserService: BrowserService,
    private readonly router: Router
  ) {}

  public async open(url: string): Promise<void> {
    await this.browserService.openUrl(url, true);
  }

  public isCompleted(status: PlanStatus): boolean {
    return status === PlanStatus.completed;
  }

  public getClassStatus(status: WeeklyPlanStatus): string {
    return status === WeeklyPlanStatus.active
      ? 'plan__overview__detail__label--active'
      : status === WeeklyPlanStatus.upcoming
      ? 'plan__overview__detail__label--next'
      : '';
  }

  public async onEndProgram(planId: string): Promise<void> {
    try {
      await this.endPlanService.endPlan(planId);
      await this.router.navigate(['/tabs/nutrition-planner']);
    } catch (e) {
      /* do nothing */
    }
  }

  public getPlanTitle(planStatus: PlanStatus): string {
    return planStatus === PlanStatus.completed
      ? 'NUTRITION.PLAN_PROGRESS.COMPLETED_TITLE'
      : 'NUTRITION.PLAN_PROGRESS.TITLE';
  }

  public getPlanSubtitle(planStatus: PlanStatus): string {
    return planStatus === PlanStatus.completed
      ? 'NUTRITION.PLAN_PROGRESS.COMPLETED_HEADING'
      : 'NUTRITION.PLAN_PROGRESS.HEADING';
  }
}
