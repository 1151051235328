import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';
import { map } from 'rxjs/operators';
import { CustomMealResponse, SearchedCustomMeal, Unit } from './model/custom-meal';

@Injectable({
  providedIn: 'root'
})
export class MealSearchService {
  constructor(private readonly httpClient: HttpClient) {}

  public searchMeals(searchString: string): Observable<SearchedCustomMeal[]> {
    return this.httpClient
      .get<CustomMealResponse[]>(`${ApiService.API_URL}/nutrition/meal/search`, {
        params: {
          q: searchString
        }
      })
      .pipe(
        map(response =>
          response.map(meal => ({
            id: meal.id,
            title: meal.name,
            caloriesAmount: meal.kCal,
            carbsInGrams: meal.carbs,
            referenceAmount: meal.referenceAmount,
            referenceUnit: meal.referenceUnit,
            proteinsInGrams: meal.protein,
            fatsInGrams: meal.fat,
            portionsAmount: 1,
            verified: meal.verified,
            defaultUnit: meal.units[0],
            defaultUnitCaloriesAmount: this.getCaloriesForDefaultUnit(meal.units[0], meal.referenceAmount, meal.kCal),
            imageUrl: meal.imageUrl
          }))
        )
      );
  }

  private getCaloriesForDefaultUnit(defaultUnit: Unit, referenceAmount: number, caloriesAmount: number): number {
    return (caloriesAmount / referenceAmount) * defaultUnit.amount;
  }
}
