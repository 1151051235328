import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormArray, FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { map, tap } from 'rxjs/operators';
import { Durations } from '../../../recipe-query.service';
import { getCheckedFormElements, getFormControls } from '@fitup-monorepo/core/lib/util/form-element/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { recipeFilterActions } from '../state/recipe-filter.actions';
import { firstValueFrom, Observable } from 'rxjs';
import { selectAllRecipeFilters, selectRecipeSelectedFilters } from '../state/recipe-filter.selector';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-recipe-filter',
  templateUrl: './recipe-filter.component.html',
  styleUrls: ['./recipe-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, ReactiveFormsModule, CommonModule, TranslateModule]
})
export class RecipeFilterComponent implements OnInit {
  @Output()
  public getFilterForm = new EventEmitter();

  protected selectedDurationFilter = signal(undefined);

  protected readonly filters$: Observable<{
    tags: string[];
    durations: string[];
  }> = this.store.select(selectAllRecipeFilters).pipe(
    map(filters =>
      filters.reduce(
        (acc, filter) => {
          if (this.durations.includes(filter)) {
            acc.durations = [...acc.durations, filter];
          } else {
            acc.tags = [...acc.tags, filter];
          }
          return acc;
        },
        { tags: [], durations: [] }
      )
    ),
    tap(async filters => {
      const selectedFilters = await firstValueFrom(this.store.select(selectRecipeSelectedFilters));
      this.tagsFormArray.clear();
      this.filterForm.patchValue({ duration: '' });

      if (selectedFilters.length) {
        selectedFilters.forEach(filter => {
          if (this.durations.includes(filter)) {
            this.filterForm.patchValue({ duration: filter });
          }
        });

        filters.tags.forEach(tag => {
          this.tagsFormArray.push(new FormControl(selectedFilters.includes(tag)));
        });
      } else {
        filters.tags.forEach(() => {
          this.tagsFormArray.push(new FormControl(false));
        });
      }
    })
  );

  protected readonly filterForm = this.formBuilder.group({
    tags: new FormArray([]),
    duration: ''
  });

  public readonly tagControls = getFormControls(this.filterForm.get('tags'));
  protected readonly tagsFormArray = this.filterForm.get('tags') as FormArray;

  protected readonly durations = [
    Durations.short_duration as string,
    Durations.medium_duration as string,
    Durations.long_duration as string
  ];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.formValueChanges();
  }

  protected toggleRadio(tag: string): void {
    if (this.selectedDurationFilter() === tag) {
      this.filterForm.patchValue({ duration: '' });
    }
  }

  private formValueChanges(): void {
    this.filterForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async value => {
      this.getFilterForm.emit(value);
      this.selectedDurationFilter.set(this.filterForm.value.duration);
      const allFilters = await firstValueFrom(this.store.select(selectAllRecipeFilters));
      const allTagFilters = allFilters.filter(value => !this.durations.includes(value));
      const checkedElements = getCheckedFormElements(value.tags, allTagFilters);
      const allCheckedElements = value.duration ? [...checkedElements, value.duration] : checkedElements;
      this.store.dispatch(
        recipeFilterActions.setSelectedFilters({
          selectedFilters: allCheckedElements
        })
      );
    });
  }
}
