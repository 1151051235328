// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .fu-input--disabled {
  background: var(--fu-grey-7);
}
:host .fu-input--disabled .fu-input__icon {
  color: var(--fu-grey-5);
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/input-element/input-element.component.scss"],"names":[],"mappings":"AAEI;EACE,4BAAA;AADN;AAGM;EACE,uBAAA;AADR","sourcesContent":[":host {\n  .fu-input {\n    &--disabled {\n      background: var(--fu-grey-7);\n\n      .fu-input__icon {\n        color: var(--fu-grey-5);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
