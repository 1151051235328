import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { StartSubscriptionService } from '../../start-subscription.service';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { executeWithToastErrorHandling, isErrorResult } from '@fitup-monorepo/core/lib/util/rxjs-util';
import { TrialService } from '../../trial.service';
import { Store } from '@ngrx/store';
import {MembershipPlan} from "@fitup-monorepo/core/lib/model/purchase/purchase";
import {profileApiActions} from "@fitup-monorepo/core/lib/state/profile/profile.actions";

@Component({
  selector: 'app-start-subscription-button',
  templateUrl: './start-subscription-button.component.html',
  styleUrls: ['./start-subscription-button.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartSubscriptionButtonComponent {
  @Input()
  public label: string | undefined;

  @Input()
  public plan: MembershipPlan | undefined;

  @Input()
  public isUpgrade = false;

  @Input()
  public hasFreeTrial = false;

  @Output()
  public startSubscriptionSuccess = new EventEmitter<void>();

  protected readonly starting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly startSubscriptionService: StartSubscriptionService,
    private readonly toastService: ToastService,
    private readonly trialService: TrialService,
    private readonly store: Store
  ) {}

  public async onStartSubscription(): Promise<void> {
    this.starting$.next(true);
    if (this.hasFreeTrial) {
      await this.startTrial();
    } else {
      await this.startSubscription();
    }
    this.starting$.next(false);
  }

  private async startTrial(): Promise<void> {
    const result = await executeWithToastErrorHandling({
      observable$: this.trialService.startFreeTrial(),
      toastService: this.toastService,
      errorMessage: 'Something went wrong while starting trial'
    });
    if (!isErrorResult(result)) {
      this.startSubscriptionSuccess.emit();
      this.store.dispatch(profileApiActions.loadProfile());
    }
  }

  private async startSubscription(): Promise<void> {
    try {
      await this.startSubscriptionService.startSubscription(this.plan);
      this.startSubscriptionSuccess.emit();
    } catch (error) {
      await this.toastService.showError('SUBSCRIPTION_PLAN.PURCHASE_ERROR');
      console.error('Start subscription error', JSON.stringify(error));
    }
  }
}
