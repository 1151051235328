import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Notification } from '../../model/notification/notification';
import { PageResult } from '../../model/page-result';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private readonly httpClient: HttpClient) {}

  public getNotifications(pageSize: number, pageNum: number): Observable<PageResult<Notification>> {
    return this.httpClient.get<PageResult<Notification>>(`${ApiService.API_URL}/notifications/list`, {
      params: {
        pageSize,
        pageNum
      }
    });
  }

  public markNotificationAsRead(notificationId: number): Observable<Notification[]> {
    return this.httpClient.put<Notification[]>(`${ApiService.API_URL}/notifications/list/read`, {
      ids: [notificationId]
    });
  }

  public markAllNotificationsAsRead(): Observable<void> {
    return this.httpClient.put<void>(`${ApiService.API_URL}/notifications/list/read/all`, null);
  }
}
