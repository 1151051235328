import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, IonicModule, TranslateModule]
})
export class EditorComponent<T> implements OnInit {
  @Input()
  public formGroup: FormGroup | undefined;

  @Input()
  public buttonLabel: string | undefined;

  @Input()
  public disableSubmitButtonOnInvalid = false;

  @Input()
  public disablePristineSubmitButton = false;

  @Input()
  public disableSubmitButton = false;

  @Input()
  public showCancelButton = false;

  @Input()
  public hasStickyButtons = true;

  @Input()
  public buttonStyle: 'default' | 'full-width' = 'default';

  @Output()
  public formSubmit = new EventEmitter<T>();

  @Output()
  public clickCancelButton = new EventEmitter();

  private readonly destroyRef = inject(DestroyRef);
  protected readonly buttonVisibility = signal(false);

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public ngOnInit(): void {
    setTimeout(() => {
      this.buttonVisibility.set(true);
    }, 100);

    this.formGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public onSubmit(): void {
    // As with update onBlur the blur event is not called initially (e.g. for autofill)
    // we want to ensure that the input values are actually updated in the form group.
    this.document.querySelectorAll('input').forEach(input => {
      input.dispatchEvent(new Event('blur'));
    });
    if (this.formGroup.valid) {
      this.formSubmit.emit(this.formGroup.value);
    } else {
      this.#showAllFormErrors();
    }
  }

  #showAllFormErrors(): void {
    // as errors are only shown once a control is dirty, we need to mark them manually on submit.
    Object.values(this.formGroup.controls).forEach(control => {
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  protected getClasses(): string {
    if (this.buttonStyle === 'full-width') {
      return 'fu-button--full-width';
    }
    return '';
  }
}
