import { AppScreenOrientationService } from '../app-screen-orientation/app-screen-orientation.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Injectable } from '@angular/core';
import { timeout } from '../../util/timeout';

@Injectable({ providedIn: 'root' })
export class SplashScreenService {
  constructor(private appScreenOrientationService: AppScreenOrientationService) {}

  private splashScreenVisible = true;
  public async hide(): Promise<void> {
    if (this.splashScreenVisible) {
      await timeout(100);
      console.log('SplashScreenService: hide');
      await SplashScreen.hide({ fadeOutDuration: 100 });
      await this.appScreenOrientationService.handleInsets('SplashScreenService');
      this.splashScreenVisible = false;
    } else {
      console.log('SplashScreenService: hide (already hidden)');
    }
  }
}
