import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';

export interface ReportProblem {
  topic: string;
  message: string;
  params: { [name: string]: string };
}

@Injectable({
  providedIn: 'root'
})
export class ReportProblemApiService {
  constructor(private readonly httpClient: HttpClient) {}

  public reportProblem(reportProblem: ReportProblem): Observable<void> {
    return this.httpClient.post<void>(`${ApiService.API_URL}/problem`, reportProblem);
  }
}
