import { AfterViewInit, ChangeDetectorRef, Directive, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private isNativePlatform: boolean;

  constructor(private readonly platform: Platform) {}

  public async initialize(): Promise<void> {
    await this.platform.ready();
    this.isNativePlatform =
      environment.forceNativePlatform || this.platform.is('cordova') || this.platform.is('capacitor');
  }

  public isNative(): boolean {
    return this.isNativePlatform;
  }
}

@Directive({
  selector: '[appNativeOnly]',
  standalone: true
})
export class NativeOnlyDirective implements AfterViewInit {
  constructor(
    private readonly view: ViewContainerRef,
    private readonly template: TemplateRef<any>,
    private readonly platformService: PlatformService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    if (this.platformService.isNative()) {
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
    this.changeDetectorRef.detectChanges();
  }
}
