import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MiniChallenge } from '../model/mini-challenge';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';

export const miniChallengesActions = createActionGroup({
  source: 'Mini Challenges',
  events: {
    ['Load All Mini Challenges']: emptyProps(),
    ['Load All Mini Challenges Success']: props<{ miniChallenges: MiniChallenge[] }>(),
    ['Load All Mini Challenges Failure']: props<ActionError>(),
    ['Join Mini Challenge']: props<{ miniChallengeId: number }>(),
    ['Join Mini Challenge Success']: props<{ miniChallenge: MiniChallenge }>(),
    ['Join Mini Challenge Failure']: props<ActionError>(),
    ['Cancel Mini Challenge']: props<{ miniChallengeId: number }>(),
    ['Cancel Mini Challenge Success']: props<{ miniChallenge: MiniChallenge }>(),
    ['Cancel Mini Challenge Failure']: props<ActionError>()
  }
});
