import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { firstValueFrom, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RegisterDeviceService } from './register-device.service';
import { NavController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import firebase from 'firebase/compat';
import MessagePayload = firebase.messaging.MessagePayload;
import { navigateNotificationClick } from '@fitup-monorepo/core/lib/util/push-notifications.util';
import { reminderSettingsActions } from '../state/reminder-settings.actions';

@Injectable({
  providedIn: 'root'
})
export class WebPushNotificationsService {
  constructor(
    private readonly angularFireMessaging: AngularFireMessaging,
    private readonly registerDeviceService: RegisterDeviceService,
    private readonly platform: Platform,
    private readonly store: Store,
    private readonly toastService: ToastService,
    private readonly navController: NavController
  ) {}

  public async requestPermissions(): Promise<boolean> {
    try {
      if (!this.platform.is('cordova')) {
        const result: NotificationPermission = await firstValueFrom(this.angularFireMessaging.requestPermission);
        return result === 'granted';
      }
      return false;
    } catch (e) {
      console.error('Error requesting permissions', JSON.stringify(e));
      return false;
    }
  }

  public async hasPermissions(): Promise<boolean> {
    const result = await firstValueFrom(this.angularFireMessaging.getToken);
    return !!result;
  }

  public async registerFirebaseListeners(): Promise<void> {
    await firstValueFrom(
      this.angularFireMessaging.tokenChanges.pipe(
        switchMap(token => {
          if (token) {
            return this.registerDeviceService.registerDevice({
              token: token,
              deviceId: 'browser',
              platform: 'browser',
              model: 'browser'
            });
          } else return of();
        })
      )
    );

    this.angularFireMessaging.messages.subscribe(m => {
      this.store.dispatch(reminderSettingsActions.loadNotifications());
      console.log(m);
      this.handlePushNotificationReceived(m);
    });
  }

  private async handlePushNotificationReceived(message: MessagePayload): Promise<void> {
    console.log(`Push received: ${JSON.stringify(message)}`);
    await this.toastService.showToast({
      message: message.notification?.title ?? 'NOTIFICATIONS.NEW_NOTIFICATION',
      primaryButtonLabel: 'OPEN',
      primaryAction: async (): Promise<void> => await this.handleNotificationClick(message)
    });
  }

  private async handleNotificationClick(message: MessagePayload): Promise<void> {
    const link: string = message.data.link;
    await navigateNotificationClick(this.navController, link);
  }
}
