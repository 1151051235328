import { ChangeDetectorRef, Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserProfileModalComponent } from '../user-profile/user-profile-modal.component';
import { CustomerSelectService } from '@fitup-monorepo/core/lib/services/customer-select/customer-select.service';

@Directive({
  selector: '[appProfileNavigation]',
  standalone: true
})
export class ProfileNavigationDirective implements OnInit {
  @Input()
  public uuid: string | undefined;

  #isNavigable = false;

  constructor(
    private readonly customerSelectService: CustomerSelectService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly modalController: ModalController
  ) {}

  @HostListener('click')
  public async onClick(): Promise<void> {
    if (this.#isNavigable) {
      await UserProfileModalComponent.openModal(this.modalController, this.uuid);
    }
  }

  @HostBinding('class.fu-cursor-pointer')
  public get isNavigable(): boolean {
    return this.#isNavigable;
  }

  public async ngOnInit(): Promise<void> {
    const customer = await this.customerSelectService.selectCustomer();
    this.#isNavigable = !!this.uuid && customer.uuid !== this.uuid;
    this.changeDetectorRef.markForCheck();
  }
}
