import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Team } from './model/team';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';

@Injectable({ providedIn: 'root' })
export class TeamService {
  constructor(private readonly httpClient: HttpClient) {}

  public getTeam(teamId: number): Observable<Team> {
    return this.httpClient.get<Team>(`${ApiService.API_URL}/team/${teamId}`);
  }
}
