import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { accountPageActions } from './account.page.actions';
import { map } from 'rxjs/operators';
import { badgeActions } from '@fitup-monorepo/badges/lib/state/badge.actions';

@Injectable()
export class AccountPageEffects {
  public loadEarnedBadges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountPageActions.loadBadges),
      map(() => badgeActions.loadEarnedBadges())
    )
  );

  constructor(private readonly actions$: Actions) {}
}
