import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  MealSearchAction,
  MealSearchComponent,
  MealSearchConfig
} from '../components/meal-search/meal-search.component';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlanFeatureService } from '../plan-feature.service';
import {WeekDay} from "@fitup-monorepo/core/lib/model/weekday";

@Component({
  selector: 'app-meal-search-page',
  templateUrl: './meal-search.page.html',
  styleUrls: ['./meal-search.page.scss'],
  imports: [CommonModule, IonicModule, HeaderComponent, MealSearchComponent, TranslateModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MealSearchPage {
  public config$: Observable<MealSearchConfig> = this.activatedRoute.queryParamMap.pipe(
    map(params => ({
      action: MealSearchAction.add,
      planId: params.get('planId'),
      numOfWeek: +params.get('numOfWeek'),
      weekDay: params.get('weekDay') as WeekDay,
      date: new Date(params.get('date'))
    }))
  );

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly planFeatureService: PlanFeatureService
  ) {}

  public onCreate(config: MealSearchConfig): void {
    this.planFeatureService.navigateToCreate(config);
  }
}
