import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonicModule, NavController } from '@ionic/angular';

import { MealSearchAction, MealSearchConfig } from '../meal-search/meal-search.component';
import { firstValueFrom, Observable } from 'rxjs';
import { errorHandlingWithToast, ErrorResult, isErrorResult } from '@fitup-monorepo/core/lib/util/rxjs-util';
import { PlanFeatureService } from '../../plan-feature.service';
import { PlanMutationService } from '../../plan-mutation.service';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { TranslateModule } from '@ngx-translate/core';
import { PreferredMeal } from '../../confirm-meal-swap/confirm-meal-swap.page';
import { isObjectOfType } from '@fitup-monorepo/core/lib/util/type-util';
import { CustomAddedMeal, CustomMealAction, ReferenceUnit, SearchedCustomMeal } from '../../model/custom-meal';
import { InternalMealType, Meal, MealStatus, MealType } from '../../model/plan';
import { CustomMealRequest, MealStatusRequest } from '../../model/plan-request';

export type CustomMealGeneral = SearchedCustomMeal | CustomAddedMeal;

@Component({
  selector: 'app-custom-meal-buttons',
  templateUrl: './custom-meal-buttons.component.html',
  styleUrls: ['./custom-meal-buttons.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomMealButtonsComponent {
  @Input()
  public config: MealSearchConfig | undefined;

  @Input()
  public actions: CustomMealAction[] = [];

  @Input()
  public meal: CustomMealGeneral | undefined;

  @Input()
  public portionsAmount: number | undefined;

  @Input()
  public mealType: MealType | undefined;

  public readonly action = CustomMealAction;

  constructor(
    private readonly planFeatureService: PlanFeatureService,
    private readonly planMutationService: PlanMutationService,
    private readonly navController: NavController,
    private readonly toastService: ToastService
  ) {}

  public showButtonFor(action: CustomMealAction, actions: CustomMealAction[]): boolean {
    return actions.includes(action) && !actions.includes(CustomMealAction.track);
  }

  public showAddAndTrackButton(actions: CustomMealAction[]): boolean {
    return this.containsTrackAnd(CustomMealAction.add, actions);
  }

  public showSwapAndTrackButton(actions: CustomMealAction[]): boolean {
    return this.containsTrackAnd(CustomMealAction.swap, actions);
  }

  private containsTrackAnd(action: CustomMealAction.swap | CustomMealAction.add, actions: CustomMealAction[]): boolean {
    return actions.includes(action) && actions.includes(CustomMealAction.track);
  }

  public async onAddMeal(meal: CustomMealGeneral, config: MealSearchConfig): Promise<void> {
    await this.addMealWithStatus(meal, config, MealStatus.open);
  }

  public async onSwapMeal(config: MealSearchConfig, preferredMeal: CustomMealGeneral): Promise<void> {
    if (config.action !== MealSearchAction.swap) {
      throw new Error('Unallowed action');
    }
    await this.swapWithStatus(config.mealIndex, config.currentMeal, preferredMeal, MealStatus.open);
  }

  public async onSwapAndTrack(config: MealSearchConfig, preferredMeal: CustomMealGeneral): Promise<void> {
    if (config.action !== MealSearchAction.swap) {
      throw new Error('Unallowed action');
    }
    await this.swapWithStatus(config.mealIndex, config.currentMeal, preferredMeal, MealStatus.tracked);
  }

  public async swapWithStatus(
    mealIndex: number,
    currentMeal: Meal,
    preferredMeal: CustomMealGeneral,
    mealStatus: MealStatusRequest
  ): Promise<void> {
    const swappedSuccessfully = await this.planFeatureService.swapMeals(
      { ...currentMeal, mealIndex },
      this.getPreferredMeal(preferredMeal),
      mealStatus
    );
    if (swappedSuccessfully) {
      await this.navController.navigateRoot('/tabs/nutrition-planner');
    }
  }

  private getPreferredMeal(customMeal: CustomMealGeneral): PreferredMeal {
    if (this.isCustomMeal(customMeal)) {
      return {
        type: InternalMealType.custom,
        id: customMeal.id,
        title: customMeal.title,
        caloriesAmount: customMeal.defaultUnitCaloriesAmount,
        image:
          'https://res.cloudinary.com/catapult/image/upload/v1684157545/fitup/nutrition/00_-_No_Meal_Picture_ewzqge.jpg',
        unitId: customMeal.defaultUnit.id,
        unitsAmount: customMeal.defaultUnit.amount,
        numOfPortions: this.portionsAmount
      };
    }
    return {
      type: InternalMealType.customAdded,
      title: customMeal.title,
      caloriesAmount: customMeal.caloriesAmount,
      image: customMeal.image,
      carbs: customMeal.carbsInGrams,
      fats: customMeal.fatsInGrams,
      proteins: customMeal.proteinsInGrams,
      portions: customMeal.portionsAmount,
      servingSize: 1, //FIXME see SearchedCustomMEal customMeal.servingSize,
      servingUnit: ReferenceUnit.grams //FIXME see SearchCustomMeal customMeal.servingUnit
    };
  }

  private isCustomMeal(customMeal: CustomMealGeneral): customMeal is SearchedCustomMeal {
    return isObjectOfType(customMeal, ['defaultUnit']);
  }

  public async onAddAndTrackClick(meal: CustomMealGeneral, config: MealSearchConfig): Promise<void> {
    await this.addMealWithStatus(meal, config, MealStatus.tracked);
  }

  private async addMealWithStatus(
    meal: CustomMealGeneral,
    config: MealSearchConfig,
    mealStatus: MealStatusRequest
  ): Promise<void> {
    const result = await firstValueFrom(this.getAddMealObservable(meal, config, mealStatus));
    if (!isErrorResult(result)) {
      await this.navController.navigateRoot('/tabs/nutrition-planner');
    }
  }

  private getAddMealObservable(
    meal: CustomMealGeneral,
    config: MealSearchConfig,
    mealStatus: MealStatusRequest
  ): Observable<Meal | ErrorResult> {
    return this.planMutationService
      .addMeal({
        planId: config.planId,
        week: config.numOfWeek,
        day: config.weekDay,
        status: mealStatus,
        numOfPortions: this.portionsAmount,
        body: {
          mealType: this.mealType,
          customMeal: this.getMealBody(meal)
        }
      })
      .pipe(errorHandlingWithToast(this.toastService));
  }

  private getMealBody(meal: CustomMealGeneral): CustomMealRequest {
    if (this.isCustomMeal(meal)) {
      return {
        id: meal.id,
        unitsAmount: meal.defaultUnit.amount,
        unitId: meal.defaultUnit.id
      };
    }
    return {
      unitId: 'portion',
      unitsAmount: meal.portionsAmount,
      customMealData: {
        title: meal.title,
        caloriesAmount: meal.caloriesAmount,
        image: meal.image,
        carbsInGrams: meal.carbsInGrams,
        fatsInGrams: meal.fatsInGrams,
        proteinInGrams: meal.proteinsInGrams,
        servingSize: meal.servingSize,
        servingUnit: meal.servingUnit
      }
    };
  }
}
