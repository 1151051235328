// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host.center {
  text-align: center;
}
:host.left {
  text-align: left;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 16px;
  background: var(--fu-banner-bg);
  color: var(--fu-banner-color);
  border-radius: 16px;
}
.info__icon {
  font-size: 24px;
  margin-bottom: 20px;
}
.info__icon--default {
  color: var(--fu-orange-light);
}
.info__title {
  color: var(--fu-body-color);
  margin-bottom: var(--fu-spacing-s);
}
.info__text {
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--fu-font-regular);
  color: var(--fu-banner-color);
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/info-banner/info-banner.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,+BAAA;EACA,6BAAA;EACA,mBAAA;AADF;AAGE;EACE,eAAA;EACA,mBAAA;AADJ;AAGI;EACE,6BAAA;AADN;AAKE;EACE,2BAAA;EACA,kCAAA;AAHJ;AAME;EACE,eAAA;EACA,iBAAA;EACA,mCAAA;EACA,6BAAA;AAJJ","sourcesContent":[":host {\n  display: block;\n\n  &.center {\n    text-align: center;\n  }\n\n  &.left {\n    text-align: left;\n  }\n}\n\n.info {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 30px 16px;\n  background: var(--fu-banner-bg);\n  color: var(--fu-banner-color);\n  border-radius: 16px;\n\n  &__icon {\n    font-size: 24px;\n    margin-bottom: 20px;\n\n    &--default {\n      color: var(--fu-orange-light);\n    }\n  }\n\n  &__title{\n    color: var(--fu-body-color);\n    margin-bottom: var(--fu-spacing-s);\n  }\n\n  &__text {\n    font-size: 14px;\n    line-height: 18px;\n    font-weight: var(--fu-font-regular);\n    color: var(--fu-banner-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
