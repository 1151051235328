// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-progress {
  margin-bottom: 60px;
}`, "",{"version":3,"sources":["webpack://./libs/nutrition-planner/src/lib/plan-progress/plan-progress.page.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".plan-progress {\n  margin-bottom: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
