import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  constructor(private readonly loadingCtrl: LoadingController, private readonly translateService: TranslateService) {}

  public async showLoading(message?: string): Promise<void> {
    const loadingEl = await this.loadingCtrl.create({
      message: message ? this.translateService.instant(message) : undefined,
      spinner: 'crescent',
      cssClass: ['fu-loading'],
      showBackdrop: true,
    });
    await loadingEl.present();
  }

  public async hideLoading(): Promise<void> {
    await this.loadingCtrl.dismiss();
  }
}
