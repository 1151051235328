import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Group } from '../../model';
import { createReducer, on } from '@ngrx/store';
import { groupActions } from './group.actions';

export interface GroupState extends EntityState<Group> {
  loading: boolean;
  error?: string;
  joinedGroups: number[];
  createdGroups: number[];
  availableGroups: number[];
}

export const groupAdapter: EntityAdapter<Group> = createEntityAdapter();

const initialState: GroupState = groupAdapter.getInitialState({
  loading: false,
  joinedGroups: [],
  createdGroups: [],
  availableGroups: []
});

export const groupReducer = createReducer(
  initialState,
  on(
    groupActions.loadGroupOverview,
    groupActions.joinGroup,
    groupActions.leaveGroup,
    groupActions.createGroup,
    groupActions.updateGroup,
    groupActions.deleteGroup,
    (state): GroupState => ({ ...state, loading: true })
  ),
  on(groupActions.loadGroupOverviewSuccess, (state, { groupOverview }) =>
    groupAdapter.upsertMany([...groupOverview.availableLeagues, ...groupOverview.joinedLeagues], {
      ...state,
      joinedGroups: groupOverview.joinedLeagues.map(l => l.id),
      createdGroups: groupOverview.joinedLeagues.filter(l => l.canEdit).map(l => l.id),
      availableGroups: groupOverview.availableLeagues.map(l => l.id),
      loading: false
    })
  ),
  on(
    groupActions.joinGroupSuccess,
    groupActions.leaveGroupSuccess,
    groupActions.createGroupSuccess,
    groupActions.updateGroupSuccess,
    (state, { group }) => groupAdapter.upsertOne(group, { ...state, loading: false })
  ),
  on(groupActions.deleteGroupSuccess, (state): GroupState => ({ ...state, loading: false })),
  on(
    groupActions.loadGroupOverviewFailure,
    groupActions.joinGroupFailure,
    groupActions.leaveGroupFailure,
    groupActions.createGroupFailure,
    groupActions.updateGroupFailure,
    groupActions.deleteGroupFailure,
    (state): GroupState => ({ ...state, loading: false })
  )
);
