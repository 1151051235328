import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import {
  selectCustomer,
  selectExpiredTrial,
  selectMembership,
  selectPromotionCode
} from '../../state/profile/profile.selector';
import { filter } from 'rxjs/operators';
import { Customer } from '../../model/customer';
import { CustomerMembership } from '../../model/customer-membership';
import { PromotionCode } from '../../model/promotion-code';

@Injectable({
  providedIn: 'root'
})
export class CustomerSelectService {
  constructor(private readonly store: Store) {}

  public selectCustomer$(): Observable<Customer> {
    return this.store.select(selectCustomer).pipe(filter(customer => !!customer));
  }

  public selectCustomer(): Promise<Customer> {
    return firstValueFrom(this.selectCustomer$());
  }

  public selectMembership$(): Observable<CustomerMembership | null> {
    return this.store.select(selectMembership).pipe(filter(membership => membership !== undefined));
  }

  public selectExpiredTrial$(): Observable<boolean> {
    return this.store.select(selectExpiredTrial).pipe(filter(trial => trial !== undefined));
  }

  public selectExpiredTrial(): Promise<boolean> {
    return firstValueFrom(this.selectExpiredTrial$());
  }

  public selectMembership(): Promise<CustomerMembership | null> {
    return firstValueFrom(this.selectMembership$());
  }

  public selectPromotionCode$(): Observable<PromotionCode | null> {
    // undefined === not loaded, null === loaded, but non-existent
    return this.store.select(selectPromotionCode).pipe(filter(promotionCode => promotionCode !== undefined));
  }

  public selectPromotionCode(): Promise<PromotionCode | null> {
    return firstValueFrom(this.selectPromotionCode$());
  }
}
