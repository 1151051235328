// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.segment {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: var(--fu-segment-bg);
  border-radius: 20px;
}
.segment__tile {
  display: grid;
  place-items: center;
  width: 100%;
  height: calc(100% - 6px);
  border-radius: inherit;
  margin: 3px;
  cursor: pointer;
  text-transform: capitalize;
  transition: ease-in-out;
}

.active {
  background-color: var(--fu-segment-bg-active);
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/segment/segment.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,sCAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,wBAAA;EACA,sBAAA;EACA,WAAA;EACA,eAAA;EACA,0BAAA;EACA,uBAAA;AACJ;;AAGA;EACE,6CAAA;AAAF","sourcesContent":[".segment {\n  display: flex;\n  width: 100%;\n  height: 40px;\n  background-color: var(--fu-segment-bg);\n  border-radius: 20px;\n\n  &__tile {\n    display: grid;\n    place-items: center;\n    width: 100%;\n    height: calc(100% - 6px);\n    border-radius: inherit;\n    margin: 3px;\n    cursor: pointer;\n    text-transform: capitalize;\n    transition: ease-in-out;\n  }\n}\n\n.active {\n  background-color: var(--fu-segment-bg-active);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
