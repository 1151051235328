import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  CategoryOverviewResponse,
  CategoryType,
  MediaDomain,
  MediaEntry,
  MediaFilter,
  MediaHistoryResponse,
  MediaOverview
} from '../model';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';
import { PageResult } from '@fitup-monorepo/core/lib/model/page-result';

export const mediaCenterActions = createActionGroup({
  source: 'Mediacenter API',
  events: {
    ['Load Overview']: props<{ mediaType: MediaDomain.center }>(),
    ['Load Overview Replay']: props<{ mediaType: MediaDomain.replay }>(),
    ['Load Overview Success']: props<{
      mediaType: MediaDomain;
      overview: MediaOverview;
    }>(),
    ['Load Overview Failure']: props<ActionError>(),
    ['Load Category']: props<{
      categoryId: CategoryType;
      mediaType: MediaDomain;
    }>(),
    ['Load Category Success']: props<{
      mediaType: MediaDomain;
      overview: CategoryOverviewResponse;
    }>(),
    ['Load Category Failure']: props<ActionError>(),
    ['Set Filters']: props<{
      mediaType: MediaDomain;
      categoryId: CategoryType;
      mediaFilter: MediaFilter;
    }>(),
    ['Load Media Entry']: props<{ id: string }>(),
    ['Load Media Entry Success']: props<{ media: MediaEntry }>(),
    ['Load Media Entry Failure']: props<ActionError>(),
    ['Update Favorite']: props<{
      id: string;
      isFavorite: boolean;
      mediaType: MediaDomain;
    }>(),
    ['Update Favorite Success']: props<{ id: string; isFavorite: boolean }>(),
    ['Update Favorite Failure']: props<ActionError>(),
    ['Load Favorites']: props<{ mediaType: MediaDomain }>(),
    ['Load Favorites Success']: props<PageResult<MediaEntry>>(),
    ['Load Favorites Failure']: props<ActionError>(),
    ['Load History']: emptyProps(),
    ['Load History Success']: props<MediaHistoryResponse>(),
    ['Load History Failure']: props<ActionError>(),
    ['Load More Category Media']: props<{
      categoryId: CategoryType;
      mediaType: MediaDomain;
    }>(),
    ['Load More Category Media Success']: props<{
      mediaType: MediaDomain;
      overview: CategoryOverviewResponse;
    }>(),
    ['Load More History']: emptyProps(),
    ['Load More Favorites']: props<{ mediaType: MediaDomain }>(),
    ['Update Watch Progress']: props<{ id: string; progress: number }>(),
    ['Update Watch Progress Success']: props<{ media: MediaEntry }>(),
    ['Update Watch Progress Failure']: props<ActionError>()
  }
});
