import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DropdownItem, DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ReportProblemService } from '../report-problem/report-problem.service';

@Component({
  selector: 'app-feedback-dropdown-menu',
  templateUrl: './feedback-dropdown-menu.component.html',
  styleUrls: ['./feedback-dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DropdownMenuComponent, IonicModule, TranslateModule],
  standalone: true
})
export class FeedbackDropdownMenuComponent {
  @Input({ required: true })
  public topic!: string;

  @Input()
  public set menuItems(menuItems: DropdownItem[]) {
    if (menuItems) {
      this.items = [...menuItems, ...this.items];
    }
  }

  protected items: DropdownItem[] = [
    {
      label: 'REPORT_PROBLEM.TITLE',
      iconName: 'alert-triangle-solid',
      color: 'var(--fu-light-red)',
      callback: (): Promise<void> => this.openModal()
    }
  ];

  constructor(private readonly reportProblemService: ReportProblemService) {}

  private async openModal(): Promise<void> {
    await this.reportProblemService.openReportModal(this.topic);
  }
}
